import React, { useEffect, useState } from "react";
import { withRouter } from "../../../providers/withRouter";
/* import LogoMC from "../../../images/MC.png" */
import LogoLAE from "../../../images/LAE.png"
import Cabecera from "../../../images/cabecera.png"
import { incidencias } from "../../../providers/incidencias";
import { getVisitSheet, getSale } from "../../../services";
import { formatoFechasEs } from "../../../functions/funciones";

const HojaVisitaRender = (props) => {

    const [hojaVisita, setHojasVisita] = useState({})
    const [incidenciasVisibles, setIncidenciasVisibles] = useState([])
    const [imagenes, setImagenes] = useState([])

    const { idCentroTrabajo } =  props?.params;

    useEffect(() => {
        fetchData()
    },[])

    const fetchData = async () => {
        let inc = []
        const sale = await getSale(props.params.id)
       const visitSheet_id = sale.detailsCae[0]?.visitSheet_id
        const response = await getVisitSheet(visitSheet_id)
        const {data} = response
        let ArrImagenes = [];
        data.images.forEach( (imgURL, i) => {
            ArrImagenes.push(`${process.env.REACT_APP_STORAGE_URL}${imgURL.ruta}`);
        });
        setImagenes(ArrImagenes);
        setHojasVisita(data)
        if (data.questions?.p1_1 === "si") inc.push(incidencias.p1_1)
        if (data.questions?.p1_2 === "si") inc.push(incidencias.p1_2)
        if (data.questions?.p1_3 === "si") inc.push(incidencias.p1_3)
        if (data.questions?.p1_4 === "si") inc.push(incidencias.p1_4)
        if (data.questions?.p1_5 === "si") inc.push(incidencias.p1_5)
        if (data.questions?.p1_6 === "si") inc.push(incidencias.p1_6)
        if (data.questions?.p1_7 === "si") inc.push(incidencias.p1_7)
        if (data.questions?.p1_8 === "no") inc.push(incidencias.p1_8)
        if (data.questions?.p2_0 === "si") {
            if (data.questions?.p2_1 === "no") inc.push(incidencias.p2_1)
            if (data.questions?.p2_2 === "no") inc.push(incidencias.p2_2)
            if (data.questions?.p2_3 === "no") inc.push(incidencias.p2_3)
            if (data.questions?.p2_4 === "no") inc.push(incidencias.p2_4)
            if (data.questions?.p2_5 === "no") inc.push(incidencias.p2_5)
            if (data.questions?.p2_5 === "no") {
                if (data.questions?.p2_6 === "no") inc.push(incidencias.p2_6)
                if (data.questions?.p2_7 === "no") inc.push(incidencias.p2_7)
                if (data.questions?.p2_8 === "no" && data.questions?.p2_9 === "no") inc.push(incidencias.p2_8)
            }
            if (data.questions?.p2_9 === "no" && data.questions?.p2_8 === "no") inc.push(incidencias.p2_9)
            if (data.questions?.p2_10 === "no") inc.push(incidencias.p2_10)
        }
        if (data.questions?.p3_0 === "si") {
            if (data.questions?.p3_1 === "no") inc.push(incidencias.p3_1)
            if (data.questions?.p3_2 === "no") inc.push(incidencias.p3_2)
            if (data.questions?.p3_3 === "no") inc.push(incidencias.p3_3)
            if (data.questions?.p3_4 === "no") inc.push(incidencias.p3_4)
        }
        if (data.questions?.p4_0 === "s") {
            if (data.questions?.p4_1 === "no") inc.push(incidencias.p4_1)
            if (data.questions?.p4_2 === "no") inc.push(incidencias.p4_2)
            if (data.questions?.p4_3 === "no") inc.push(incidencias.p4_3)
            if (data.questions?.p4_4 === "no") inc.push(incidencias.p4_4)
            if (data.questions?.p4_5 === "no") inc.push(incidencias.p4_5)
            if (data.questions?.p4_6 === "no") inc.push(incidencias.p4_6)
            if (data.questions?.p4_7 === "no") inc.push(incidencias.p4_7)
            if (data.questions?.p4_8 === "no") inc.push(incidencias.p4_8)
            if (data.questions?.p4_9 === "no") inc.push(incidencias.p4_9)
            if (data.questions?.p4_10 === "no") inc.push(incidencias.p4_10)
            if (data.questions?.p4_11 === "no") inc.push(incidencias.p4_11)
            if (data.questions?.p4_12 === "no") inc.push(incidencias.p4_12)
            if (data.questions?.p4_13 === "no") inc.push(incidencias.p4_13)
            if (data.questions?.p4_14 === "no") inc.push(incidencias.p4_14)
            if (data.questions?.p4_15 === "no") inc.push(incidencias.p4_15)
        }
        if (data.questions?.p5_1 === "si") {
            if (data.questions?.p5_2 === "no") inc.push(incidencias.p5_2)
            if (data.questions?.p5_3 === "no") inc.push(incidencias.p5_3)
        }
        if (data.questions?.p6_0 === "si") {
            if (data.questions?.p6_2 === "no") inc.push(incidencias.p6_2)
            if (data.questions?.p6_3 === "no") inc.push(incidencias.p6_3)
        }
        if (data.questions?.p10_0 === "si") {
            if (data.questions?.p10_1 === "no") inc.push(incidencias.p10_1)
            if (data.questions?.p10_2 === "no") inc.push(incidencias.p10_2)
            if (data.questions?.p10_3 === "no") inc.push(incidencias.p10_3)
            if (data.questions?.p10_4 === "no") inc.push(incidencias.p10_4)
            if (data.questions?.p10_5 === "no") inc.push(incidencias.p10_5)
            if (data.questions?.p10_6 === "no") inc.push(incidencias.p10_6)
        }
        if (data.questions?.p10_0 === "no") inc.push(incidencias.p10_0)
        if (data.questions?.p11_0 === "si") {
            if (data.questions?.p11_1 === "no") inc.push(incidencias.p11_1)
            if (data.questions?.p11_2 === "no") inc.push(incidencias.p11_2)
        }
        if (data.questions?.p11_0 === "no") inc.push(incidencias.p11_0)
        if (data.questions?.p12_0 === "si") {
            if (data.questions?.p12_1 === "no") inc.push(incidencias.p12_1)
            if (data.questions?.p12_2 === "no") inc.push(incidencias.p12_2)
        }
        if (data.questions?.p12_0 === "no") inc.push(incidencias.p12_0)
        setIncidenciasVisibles(inc)
        
    }

    return (
        <>
            <table className="tablePage">
                <thead><tr><td style={{ border: 'none' }}>
                    <div className="header-space">&nbsp;</div>
                </td></tr></thead>
                <tbody>
                    <div style={{ padding: '0 30px' }}>
                        <div style={{ color: '#ffffff', backgroundColor: '#152849', textAlign: 'center', marginTop: 20, marginBottom: 5, fontSize: 14 }}>HOJA DE SEGUIMIENTO</div>
                        <table>
                            <tr>
                                <th>Comunidad de propietarios:</th>
                                <td>{hojaVisita?.propertie?.razonSocial}</td>
                                <th>Actividad:</th>
                                <td>COMUNIDAD DE PROPIETARIOS</td>
                            </tr>
                            <tr>
                                <th>Centro de trabajo:</th>
                                <td>{idCentroTrabajo !== undefined ? hojaVisita?.workcenter?.razonSocial : hojaVisita?.propertie?.direccion}</td>
                                <th>Localidad:</th>
                                <td>{hojaVisita?.propertie?.municipio}</td>
                            </tr>
                            <tr>
                                <th>Técnico:</th>
                                <td>{hojaVisita?.technician?.nombre+" "+hojaVisita?.technician?.apellidos}</td>
                                <th>Código Postal:</th>
                                <td>{hojaVisita?.propertie?.codigoPostal}</td>
                            </tr>
                            <tr>
                                <th>Fecha de visita:</th>
                                <td>{formatoFechasEs(hojaVisita?.createdAt)}</td>
                                <th>Administrador de Fincas:</th>
                                <td>{hojaVisita?.aaff?.razonSocial}</td>
                            </tr>
                        </table>
                        <div style={{ color: '#ffffff', backgroundColor: '#152849', textAlign: 'center', marginTop: 20, marginBottom: 5, fontSize: 14 }}>ACTUACIONES</div>
                        <table>
                            <tr><td><div style={{ minHeight: 100 }}>Visita al centro de trabajo. Se realiza toma de datos para la identificación de riesgos.</div></td></tr>
                        </table>
                        <div style={{ color: '#ffffff', backgroundColor: '#152849', textAlign: 'center', marginTop: 20, marginBottom: 5, fontSize: 14 }}>IDENTIFICACIÓN DE RIESGOS</div>
                        <table id="tableIncidencias">
                            <tr><th>Una vez realizada la visita en la comunidad, se observa:</th></tr>
                            <tr><td><ul style={{ minHeight: 200 }}>
                                {incidenciasVisibles.map((e, i) => <li>{e}</li>)}
                            </ul></td></tr>
                        </table>
                        <div style={{ color: '#ffffff', backgroundColor: '#152849', textAlign: 'center', marginTop: 20, marginBottom: 5, fontSize: 14 }}>OBSERVACIONES</div>
                        <table>
                            <tr><td>
                            <div style={{ minHeight: 100 }}><p style={{ whiteSpace: "pre-line" }}>{hojaVisita?.comentario}</p></div>
                            </td></tr>
                        </table>
                            {imagenes?.length > 0 && imagenes !== undefined ? 
                            <>
                                <div className="pagebreak"></div>
                                <div style={{ color: '#ffffff', backgroundColor: '#152849', textAlign: 'center', marginTop: 20, marginBottom: 5, fontSize: 14 }}>IMAGENES</div>
                                <div style={{padding: 10, textAlign: "center"}}>
                                        {imagenes.map(url => {
                                            return <img src={url} style={{width: 200}} />
                                        }) }
                                </div>
                            </>
                            : null }
                        <div style={{ display: 'flex', justifyContent: 'left', marginTop: 30, marginBottom: 30 }}>
                            <div style={{ marginRight: 75 }}>
                                <p><b>TÉCNICO DE SEGURIDAD</b></p>
                                <p style={{ marginTop: 50 }}>Firma: {hojaVisita?.tecnico?.nombre}</p>
                                <p style={{ marginTop: 10 }}><small>Técnico en PRL</small></p>
                                {/* <div>
                                    <img style={{ height: 60, marginTop: 30, marginBottom: 10 }} src={LogoLAE} alt="Logo LAE" />
                                    <p style={{ fontSize: 8 }}><b>LAE Consulting Normativas S.L. <br />B75010686</b></p>
                                </div> */}
                            </div>
                            <div style={{ marginLeft: 75 }}>
                                {/* <p><b>RESPONSABLE</b></p>
                                <p style={{ marginTop: 50 }}>Firma: _________________________________</p> */}
                            </div>
                        </div>
                    </div>
                </tbody>
            </table>
            <div style={{
                width: '100%',
                height: 120,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0 40px',
                backgroundImage: `url(${Cabecera})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: "fixed",
                top: 0,
                left: 0
            }}>
                <div style={{ display: 'flex', color: '#ffffff', alignItems: 'center' }}>
                    <img style={{ height: 60, filter: "brightness(0) invert(1)" }} src={LogoLAE} alt="Logo LAE" />
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: 20, paddingLeft: 20, borderLeft: '2px solid #ffffff', height: 'fit-content', fontSize: 20 }}>
                        <p style={{ margin: 0, lineHeight: 1.2, fontWeight: 'bold' }}>HOJA DE VISITA</p>
                        <p style={{ margin: 0, lineHeight: 1.2 }}>Identificación de riesgos</p>
                    </div>
                </div>
                {/* <img style={{ height: 60, filter: "brightness(0) invert(1)" }} src={LogoMC} alt="Logo M&C" /> */}
            </div>
            <style jsx>{`
            div {
                font-size: 10px
            }
            table {
                border-collapse: collapse;
                width: 100%;
                table-layout: fixed;
            }
            th, td {
                border: 1px solid #000000;
                padding: 5px;
                line-height: 1.1;
                text-align: left
            }
            th {
                background-color: #eaecee;
            }
            p {
                margin: 0
            }
            ul {
                padding-left: 20px
            }
            li {
                margin-top: 5px
            }
            .header-space {
                height: 120px;
            }
            @page {
                size: A4 portrait;
                margin: 0 0 30px 0;
            }
        `}</style>
        </>
    );
}
 

export default withRouter(HojaVisitaRender);
