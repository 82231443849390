import { useState, useContext } from "react";
import moment from "moment";

import { getAAFFs, getListadoAaffsSupp, noRealizada } from "../services";
import { UsuarioContext } from "../providers/userContext";
import {  useNavigate } from 'react-router-dom';
import { useRecogerURLParamsFiltros, construirURLParamsFiltros } from './../functions/parametrosURL/funcionesParamsURL';


const formatData = (data) => {
  
  
  
  return data.map((row) => ({
    razonSocial: row.razonSocial || row.propertie?.aaff?.nombreContacto,
    nif: row.nif || row.propertie?.nif,
    // fechaEncargo: moment(row.fechaEncargo).format("DD/MM/YYYY"),
    id: row.id,
    // communityNumbers: row.properties ?? [],
    // communityPercent: row.properties ?? [],
    direction: row.direccion || row.propertie?.direccion,
    cp: row.codigoPostal || row.propertie?.codigoPostal,
    provincia: row.provincia || row.propertie?.provincia,
    municipio: row.municipio || row.propertie?.municipio,
    empleados: (row.trabajadores || row.propertie?.trabajadores) ?? "No",
    servicios: row.gestionadaPor || row.propertie?.gestionadaPor,
    hojasVisita: row.visitSheets,
    visitadas: row.visitadasCount + row.renovadasVisitadasCount ?? 0,
    // noVisitada: row,
    comunidades: row.pendientesCount + row.visitadasCount + row.renovadasCount + row.renovadasVisitadasCount ?? 0,
    telefono: row.telefono || row.propertie?.telefono,
    unRealized: row.unRealized,
    unRealizedDate: row.unRealizedDate,
    observacionNoVisita: row.observacionNoVisita,
    propCaducadas: row.hasPropCaducadas
  }));
};

function useAFFs(busqueda) {
  
  const {filtroOrder} = useRecogerURLParamsFiltros();
  const { rol } = useContext(UsuarioContext)
  const [data, setData] = useState({
    meta: {},
    rows: [],
  });
  const [loading, setLoading] = useState(true);

  
  const urlPadre = window.location.pathname;
  const navigate = useNavigate();

  const fetchData = async ({ offset, limit, filtros = busqueda,  order,orderBy } = {}) => {


    orderBy = orderBy || filtroOrder.ordenadoPor || 'id';
    order =   order || filtroOrder.orden || 'ASC';
    
 
    construirURLParamsFiltros(navigate, offset || 1, limit || 50, filtros,order, orderBy);
    const payload = {
      params: { page: offset, limit:limit,order, orderBy },
      filtros,
    };

    try {
      setLoading(true);

      let response = []
      if (rol === 'SUPP') {
        response = await getListadoAaffsSupp(payload);
        // console.log(response);
      } else {
        response = await getAAFFs(payload);
      }

      

      const { meta, data } = response;
      setData({
        meta,
        rows: formatData(data),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    fetchData,
    loading,
    // limit
  };
}

export default useAFFs;
