import React, { useState, useEffect, useContext  } from 'react';
import { withRouter } from "../../../providers/withRouter";
import { Link } from "react-router-dom";
import { deleteFinca, deleteCentroTrabajo, getFincasAAFF, cambiarFinca, UpdateSale, getEstadosFincas } from '../../../services';
import { toast } from "react-toastify";
import { UsuarioContext } from "../../../providers/userContext";
import Swal from "sweetalert2";
import { Typography, Box, Select } from "@mui/material";
import { diferenciaMesesCaducidad, formatoFechasEs } from "../../../functions/funciones";
import Delete from "../../../icons/delete.svg";
import Change from "../../../icons/change.svg";
import Add from "../../../icons/addBlack.svg";
import { DialogoCambioAAFF } from "../../../providers/Dialogos";
import { CustomSwitch } from "../../../components/Buttons/Switch";
import { DataTable } from '../../../components';
import { useLocation, useNavigate } from 'react-router-dom';
import { construirURLParamsFiltros, useRecogerURLParamsFiltros } from '../../../functions/parametrosURL/funcionesParamsURL';
import FiltrosComunidades from '../../../components/filtros/filtrosComunidades';

const ListadoComunidades = (props) => {
  const navigate = useNavigate();
  const {filtrosIniciales, filtroOrder} = useRecogerURLParamsFiltros();
  const [busqueda, setBusqueda] = useState(filtrosIniciales);
  const [isLoading, setIsloading] = useState(true);
  const [recargar, setRecargar] = useState(0);
  const [clearFilterActivo, setClearFilterActivo] = useState(false);
  // const [fincas, setFincas] = useState([]);
  const [fincaCambiarAAFF, setFincaCambiarAAFF] = useState({});
  const [primeraCarga, setPrimeraCarga] = useState(true);
  const [estados, setEstados] = useState([]);

  const [open, setOpen] = useState(false);
  const [data, setData] = useState({
    meta: {},
    rows: [],
  });

  useEffect(() => {
    fetchData();
  },[recargar])

  const fetchData = async ({ offset, limit, filtros = busqueda, order,orderBy } = {}) => {
    
    orderBy = orderBy || filtroOrder.ordenadoPor || 'id';
    order =   order || filtroOrder.orden || 'ASC';

    
    construirURLParamsFiltros(navigate, offset || 1, limit || 50, filtros, order,orderBy);    

    setIsloading(true);

    if (props.aaff?.id) {
      const payload = {
        filtros: { ...filtros, aaff_id: props.aaff.id },
        params: { page: offset, limit, order,orderBy },
      };
      const responseEstados = await getEstadosFincas();
      setEstados(responseEstados)
      const response = await getFincasAAFF(payload)
      const { meta, data } = response;
      setData({
        meta,
        rows: formatData(data),
      });
      setIsloading(false);
      setRecargar(false);
    }
  }

    const formatData = (data) => {
        const formattedData = []
        data.map((row) => {
          const sale_cae = row?.sales?.find(s => [1, 3, 6, 7].includes(s.servp_id)) || null;
          const sale_lopd = row?.sales?.find(s => [2, 4, 5].includes(s.servp_id)) || null;
          // const diferenciaMeses = diferenciaMesesCaducidad(row.fechaRenovacion, row.createdAt);
          formattedData.push({
            razonSocial: row.razonSocial,
              nif: row.nif,
              nifAAFF: row.aaff.nif,
              createdAt: formatoFechasEs(row.createdAt),
              proveedores: row.proveedores || row.proveedores === null ? row.suppliers.filter(objeto => objeto.status === true).length : "No tiene",
              trabajadores: row.trabajadores === null ? false : row.trabajadores,
              activo: row.activo === null ? true : row.activo,
              estadoCae: sale_cae ? sale_cae.estado_id === null ? 1 : sale_cae.estado_id : "-",
              estadoLopd: sale_lopd ? sale_lopd.estado_id === null ? 1 : sale_lopd.estado_id : "-",
              ct: row.nif,
              cambiar: row,
              eliminar: row,
              isWorkcenter: false, // Not a workcenter
              // caducada: diferenciaMeses > 13 ? true : false,
              saleCae_id: sale_cae?.id || null,
              saleLopd_id: sale_lopd?.id || null
          })
          if (row.workcenters?.length > 0) {
              row.workcenters?.forEach((wc) => {
                  formattedData.push({
                      razonSocial: wc.razonSocial,
                      nif: "",
                      nifAAFF: row.aaff.nif,
                      createdAt: !isNaN(wc.createdAt) ? formatoFechasEs(wc.createdAt) : "",
                      ct: "",
                      cambiar: "",
                      eliminar: wc,
                      isWorkcenter: true,
                      trabajadores: "",
                      activo: "",
                      estadoCae: "",
                      estadoLopd: "",
                      saleCae_id: "",
                      saleLopd_id: "",
                  });
              });
          }
        });
        return formattedData;
    };
      
    const handleChangeActivo = (evt, nif) => {        
        const actualizarEstado = data.rows.map((row) => {
          if (row.nif === nif) {
            cambiarFinca(nif, {
              activo: evt.target.value
            })
            return { ...row, activo: evt.target.value === 'true' };
    
          }
          return row;
        });
        setData({ ...data, rows: actualizarEstado });
    };
    const handleChangeEstado = (evt, id, serv) => {   
      console.log(evt.target.value, id, serv)    
      const valor = parseInt(evt.target.value)
      const actualizarEstado = data.rows.map((row) => {
        const sale_id = serv === 'cae' ? row.saleCae_id : row.saleLopd_id
        if (sale_id === id) {
          console.log(serv, sale_id)
          UpdateSale(id, {
            estado_id: valor
          })
          const nuevosDatos = serv === "cae" ? {estadoCae:valor} : {estadoLopd:valor}
          return { ...row, ...nuevosDatos };
        }
        return row;
      });
      console.log(actualizarEstado)
      setData({ ...data, rows: actualizarEstado });
      // const updatedMainArray = data.rows.map(item => {
      //   if (item.sales) {
      //     const updatedNestedArray = item.sales.map(sale => {
      //       if (sale.id === id) {
      //         // console.log({ ...sale, estado_id: evt.target.value })
      //         const nuevosDatos = serv === "cae" ? {estadoCae:evt.target.value} : {estadoLopd:evt.target.value}
      //         return { ...sale, ...nuevosDatos };
      //       }
      //       return sale;
      //     });
      //     // console.log(updatedNestedArray)
      //     // // Devolvemos el item principal con el nestedArray modificado
      //     return { ...item, sales: updatedNestedArray };
      //   }
      //   return item;
      // });
      // console.log(updatedMainArray)
      // setData({ ...data, rows: updatedMainArray });
    };

    const handleChangeTrabajadores = (evt, nif) => {        
      const actualizarEstado = data.rows.map((row) => {
        if (row.nif === nif) {
          cambiarFinca(nif, {
            trabajadores: evt.target.value
          })
          return { ...row, trabajadores: evt.target.value === 'true' };
  
        }
        return row;
      });
      setData({ ...data, rows: actualizarEstado });
    };

  const comunidadesRows = [
    {
      id: "razonSocial",
      numeric: false,
      disablePadding: false,
      label: "COMUNIDAD",
      overrideFunc: (data, row) => (
            row.isWorkcenter === false ? 
          <Link to={"/aaff/" + row.nifAAFF + "/comunidades/" + row.nif}>
            {data}
          </Link>
            : 
              <Typography style={{ textAlign: 'left'}}>
                {data}
              </Typography>
      )
    },
    {
      id: "nif",
      numeric: false,
      disablePadding: false,
      label: "NIF",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "FECHA ALTA",
      overrideFunc: (data, row) => (
        <Typography style={{ textAlign: 'left' }}>
          {data}
        </Typography>
      ),
    },
    {
      id: "proveedores",
      numeric: false,
      disablePadding: false,
      label: "PROVEEDORES",
      noObjeto:true,
      overrideFunc: (data, row) => (
        // /aaff/:nifAAFF/comunidades/:nifFinca/proveedores
        <Link to={"/aaff/" + row.nifAAFF + "/comunidades/" + row.nif + "/proveedores"}>
          {row.proveedores}
        </Link>
      )
    },
    {
      id: "estadoCae",
      numeric: false,
      disablePadding: false,
      label: "ESTADO CAE",
      noObjeto:true,
      overrideFunc: (data, row) => (
        !row.isWorkcenter ?
          data !== "-" ?
            <div className="activoSelect">
              <Select 
                  label="EstadoCae"
                  native 
                  name="estadoCae" 
                  id="estadoCae" 
                  style={{ width: 'auto !important', textTransform: 'uppercase', padding: 0, fontSize: '11px', color: data === 1 || null ? "#3eae3e" : data === 2 || data === 3 ? '#FF8300' : data === 4 ? "#EE4534" : "#000" }} 
                  onChange={(event) => handleChangeEstado(event, row.saleCae_id, 'cae')} defaultValue={data}
                >
                  {estados?.map((e, i) => {
                    return (
                      <option key={i} value={e.id} style={{ color: e.id === 1 || e.id == null ? "#3eae3e"  : e.id === 2 || e.id === 3 ? '#FF8300' : e.id === 4 ? "#EE4534" : "#000" }}>
                        {e.nombre}
                      </option>
                    );
                  })}
                </Select>
            </div>
          : data
        : ""
      ),
    },
    {
      id: "estadoLopd",
      numeric: false,
      disablePadding: false,
      label: "ESTADO LOPD",
      noObjeto:true,
      overrideFunc: (data, row) => (
        !row.isWorkcenter ?
          data !== "-" ?
            <div className="activoSelect">
              <Select 
                  label="EstadoLopd"
                  native 
                  name="estadoLopd" 
                  id="estadoLopd" 
                  style={{ width: 'auto !important', textTransform: 'uppercase', padding: 0, fontSize: '11px', color: data === 1 || null ? "#3eae3e"  : data === 2 || data === 3 ? '#FF8300' : data === 4 ? "#EE4534" : "#000" }} 
                  onChange={(event) => handleChangeEstado(event, row.saleLopd_id, 'lopd')} defaultValue={data}
                >
                  {estados?.map((e, i) => {
                    return (
                      <option key={i} value={e.id} style={{ color: e.id === 1 || e.id == null ? "#3eae3e"  : e.id === 2 || e.id === 3 ? '#FF8300' : data === 4 ? "#EE4534" : "#000" }}>
                        {e.nombre}
                      </option>
                    );
                  })}
                </Select>
            </div>
          : data
        : ""
      ),
    },
    // {
    //   id: "activo",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "ACTIVO",
    //   overrideFunc: (data, row) => (
    //     data !== "" ?
    //       <div className="activoSelect">
    //         <Select 
    //             label="Activo"
    //             native 
    //             name="activo" 
    //             id="activo" 
    //             style={{ textTransform: 'uppercase', padding: 0, fontSize: '11px', color: data ? "green" : 'red' }} 
    //             onChange={(event) => handleChangeActivo(event, row.nif)} value={data}
    //           >
    //             <option value={true}>Sí</option>
    //             <option value={false}>No</option>
    //           </Select>
    //       </div>
    //     : ""
    //   ),
    // },
    {
      id: "trabajadores",
      numeric: false,
      disablePadding: false,
      label: "EMPLEADOS",
      overrideFunc: (data, row) => (
        data !== "" ?
          <div className="trabajadoresSelect">
            <Select 
                label="Trabajadores"
                native 
                name="trabajadores" 
                id="trabajadores" 
                style={{ textTransform: 'uppercase', padding: 0, fontSize: '11px', color: data ? "green" : 'red' }} 
                onChange={(event) => handleChangeTrabajadores(event, row.nif)} value={data}
              >
                <option value={true}>Sí</option>
                <option value={false}>No</option>
              </Select>
          </div>
        : ""
      ),
    },
    {
      id: "ct",
      numeric: false,
      disablePadding: false,
      label: "AÑADIR CENTRO TRABAJO",
      noObjeto:true,
      overrideFunc: (data, row) =>
        data !== "" ?
          <Link to={"/comunidades/" + row.nif + "/nuevoCentro"}>
            <img
              src={Add}
              alt="Más"
              className="tableIcon"
              style={{margin:0}}
            />
          </Link>
          : null
    },
    {
      id: "cambiar",
      numeric: false,
      disablePadding: false,
      label: "CAMBIAR AAFF",
      noObjeto:true,
      overrideFunc: (data, row) =>
        data !== "" ?
          <img
            src={Change}
            alt="CAMBIAR AAFF"
            className="tableIcon"
            style={{margin:0}}
            onClick={() => mostrarPanel(true, row)}
          ></img>
          : null
    },
    {
      id: "baja",
      numeric: false,
      disablePadding: false,
      label: "BAJA",
      noObjeto:true,
      overrideFunc: (data, row) =>
      (
        row.isWorkcenter ? (
          <img
            src={Delete}
            alt="BAJA"
            className="tableIcon"
            style={{margin:0}}
            onClick={() => eliminarCentroTrabajo(row.eliminar)}
          ></img>
        ) : (
          <img
            src={Delete}
            alt="BAJA"
            className="tableIcon"
            style={{margin:0}}
            onClick={() => bajaFinca(row.eliminar)}
          ></img>
        )
      )

    },
  ];
  const mostrarPanel = (opened, finca) => {
    setOpen(opened);
    setFincaCambiarAAFF(finca);
  };

  function bajaFinca(f) {
    Swal.fire({
      title: "Dar de baja " + f.razonSocial + "?",
      showCancelButton: true,
      confirmButtonText: "Dar de baja",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteFinca(f.nif)
          .then((res) => {
            if (res.message === "PROPERTIE_DELETED") {
              toast.success("finca dada de baja");
              setRecargar(!recargar);
            } else {
              toast.error("Error al dar de baja la finca");
            }
          })
          .catch((error) => {
            console.log(error);
            toast.error("Error al dar de baja la finca");
          });
      }
    });
  }

  function eliminarCentroTrabajo(f) {
    Swal.fire({
      title: "Dar de baja " + f.razonSocial + "?",
      showCancelButton: true,
      confirmButtonText: "Dar de baja",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCentroTrabajo(f.id)
          .then((res) => {
            toast.success("Centro de trabajo eliminado con éxito");
            setRecargar(!recargar);
          })
          .catch((error) => {
            console.log(error);
            toast.error("Error al eliminar el centro de trabajo");
          });
        }
      })
    }
    const hijoAPadre = (datosHijo) => {
        setRecargar(datosHijo);
    };

    const filtrarDatos = (filtros) => {
      setBusqueda(filtros);
    };

    const user = useContext(UsuarioContext);
    const clearFilters = () => {
      if (user.rol === "CONSUL" && primeraCarga) {
        setBusqueda({
          ...busqueda,
          consul_id: user.id 
        });
        setPrimeraCarga(false);
      }
      else{
        setBusqueda({});
      }
      
      setClearFilterActivo(true);
    }
    return (
        <>
            <FiltrosComunidades onFilter={filtrarDatos} clearFilter={clearFilters} filtrosIniciales={busqueda} />
            <div className="tableWrapper">
            <Box className="pageContent">
                <Box sx={{ mb: 10 }}>
                <DataTable
                    data={data.rows}
                    isLoading={isLoading}
                    headers={comunidadesRows}
                    fetcher={fetchData}
                    currentPage={data.current_page}
                    meta={data.meta}
                    totalItems={data.total}
                    totalPages={data.totalPages}
                    nextPage={data.next_page}
                    filtros={busqueda}
                    clearFilterActivo={clearFilterActivo}
                    setClearFilterActivo={setClearFilterActivo}
                    mensaje={"No hay comunidades"}
                    enumerate={true}
                    // propCaducada={true}
                />
                </Box>
            </Box>
            </div>
            {open && (
                <DialogoCambioAAFF
                    open={open}
                    setOpen={setOpen}
                    finca={fincaCambiarAAFF}
                    aaff={props.params.nif}
                    tipo={"cambiar"}
                    hijoAPadre={hijoAPadre}
                />
            )}
            <style jsx="true">{`
              #trabajadores, #activo, #estadoCae, #estadoLopd{
                padding:0 25px 0 0;
                border: none;
              }
              .trabajadoresSelect fieldset, .trabajadoresSelect select:hover, .activoSelect fieldset, .activoSelect select:hover{
                border-color: rgba(0, 0, 0, 0) !important;
              }               
          `}</style>
        </>
    )
}
export default withRouter(ListadoComunidades);