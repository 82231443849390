import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { alpha, styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { TiArrowBack } from "react-icons/ti";
import Add from "../../../icons/add.svg";
import CircularProgress from "@mui/material/CircularProgress";
import { getAAFF, getOneAAFF, getFincasAAFF, getProveedor } from "../../../services";
import { cambiarFormatoNombre, daysDiff, editarComentario, eliminarComentario, formatoFechasEs } from "../../../functions/funciones";
import { withRouter } from "../../../providers/withRouter";
import Editar from "../../../icons/editar.svg";
import {
  abrirArchivo,
  subirDocumentacionProveedor,
  subirDeclaracionResponsable,
  validarDocumentacion,
  verDocumentacion,
  verDeclRes,
  validarDeclRes,
} from "./../../../providers/documentos";
import ModalEditar from "./editarDatosProv";
import { DialogoSubirDocumentacion } from "../../../providers/Dialogos";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import { UsuarioContext } from "../../../providers/userContext";
import Delete from "../../../icons/delete.svg";
import Delegations from "../../../components/suppliers/delegations";
import { createComment } from "../../../services/comment.service";
import { IoSave } from "react-icons/io5";
import ModalEditarComentarios from "./../../../components/suppliers/AllComments";
import ControlledAccordions from "./accAAFFCom";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase": {
    color: "#FF5252",
    "&:hover": {
      backgroundColor: alpha("#FF5252", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase + .MuiSwitch-track": {
    backgroundColor: "#FF5252",
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#7bc977",
    backgroundColor: alpha("#7bc977", theme.palette.action.hoverOpacity),
    "&:hover": {
      backgroundColor: alpha("#7bc977", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#7bc977",
  },
}));

const CustomPendienteSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase": {
    color: "#FFD700",
  },
  "& .MuiSwitch-switchBase + .MuiSwitch-track": {
    backgroundColor: "#FFD700",
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#FFD700",
    backgroundColor: alpha("#FFD700", theme.palette.action.hoverOpacity),
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#FFD700",
  },
}));

const Proveedor = (props) => {
  // const delegations = 1;
  const user = useContext(UsuarioContext);
  const [aaff, setAaff] = useState({});
  const [proveedor, setProveedor] = useState({});
  const [fincas, setFincas] = useState([]);
  const [modalEditarOpened, setModalEditarOpened] = useState(false);
  const [modalCommentsOpened, setModalCommentsOpened] = useState(false);
  const [recargar, setRecargar] = useState(false);
  const [open, setOpen] = useState(0);
  const [dialog, setDialog] = useState({});
  const [comentarios, setComentarios] = useState([]);
  const [comentario, setComentario] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const hijoAPadre = () => {
    setRecargar(!recargar);
  };
  useEffect(() => {
    fetchData();
  }, [recargar]);

  const fetchData = async () => {
    const res = await getOneAAFF(props.params.nifAAFF)
    const response = await getFincasAAFF(res.id);

    let fincas = [];
    setAaff(res);
    setFincas(res?.properties);

    const resProv = await getProveedor(props.params.nifProveedor)
    setProveedor(resProv);

    const comments = resProv?.comments?.length > 0 ? resProv?.comments?.map(comment => {
      comment.usuario = comment.user?.consultant?.[0] || comment.user?.admin?.[0]
      return comment
    }) : [];
    setComentarios(comments.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
    setIsLoading(false);
  }

  const mostrarPanel = (data) => {
    setDialog({
      opened: true,
      title: data.title,
      docName: data.nombreDoc,
      fechaCaducidad: undefined,
      fecha: undefined,
    });
    setOpen(data.open);
  };

  const handleSubirDocsCallback = () => {
    setRecargar(!recargar);
  };

  const comprobarArchivos = async (validado, status, ruta, id, idDoc, rol, nif) => {
    // Llama a la función importada y pasa la devolución de llamada

    if (validado && status) {
      verDocumentacion(ruta, id, idDoc, rol, nif).then((resultado) => {
        handleSubirDocsCallback(resultado)
      })
    } else {
      subirDocumentacionProveedor(ruta, id, idDoc, rol, nif).then((resultado) => {
        handleSubirDocsCallback(resultado)
      })
    }
  };

  const comprobarValidacion = async (ruta, id, nif) => {
    validarDocumentacion(ruta, id, nif).then((resultado) => {
      handleSubirDocsCallback(resultado)
    })
  }

  const editarComment = async (id, comm) => {
    editarComentario(id, user.id, comm).then((resultado) => {
      handleSubirDocsCallback(resultado)
    })
  }
  const eliminarComment = async (id) => {
    eliminarComentario(id).then((resultado) => {
      handleSubirDocsCallback(resultado)
    })
  }

  function guardarComentarios() {
    try {
      let infoComentario = {
        prop_id: null,
        supp_id: proveedor.id,
        aaff_id: null,
        texto: comentario,
        tipo: "comment",
        status: 1,
      }
      createComment(infoComentario).then(res => {
        handleSubirDocsCallback(res)
        setComentario('')
      })
    } catch (error) {
      console.log(error)
    }

  }

  function cambiarComentarios(evt) {
    const value = evt.target.value;
    setComentario(value);
  }

  const redirectToSomeRoute = (nif) => {
    setIsLoading(true)
    setRecargar(!recargar)
    props.navigate(`/proveedores/${nif}`);
  }

  const comprobarDeclRes = async (nifProv, id, nifAAFF, ruta, idDeclRes, validado, status) => {
    // Llama a la función importada y pasa la devolución de llamada
    if (validado && status) {
      verDeclRes(nifProv, id, nifAAFF, ruta, idDeclRes).then((resultado) => {
        handleSubirDocsCallback(resultado)
      })
    } else {
      subirDeclaracionResponsable(nifProv, id, ruta, idDeclRes).then((resultado) => {
        handleSubirDocsCallback(resultado)
      })
    }
  };
  const comprobarValDeclRes = async (ruta, idDeclRes, nifAAFF) => {

    validarDeclRes(ruta, idDeclRes, nifAAFF).then((resultado) => {

      handleSubirDocsCallback(resultado)
    })
  };

  return isLoading ? <CircularProgress style={{color:'#92004D', position: 'absolute', top: '50%', left: '50%'}} /> : 
    <>
      <ModalEditarComentarios
        comentarios={comentarios}
        opened={modalCommentsOpened}
        eliminarComment={eliminarComment}
        editarComment={editarComment}
        handleClose={() => {
          setModalCommentsOpened(false);
          setRecargar(!recargar);
        }}
      />
      <ModalEditar
        proveedor={proveedor}
        opened={modalEditarOpened}
        hijoAPadre={hijoAPadre}
        handleClose={() => {
          setModalEditarOpened(false);
          setRecargar(!recargar);
        }}
      />
      <BreadCrumb rutas={[{ path: '/aaff', label: 'Administrador' }, { path: `/aaff/${aaff?.nif}/comunidades`, label: `${aaff?.razonSocial}` }, { path: `/aaff/${aaff?.nif}/proveedores`, label: 'proveedores' }, { path: `/aaff/${aaff?.nif}/proveedores/${proveedor?.nif}`, label: `${proveedor?.razonSocial}` }]} style={{ margin: '0 10px' }} />
      <div className="pageGrid">
        <div className="gridCard">
          <div className="gridHead">
            INFORMACIÓN GENERAL
            <Link to={`/aaff/${aaff?.nif}/proveedores`}>
              <div className="gridHeadRight">
                {/* {"<< volver al listado de Proveedores"} */}
                <TiArrowBack style={{ fontSize: 23 }} />
              </div>
            </Link>
          </div>
          <div className="gridSubhead editarSubhead">
            <div>DATOS EMPRESA</div>
            <div
              className="editarBtn"
              onClick={() => setModalEditarOpened(true)}
            >
              <span style={{ marginRight: 10 }}>Editar</span>{" "}
              <img src={Editar} alt="Editar" />
            </div>
          </div>
          <div className="datosGrid">
            <p>
              <b>Nombre proveedor:</b> {proveedor?.razonSocial}
            </p>
            <p>
              <b>Dirección:</b> {proveedor?.direccion}
            </p>
            <p>
              <b>NIF:</b> {proveedor?.nif}
            </p>
            <p>
              <b>Municipio:</b> {cambiarFormatoNombre(proveedor?.municipio)}
            </p>
            <p>
              <b>Persona de contacto:</b> {proveedor?.nombreContacto}
            </p>
            <p>
              <b>CP:</b> {proveedor?.codigoPostal}
            </p>
            <p>
              <b>Email de contacto:</b> {proveedor?.emailContacto}
            </p>
            <p>
              <b>Provincia:</b> {proveedor?.provincia}
            </p>
            <p>
              <b>Email de login:</b> {proveedor?.emailLogin}
            </p>
            <p>
              <b>Telefono:</b> {proveedor?.telefonoContacto}
            </p>
            <p>
              <b>Fecha de alta:</b>{" "}
              {new Date(proveedor?.createdAt).toLocaleDateString()}
            </p>
            <p>
              <b>Ultima reclamación:</b>{" "}
              {proveedor?.ultimaReclamacion === undefined ||
                proveedor?.ultimaReclamacion === null
                ? new Date(proveedor?.createdAt).toLocaleDateString()
                : new Date(proveedor?.ultimaReclamacion).toLocaleDateString()}
            </p>
          </div>
          <div className="datosGrid">
            <div style={{ position: 'relative' }}>
              <p style={{ paddingBottom: 10 }}>
                <b>Comentarios:</b>
              </p>
              <div style={{ overflow: 'scroll', maxHeight: 200, maxWidth: 350 }}>
                {comentarios.lenght === 0
                  ? "-"
                  :
                  <>
                    {comentarios.slice(0, 4).map((comm, i) => {
                      return (
                        <div key={i}>
                          <img
                            src={Delete}
                            alt="ELIMINAR"
                            className="tableIcon"
                            onClick={() => eliminarComment(comm.id)}
                            style={{ width: 14 }}
                          ></img>
                          <img
                            src={Editar}
                            alt="Editar"
                            className="tableIcon"
                            onClick={() => editarComment(comm.id, comm)}
                            style={{ width: 14 }}
                          ></img>
                          {formatoFechasEs(comm.updatedAt)} - {comm.usuario?.nombre + " " + comm.usuario?.apellidos} - {comm.texto}</div>
                      )
                    })}
                    {comentarios.length > 4 &&
                      <div >
                        <button onClick={() => setModalCommentsOpened(true)} style={{ margin: '10px 0', borderRadius: '5px 5px', backgroundColor: '#92004D', color: '#ffffff', padding: '5px 10px' }}>Mostrar más</button>
                      </div>
                    }
                  </>
                }{" "}
              </div>
            </div>
            <div>
              <form
                noValidate
                autoComplete="off"
                style={{ display: "flex", alignItems: "end" }}
              >
                <textarea
                  style={{
                    marginRight: "10px",
                    border: "1px solid #ccc",
                    width: "100%",
                  }} id="comentarios" name="comentarios" label="Comentarios" onChange={cambiarComentarios} multiline rows={6} columns={100}
                />
                <div>
                  <button type="button" className="bigBtn" onClick={() => guardarComentarios(comentarios)}>
                    <IoSave style={{ fontSize: 23 }} />
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="gridSubhead">DOCUMENTOS EMPRESA</div>
          <div className="tableContainer">
            <table>
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>Certificado LOPD</th>
                  <th style={{ textAlign: "center" }}>Seguro Resp. Civil</th>
                  <th style={{ textAlign: "center" }}>Justificante pago SPA</th>
                  <th style={{ textAlign: "center" }}>
                    Planificación preventiva
                  </th>
                  <th style={{ textAlign: "center" }}>
                    Identificación riesgos
                  </th>
                  <th style={{ textAlign: "center" }}>Certificado Seg. Social</th>
                  <th style={{ textAlign: "center" }}>Vigilancia salud</th>
                  <th style={{ textAlign: "center" }}>
                    Declaración responsable
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ textAlign: "center" }}>
                    {proveedor?.docssupps?.find(d => d.tipoDoc === 1)?.pendiente ? (
                      <CustomPendienteSwitch
                        checked={false}
                        onClick={() => comprobarValidacion(proveedor?.docssupps?.find(d => d.tipoDoc === 1)?.ruta, proveedor?.docssupps?.find(d => d.tipoDoc === 1)?.id, proveedor?.nif)}
                      />
                    ) : (
                      <CustomSwitch
                        checked={proveedor?.docssupps?.find(d => d.tipoDoc === 1)?.validado}
                        onClick={
                          () => comprobarArchivos(
                            proveedor?.docssupps?.find(d => d.tipoDoc === 1)?.validado,
                            proveedor?.docssupps?.find(d => d.tipoDoc === 1)?.status,
                            proveedor?.docssupps?.find(d => d.tipoDoc === 1)?.ruta,
                            proveedor?.docssupps?.find(d => d.tipoDoc === 1)?.id,
                            1,
                            user.rol,
                            proveedor?.nif
                          )
                        }
                      />
                    )}
                    <small style={{ display: "block" }}>-</small>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {proveedor?.docssupps?.find(d => d.tipoDoc === 2)?.pendiente && proveedor?.docssupps?.find(d => d.tipoDoc === 2)?.status ? (
                      <CustomPendienteSwitch
                        checked={false}
                        onClick={() => comprobarValidacion(proveedor?.docssupps?.find(d => d.tipoDoc === 2)?.ruta, proveedor?.docssupps?.find(d => d.tipoDoc === 2)?.id, proveedor?.nif)}
                      />
                    ) : (
                      <CustomSwitch
                        checked={proveedor?.docssupps?.find(d => d.tipoDoc === 2)?.validado && proveedor?.docssupps?.find(d => d.tipoDoc === 2)?.status}
                        onClick={
                          () => comprobarArchivos(
                            proveedor?.docssupps?.find(d => d.tipoDoc === 2)?.validado,
                            proveedor?.docssupps?.find(d => d.tipoDoc === 2)?.status,
                            proveedor?.docssupps?.find(d => d.tipoDoc === 2)?.ruta,
                            proveedor?.docssupps?.find(d => d.tipoDoc === 2)?.id,
                            2,
                            user.rol,
                            proveedor?.nif
                          )
                        }
                      />
                    )}
                    <small style={{ display: "block" }}>
                      {proveedor?.docssupps?.find(d => d.tipoDoc === 2)?.fechaCaducidad !==
                        null && proveedor?.docssupps?.find(d => d.tipoDoc === 2)?.status ? (
                        <span
                          style={{
                            color:
                              daysDiff(
                                new Date(
                                  proveedor?.docssupps?.find(d => d.tipoDoc === 2)?.fechaCaducidad
                                ),
                                new Date()
                              ) > 30
                                ? "red"
                                : "black",
                          }}
                        >
                          {new Date(
                            proveedor?.docssupps?.find(d => d.tipoDoc === 2)?.fechaCaducidad
                          ).toLocaleDateString()}
                        </span>
                      ) : (
                        "-"
                      )}
                    </small>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {proveedor?.noProcede ? "No procede" :
                      <>
                        {proveedor?.docssupps?.find(d => d.tipoDoc === 3)?.pendiente ? (
                          <CustomPendienteSwitch
                            checked={false}
                            onClick={() => comprobarValidacion(proveedor?.docssupps?.find(d => d.tipoDoc === 3)?.ruta, proveedor?.docssupps?.find(d => d.tipoDoc === 3)?.id, proveedor?.nif)}
                          />
                        ) : (
                          <CustomSwitch
                            checked={proveedor?.docssupps?.find(d => d.tipoDoc === 3)?.validado}
                            onClick={
                              () => comprobarArchivos(
                                proveedor?.docssupps?.find(d => d.tipoDoc === 3)?.validado,
                                proveedor?.docssupps?.find(d => d.tipoDoc === 3)?.status,
                                proveedor?.docssupps?.find(d => d.tipoDoc === 3)?.ruta,
                                proveedor?.docssupps?.find(d => d.tipoDoc === 3)?.id,
                                3,
                                user.rol,
                                proveedor?.nif
                              )
                            }
                          />
                        )}
                        <small style={{ display: "block" }}>
                          {proveedor?.docssupps?.find(d => d.tipoDoc === 3)?.fechaCaducidad !==
                            null ? (
                            <span
                              style={{
                                color:
                                  daysDiff(
                                    new Date(
                                      proveedor?.docssupps?.find(d => d.tipoDoc === 3)?.fechaCaducidad
                                    ),
                                    new Date()
                                  ) > 30
                                    ? "red"
                                    : "black",
                              }}
                            >
                              {new Date(
                                proveedor?.docssupps?.find(d => d.tipoDoc === 3)?.fechaCaducidad
                              ).toLocaleDateString()}
                            </span>
                          ) : (
                            "-"
                          )}
                        </small>
                      </>
                    }
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {proveedor?.noProcede ? "No procede" :
                      <>
                        {proveedor?.docssupps?.find(d => d.tipoDoc === 4)?.pendiente ? (
                          <CustomPendienteSwitch
                            checked={false}
                            onClick={() => comprobarValidacion(proveedor?.docssupps?.find(d => d.tipoDoc === 4)?.ruta, proveedor?.docssupps?.find(d => d.tipoDoc === 4)?.id, proveedor?.nif)}
                          />
                        ) : (
                          <CustomSwitch
                            checked={
                              proveedor?.docssupps?.find(d => d.tipoDoc === 4)?.validado
                            }
                            onClick={
                              () => comprobarArchivos(
                                proveedor?.docssupps?.find(d => d.tipoDoc === 4)?.validado,
                                proveedor?.docssupps?.find(d => d.tipoDoc === 4)?.status,
                                proveedor?.docssupps?.find(d => d.tipoDoc === 4)?.ruta,
                                proveedor?.docssupps?.find(d => d.tipoDoc === 4)?.id,
                                4,
                                user.rol,
                                proveedor?.nif
                              )
                            }
                          />
                        )}
                        <small style={{ display: "block" }}>-</small>
                      </>
                    }
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {proveedor?.docssupps?.find(d => d.tipoDoc === 5)?.pendiente ? (
                      <CustomPendienteSwitch
                        checked={false}
                        onClick={() => comprobarValidacion(proveedor?.docssupps?.find(d => d.tipoDoc === 5)?.ruta, proveedor?.docssupps?.find(d => d.tipoDoc === 5)?.id, proveedor?.nif)}
                      />
                    ) : (
                      <CustomSwitch
                        checked={
                          proveedor?.docssupps?.find(d => d.tipoDoc === 5)?.validado
                        }
                        onClick={
                          () => comprobarArchivos(
                            proveedor?.docssupps?.find(d => d.tipoDoc === 5)?.validado,
                            proveedor?.docssupps?.find(d => d.tipoDoc === 5)?.status,
                            proveedor?.docssupps?.find(d => d.tipoDoc === 5)?.ruta,
                            proveedor?.docssupps?.find(d => d.tipoDoc === 5)?.id,
                            5,
                            user.rol,
                            proveedor?.nif
                          )
                        }
                      />
                    )}
                    <small style={{ display: "block" }}>-</small>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {proveedor?.docssupps?.find(d => d.tipoDoc === 6)?.pendiente ? (
                      <CustomPendienteSwitch
                        checked={false}
                        onClick={() => comprobarValidacion(proveedor?.docssupps?.find(d => d.tipoDoc === 6)?.ruta, proveedor?.docssupps?.find(d => d.tipoDoc === 6)?.id, proveedor?.nif)}
                      />
                    ) : (
                      <CustomSwitch
                        checked={proveedor?.docssupps?.find(d => d.tipoDoc === 6)?.validado}
                        onClick={
                          () => comprobarArchivos(
                            proveedor?.docssupps?.find(d => d.tipoDoc === 6)?.validado,
                            proveedor?.docssupps?.find(d => d.tipoDoc === 6)?.status,
                            proveedor?.docssupps?.find(d => d.tipoDoc === 6)?.ruta,
                            proveedor?.docssupps?.find(d => d.tipoDoc === 6)?.id,
                            6,
                            user.rol,
                            proveedor?.nif
                          )
                        }
                      />
                    )}
                    <small style={{ display: "block" }}>
                      {proveedor?.docssupps?.find(d => d.tipoDoc === 6)?.fechaCaducidad !==
                        null ? (
                        <span
                          style={{
                            color:
                              daysDiff(
                                new Date(
                                  proveedor?.docssupps?.find(d => d.tipoDoc === 6)?.fechaCaducidad
                                ),
                                new Date()
                              ) > 30
                                ? "red"
                                : "black",
                          }}
                        >
                          {new Date(
                            proveedor?.docssupps?.find(d => d.tipoDoc === 6)?.fechaCaducidad
                          ).toLocaleDateString()}
                        </span>
                      ) : (
                        "-"
                      )}
                    </small>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {proveedor?.noProcede ? "No procede" :
                      <>
                        {proveedor?.docssupps?.find(d => d.tipoDoc === 7)?.pendiente ? (
                          <CustomPendienteSwitch
                            checked={false}
                            onClick={() => comprobarValidacion(proveedor?.docssupps?.find(d => d.tipoDoc === 7)?.ruta, proveedor?.docssupps?.find(d => d.tipoDoc === 7)?.id, proveedor?.nif)}
                          />
                        ) : (
                          <CustomSwitch
                            checked={proveedor?.docssupps?.find(d => d.tipoDoc === 7)?.validado}
                            onClick={
                              () => comprobarArchivos(
                                proveedor?.docssupps?.find(d => d.tipoDoc === 7)?.validado,
                                proveedor?.docssupps?.find(d => d.tipoDoc === 7)?.status,
                                proveedor?.docssupps?.find(d => d.tipoDoc === 7)?.ruta,
                                proveedor?.docssupps?.find(d => d.tipoDoc === 7)?.id,
                                7,
                                user.rol,
                                proveedor?.nif
                              )
                            }
                          />
                        )}
                        <small style={{ display: "block" }}>
                          {proveedor?.docssupps?.find(d => d.tipoDoc === 7)?.fechaCaducidad !==
                            null ? (
                            <span
                              style={{
                                color:
                                  daysDiff(
                                    new Date(
                                      proveedor?.docssupps?.find(d => d.tipoDoc === 7)?.fechaCaducidad
                                    ),
                                    new Date()
                                  ) > 30
                                    ? "red"
                                    : "black",
                              }}
                            >
                              {new Date(
                                proveedor?.docssupps?.find(d => d.tipoDoc === 7)?.fechaCaducidad
                              ).toLocaleDateString()}
                            </span>
                          ) : (
                            "-"
                          )}
                        </small>
                      </>
                    }
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {proveedor?.declres?.find(d => d.aaff_id === aaff.id)?.pendiente ? (
                      <CustomPendienteSwitch
                        checked={false}
                        // onClick={() => abrirArchivo(proveedor?.declres?.find( d => d.aaff_id === aaff.id )?.ruta)}
                        onClick={() => comprobarValDeclRes(proveedor?.declres?.find(d => d.aaff_id === aaff.id)?.ruta, proveedor?.declres?.find(d => d.aaff_id === aaff.id).id, aaff.nif)}
                      />
                    ) : (
                      <CustomSwitch
                        checked={proveedor?.declres?.find(d => d.aaff_id === aaff.id)?.validado}
                        onClick={
                          () => comprobarDeclRes(
                            proveedor?.nif,
                            aaff.id,
                            aaff.nif,
                            proveedor?.declres?.find(d => d.aaff_id === aaff.id)?.ruta,
                            proveedor?.declres?.find(d => d.aaff_id === aaff.id)?.id,
                            proveedor?.declres?.find(d => d.aaff_id === aaff.id)?.validado,
                            proveedor?.declres?.find(d => d.aaff_id === aaff.id)?.status,
                          )
                        }
                      />
                    )}
                    <small style={{ display: "block" }}>-</small>
                  </td>
                </tr>
              </tbody>
            </table>
            <input type="file" id="fileElem" hidden></input>
          </div>

        </div>
        <div className="gridCard">
          <div className="gridHead">
            COMUNIDADES
          </div>
          <ControlledAccordions
            aaff={aaff}
            proveedor={proveedor}
            fincas={fincas}
          />
        </div>
        <div className="gridCard" /* style={delegations > 0 ? { display: 'block' } : { display: 'none' }} */>
          <div className="gridHead">
            {proveedor.parent_id !== null ? 'PROVEEDOR PRINCIPAL' : 'DELEGACIONES'}
            {proveedor.parent_id === null &&
              <Link to={`/delegacion/nueva/${proveedor.id}`} >
                <div className="gridHeadRight">
                  <img src={Add} alt="Más" width={15} />
                </div>
              </Link>
            }
          </div>
          <table style={{ margin: 15 }}>
            <thead>
              <tr
                style={{
                  fontSize: 10,
                  fontWeight: "normal",
                  textAlign: "center",
                }}
              >
                <th></th>
                {/* <th>Aviso</th> */}
              </tr>
            </thead>
            <tbody>
              <Delegations nif={props.params.nifProveedor} proveedor={proveedor} redirectToSomeRoute={redirectToSomeRoute} />
            </tbody>
          </table>
        </div>
      </div>
      {dialog.open === 1 && (
        <DialogoSubirDocumentacion
          open={dialog.open}
          proveedor={proveedor}
          dialog={dialog}
          hijoAPadre={hijoAPadre}
        />
      )}
      <style jsx="true">{`
          .pageGrid {
            position: relative;
            top: 00px;
            margin: 0 10px;
            display: grid;
            grid-template-columns: 5fr 3fr;
            grid-gap: 20px;
          }                
      `}</style>
    </>
  ;
};

export default withRouter(Proveedor);
