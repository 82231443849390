import React, { useEffect, useState } from "react";
import "./Login.css"
//import { toast } from "react-toastify";
import Logo from "../images/LAE.png"
import BackgroundImage from "../images/loginFondo.jpg"
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkToken, resetPassword, deleteToken } from "../services";
import { withRouter } from "../providers/withRouter";
import { FiEye, FiEyeOff } from 'react-icons/fi';
import jwt_decode from "jwt-decode";
import CheckPass from "../components/perfil/checkPass";

const ChangePassword = (props) => {

    const [showPassword, setShowPassword] = useState(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);
    const [error, setError] = useState('');
    const [password, setPassword] = useState('');
    const [password_confirmation, setpassword_confirmation] = useState('');
    const [expirado, setExpirado] = useState(false);
    const [passOk, setPassOk] = useState(false);
    const [envio, setEnvio] = useState(false);

    const token = decodeURIComponent(props.params.token)
    let decoded = jwt_decode(token);
    const from = props.params.from;
   
    useEffect(() => {
        fetchData()
    }, [])

    const eliminarToken = async () => {
        const response = await deleteToken({ token: token })
    }

    const fetchData = async () => {
        const response = await checkToken({ token: token })
        const tokenExpired = isTokenExpired(token);
        if (!response.id) {
            setExpirado(true)
            return "NO EXISTE"
        }
        if (tokenExpired) {
            setExpirado(true)
            eliminarToken()
        }

    }

    const isTokenExpired = (token) => {
        const decodedToken = jwt_decode(token);
        if (decodedToken.exp < Date.now() / 1000) {
            // El token ha caducado
            return true;
        }
        // El token no ha caducado
        return false;
    };


    // function validarPass(pass) {
    //     const tieneMinuscula = /[a-z]+/.test(pass);
    //     const tieneMayuscula = /[A-Z]+/.test(pass);
    //     const tieneNumero = /\d+/.test(pass);
    //     const tieneCaracterEspecial = /[@$!%*?&]+/.test(pass);
    //     const longitud = pass.length >= 8 ? true : false
    //     setMinuscula(tieneMinuscula)
    //     setMayuscula(tieneMayuscula)
    //     setNumero(tieneNumero)
    //     setCaracterEspecial(tieneCaracterEspecial)
    //     setLongitud(longitud)
    //     return tieneMinuscula && tieneMayuscula && tieneNumero && tieneCaracterEspecial && pass.length >= 8;
    // }

    const handleChangePassword = (e) => {
        setEnvio(false)
        setPassword(e.target.value);
        if (password_confirmation && e.target.value !== password_confirmation) {
            setError('Las contraseñas no coinciden');
        } else {
            setError('');
        }
    };

    const handleChangepassword_confirmation = (e) => {
        setEnvio(false)
        setpassword_confirmation(e.target.value);
        if (password && e.target.value !== password) {
            setError('Las contraseñas no coinciden');
        } else {
            setError('');
        }
    };
    const handleSubmit = async (e) => {
        setEnvio(true)
        let toastInfo = undefined
        e.preventDefault();
        // const isValid = validarPass(password)
        const isValid = passOk
        if (isValid) {
            if (password !== password_confirmation) {
                setError('Las contraseñas no coinciden');
                return;
            }
            toastInfo = toast.info("Actualizando contraseña", { autoClose: false });
            const email = decoded.email
            const resp = await resetPassword({ token, password, password_confirmation, from });
            if (resp.message = 'PASSWORD_UPDATED_SUCCESSFULLY') {
                toast.update(toastInfo, {
                    render: "contraseña actualizada",
                    type: toast.TYPE.SUCCESS,
                    autoClose: true
                });
                setTimeout(() => {
                    window.location.replace('/login')
                }, 1000)
            }
        } else {
            setError()
            return
        }

    };

    const passValida = async (isValid) => {
        setPassOk(isValid);
    }

    const handleVolver = () => {
        window.location.replace('/login')
    }

    return (
        <>
            {from !== "login" ? (
                <div
                    style={{ backgroundImage: `url(${BackgroundImage})` }}
                    className="backgroundDiv"
                >
                    {expirado ? (
                        <div className="loginForm" style={{ paddingBottom: 2 }}>
                            <div>
                                <img src={Logo} className="loginLogo" alt="Logo" />
                            </div>
                            <div style={{ backgroundColor: "#fff", margin: "0 15px" }}>
                                <p
                                    style={{
                                        margin: "0 30px 20px 30px",
                                        color: "#000",
                                        fontWeight: "normal",
                                    }}
                                >
                                    El tiempo ha expirado, por favor, vuelve a solicitar un cambio de
                                    contraseña
                                </p>
                                <button
                                    type="button"
                                    onClick={handleVolver}
                                    onKeyPress={(e) => e.key === "Enter" && handleVolver()}
                                    style={{ margin: "30px 0px 0", width: "100%" }}
                                >
                                    Volver al inicio
                                </button>
                            </div>
                        </div>
                    ) : (
                        <form className="loginForm" style={{ border: "1px solid #c1bdbd" }}>
                            <div>
                                <img src={Logo} className="loginLogo" alt="Logo" />
                            </div>
                            <div style={{ position: "relative", backgroundColor: "#fff", margin: 0 }}>
                                <label
                                    style={{
                                        margin: "0 30px 10px 30px",
                                        color: "#000",
                                        fontWeight: "normal",
                                    }}
                                >
                                    Contraseña nueva
                                </label>
                                <input
                                    type={showPassword ? "text" : "password"}
                                    name="newPassword"
                                    required
                                    minLength="8"
                                    onChange={handleChangePassword}
                                    onKeyPress={(e) => e.key === "Enter" && handleSubmit()}
                                    style={{
                                        paddingRight: "2.5rem",
                                        margin: "0 30px 20px 15px",
                                        width: 255,
                                    }}
                                />
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setShowPassword(!showPassword);
                                    }}
                                    style={{
                                        position: "absolute",
                                        right: "0.5rem",
                                        top: "54%",
                                        transform: "translateY(-50%)",
                                        backgroundColor: "transparent",
                                        border: "none",
                                        cursor: "pointer",
                                        color: "#000",
                                    }}
                                >
                                    {showPassword ? <FiEyeOff /> : <FiEye />}
                                </button>
                            </div>
                            <div style={{ position: "relative", backgroundColor: "#fff", margin: 0 }}>
                                <label
                                    style={{
                                        margin: "0 30px 10px 30px",
                                        color: "#000",
                                        fontWeight: "normal",
                                    }}
                                >
                                    Repetir contraseña nueva
                                </label>
                                <input
                                    type={showRepeatPassword ? "text" : "password"}
                                    name="repeatNewPassword"
                                    required
                                    minLength="8"
                                    onChange={handleChangepassword_confirmation}
                                    onKeyPress={(e) => e.key === "Enter" && handleSubmit()}
                                    style={{
                                        paddingRight: "2.5rem",
                                        margin: "0 30px 20px 15px",
                                        width: 255,
                                    }}
                                />
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setShowRepeatPassword(!showRepeatPassword);
                                    }}
                                    style={{
                                        position: "absolute",
                                        right: "0.5rem",
                                        top: "54%",
                                        transform: "translateY(-50%)",
                                        backgroundColor: "transparent",
                                        border: "none",
                                        cursor: "pointer",
                                        color: "#000",
                                    }}
                                >
                                    {showRepeatPassword ? <FiEyeOff /> : <FiEye />}
                                </button>
                            </div>
                            <button
                                type="button"
                                onClick={handleSubmit}
                                onKeyPress={(e) => e.key === "Enter" && handleSubmit()}
                            >
                                Cambiar
                            </button>
                            <div style={{ backgroundColor: "#fff", margin: "0px 15px" }}>
                                <p
                                    style={{
                                        display: "block",
                                        color: "#cf5c5c",
                                        fontWeight: "normal",
                                        margin: "0 15px",
                                    }}
                                >
                                    {error}
                                </p>
                            </div>
                            <CheckPass password={password} enviar={envio} passValida={passValida} />
                        </form>
                    )}
                </div>
            ) : (
                <div
                    style={{ backgroundImage: `url(${BackgroundImage})` }}
                    className="backgroundDiv"
                >
                    <form className="loginForm" style={{ border: "1px solid #c1bdbd" }}>
                        <div>
                            <img src={Logo} className="loginLogo" alt="Logo" />
                        </div>
                        <div style={{ position: "relative", backgroundColor: "#fff", margin: 0 }}>
                            <label
                                style={{
                                    margin: "0 30px 10px 30px",
                                    color: "#000",
                                    fontWeight: "normal",
                                }}
                            >
                                Contraseña nueva
                            </label>
                            <input
                                type={showPassword ? "text" : "password"}
                                name="newPassword"
                                required
                                minLength="8"
                                onChange={handleChangePassword}
                                onKeyPress={(e) => e.key === "Enter" && handleSubmit(e)}
                                style={{
                                    paddingRight: "2.5rem",
                                    margin: "0 30px 20px 15px",
                                    width: 255,
                                }}
                            />
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    setShowPassword(!showPassword);
                                }}
                                style={{
                                    position: "absolute",
                                    right: "0.5rem",
                                    top: "54%",
                                    transform: "translateY(-50%)",
                                    backgroundColor: "transparent",
                                    border: "none",
                                    cursor: "pointer",
                                    color: "#000",
                                }}
                            >
                                {showPassword ? <FiEyeOff /> : <FiEye />}
                            </button>
                        </div>
                        <div style={{ position: "relative", backgroundColor: "#fff", margin: 0 }}>
                            <label
                                style={{
                                    margin: "0 30px 10px 30px",
                                    color: "#000",
                                    fontWeight: "normal",
                                }}
                            >
                                Repetir contraseña nueva
                            </label>
                            <input
                                type={showRepeatPassword ? "text" : "password"}
                                name="repeatNewPassword"
                                required
                                minLength="8"
                                onChange={handleChangepassword_confirmation}
                                onKeyPress={(e) => e.key === "Enter" && handleSubmit()}
                                style={{
                                    paddingRight: "2.5rem",
                                    margin: "0 30px 20px 15px",
                                    width: 255,
                                }}
                            />
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    setShowRepeatPassword(!showRepeatPassword);
                                }}
                                style={{
                                    position: "absolute",
                                    right: "0.5rem",
                                    top: "54%",
                                    transform: "translateY(-50%)",
                                    backgroundColor: "transparent",
                                    border: "none",
                                    cursor: "pointer",
                                    color: "#000",
                                }}
                            >
                                {showRepeatPassword ? <FiEyeOff /> : <FiEye />}
                            </button>
                        </div>
                        <button
                            type="button"
                            onClick={(e) => handleSubmit(e)}
                            onKeyPress={(e) => e.key === "Enter" && handleSubmit(e)}
                        >
                            Cambiar
                        </button>
                        <div style={{ backgroundColor: "#fff", margin: "0px 15px" }}>
                            <p
                                style={{
                                    display: "block",
                                    color: "#cf5c5c",
                                    fontWeight: "normal",
                                    margin: "0 15px",
                                }}
                            >
                                {error}
                            </p>
                        </div>
                        <CheckPass password={password} enviar={envio} passValida={passValida} />
                    </form>
                </div>
            )
            }
        </>
        // <div style={{ backgroundImage: `url(${BackgroundImage})` }} className="backgroundDiv">
        //     {expirado && 
        //         <div className="loginForm" style={{ paddingBottom: 2 }}>
        //             <div><img src={Logo} className="loginLogo" alt="Logo" /></div>
        //             <div style={{ backgroundColor:'#fff', margin:'0 15px'}}>
        //                 <p style={{margin: "0 30px 20px 30px !important", color: '#000', fontWeight: 'normal'}}>El tiempo ha expirado, por favor, vuelve a solicitar un cambio de contraseña</p>
        //                 <button type="button" onClick={(e) => handleVolver(e) } onKeyPress={(e) => e.key === 'Enter' ? handleVolver() : ''} style={{ margin: '30px 0px 0', width: '100%' }}>Volver al inicio</button>
        //             </div>
        //         </div>
        //     }
        //     {!expirado &&
        //         <form className="loginForm" style={{border: "1px solid #c1bdbd"}}>
        //             <div><img src={Logo} className="loginLogo" alt="Logo" /></div>
        //             <div style={{ position: 'relative', backgroundColor:'#fff', margin:0 }}>
        //                 <label style={{margin: "0 30px 10px 30px", color: '#000', fontWeight: 'normal'}}>Contraseña nueva</label>
        //                 <input type={showPassword ? 'text' : 'password'} name="newPassword" required minLength="8"  onChange={handleChangePassword} onKeyPress={(e) => e.key === 'Enter' ? handleSubmit() : ''} 
        //                     style={{ paddingRight: '2.5rem', margin: '0 30px 20px 15px', width: 255 }} />
        //                 <button onClick={(e) => {
        //                     e.preventDefault()
        //                     setShowPassword(!showPassword)
        //                 }} style={{
        //                     position: 'absolute',
        //                     right: '0.5rem', // Posicionar el botón a la derecha del input
        //                     top: '54%',
        //                     transform: 'translateY(-50%)',
        //                     backgroundColor: 'transparent',
        //                     border: 'none',
        //                     cursor: 'pointer',
        //                     color:'#000'
        //                 }}>
        //                     {showPassword ? <FiEyeOff /> : <FiEye />}
        //                 </button>
        //             </div>
        //             <div style={{ position: 'relative', backgroundColor:'#fff', margin:0 }}>
        //                 <label style={{margin: "0 30px 10px 30px", color: '#000', fontWeight: 'normal'}}>Repetir contraseña nueva</label>
        //                 <input type={showRepeatPassword ? 'text' : 'password'} name="repeatNewPassword" required minLength="8"  onChange={handleChangepassword_confirmation} onKeyPress={(e) => e.key === 'Enter' ? handleSubmit() : ''} 
        //                     style={{ paddingRight: '2.5rem', margin: '0 30px 20px 15px', width: 255 }} />
        //                 <button onClick={(e) => {
        //                     e.preventDefault()
        //                     setShowRepeatPassword(!showRepeatPassword)
        //                 }} style={{
        //                     position: 'absolute',
        //                     right: '0.5rem', // Posicionar el botón a la derecha del input
        //                     top: '54%',
        //                     transform: 'translateY(-50%)',
        //                     backgroundColor: 'transparent',
        //                     border: 'none',
        //                     cursor: 'pointer',
        //                     color:'#000'
        //                 }}>
        //                     {showRepeatPassword ? <FiEyeOff /> : <FiEye />}
        //                 </button>
        //             </div>
        //             {/* <input type="password" required minLength="8" onChange={handleChange} onKeyPress={(e) => e.key === 'Enter' ? handleSubmit() : ''} /> */}
        //             <button type="button" onClick={(e) => handleSubmit(e) } onKeyPress={(e) => e.key === 'Enter' ? handleSubmit() : ''}>Cambiar</button>
        //             <div style={{backgroundColor:'#fff', margin:'0px 15px 0px 15px' }}>
        //                 <p style={{ display: 'block', color: '#cf5c5c', fontWeight: 'normal', margin: '0 15 !important' }}>{error}</p>
        //             </div>
        //             <CheckPass password={password} enviar={envio} passValida={passValida} />
        //         </form>
        //     }
        // </div>
    );
}

export default withRouter(ChangePassword);