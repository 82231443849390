import { Grid } from '@mui/material';
import React from "react";
import InicioCard from "../../../components/InicioCard";
import AAFFIcon from "../../../images/aaff.png"
import ConsultoresIcon from "../../../images/consultores.png"
import TecnicosIcon from "../../../images/tecnicos.png"
import ProveedoresIcon from "../../../images/proveedores.png"

function Inicio() {
  return (<>
    <div style={{
      textAlign: 'center',
      fontSize: 28,
      color: "#303248",
      fontWeight: 'bold',
      paddingTop: 90
    }}>Bienvenido a tu gestor de fincas</div>
    <div style={{
      textAlign: 'center',
      fontSize: 22,
      color: "#303248",
      marginTop: 10,
      marginBottom: 70
    }}>¿QUÉ QUIERES HACER?</div>
    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={5}>
      <Grid item><InicioCard icono={AAFFIcon} texto="Gestionar Administradores de Fincas" ruta="/aaff" /></Grid>
      <Grid item><InicioCard icono={ConsultoresIcon} texto="Gestionar Gest. doc" ruta="/consultores" /></Grid>
      <Grid item><InicioCard icono={ProveedoresIcon} texto="Gestionar proveedores" ruta="/proveedores" /></Grid>
      <Grid item><InicioCard icono={TecnicosIcon} texto="Gestionar Técnicos SPA" ruta="/tecnicos" /></Grid>
      <Grid item><InicioCard icono={TecnicosIcon} texto="Gestionar Comerciales" ruta="/comerciales" /></Grid>
    </Grid>

  </>);
}

export default Inicio;
