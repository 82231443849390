import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import { 
  TextField, 
  Select,
  MenuItem, 
  InputAdornment,
  Box,  
  FormControl, 
  InputLabel, 
  IconButton, 
  OutlinedInput,
  FormHelperText
 } from "@mui/material/";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import { cambiarProveedor } from "../../../services/proveedor.service";
import provinces from "@doncicuto/es-provinces";
import municipalities from "@doncicuto/es-municipalities";
import { municipiosIniciales, cambiarFormatoNombre } from "../../../functions/funciones";
import { ordenarProvinciasYMunicipios } from "../../../functions/ordenarListas";
import LaeButton from "../../../components/Buttons/LaeButton";
import { verificarCorreo, verificarTelefono , verificarCodigoPostal, verificarFecha } from "../../../functions/validator/formValidator";
import { useNavigate } from "react-router-dom";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85ch",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
};

const modal = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};


export default function TransitionsModal(props) {
  var d = new Date(
    props.proveedor.ultimaReclamacion === null
      ? props.proveedor.createdAt
      : props.proveedor.ultimaReclamacion
  );
  var mm = d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
  var dd = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
  var yy = d.getFullYear();
  let nuevoFormato = yy + "-" + mm + "-" + dd;
 
  const navigate = useNavigate();
  const [editarDatos, setEditarDatos] = useState({});
  const [listadoMunicipios, setListadoMunicipios] = useState([]);
  const [activo, setActivo] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true);
  useEffect(() => {
    // console.log(props)
    const proveedor = {
      nif: props.proveedor.nif,
      id: props.proveedor.id,
      razonSocial: props.proveedor.razonSocial,
      direccion: props.proveedor.direccion,
      nif: props.proveedor.nif,
      municipio: props.proveedor.municipio,
      nombreContacto: props.proveedor.nombreContacto,
      codigoPostal: props.proveedor.codigoPostal,
      emailLogin: props.proveedor.user?.email ? props.proveedor.user.email.trim() : "",
      emailContacto: props.proveedor.emailContacto ? props.proveedor.emailContacto.trim() : "",
      provincia: props.proveedor.provincia,
      telefonoContacto: props.proveedor.telefonoContacto,
      ultimaReclamacion: nuevoFormato,
      trabajadores: props.proveedor.noProcede
      // comentarios: props.proveedor.comentarios,
    };
    setEditarDatos(proveedor);
    setListadoMunicipios(municipiosIniciales(provinces, municipalities, props.proveedor.provincia))
  }, [props]);
  function handleEditClick () {
    setIsDisabled(!isDisabled); 
  };

  function handleChange(evt) {
    const value = evt.target.value;
    console.log(value) 
    setEditarDatos({
      ...editarDatos,
      [evt.target.name]: value,
    });
  }

  const editar = (proveedor) => {
   
    setActivo(true);
    let data = {
      razonSocial: proveedor.razonSocial,
      direccion: proveedor.direccion,
      nif: proveedor.nif,
      municipio: proveedor.municipio,
      nombreContacto: proveedor.nombreContacto,
      codigoPostal: proveedor.codigoPostal,
      emailLogin: proveedor.emailLogin,
      emailContacto: proveedor.emailContacto,
      provincia: proveedor.provincia,
      telefonoContacto: proveedor.telefonoContacto,
      ultimaReclamacion: proveedor.ultimaReclamacion,
      noProcede: proveedor.trabajadores === null ? true : proveedor.trabajadores
      // comentarios: proveedor.comentarios
    };    
    const toastInfo = toast.info("Guardando datos", { autoClose: false });
    cambiarProveedor(props.proveedor.nif, data)
      .then((res) => {
        let keys = Object.keys(data);
        let errores = false;
        const urlActual = window.location.pathname + window.location.search;

        // Expresión regular para reemplazar solo el NIF en la parte correcta de la URL
        const nuevaUrl = urlActual.replace(
          new RegExp(`proveedores/${props.proveedor.nif}`),
          `proveedores/${proveedor.nif}`
        );
        // Cambiar la URL sin recargar la página
        navigate(nuevaUrl, { replace: true });

        // keys.forEach(key => {
        //   switch (key) {

        //     case "emailContacto":

        //       let correoCorrecto = verificarCorreo(data.emailContacto);
        //       if (correoCorrecto === false) {
        //         toast.error("El email de contacto es incorrecto");
        //         errores = true;
        //         setActivo(false)
        //       }
        //       break;
        //     case "codigoPostal":

        //       let codigoPostalCorrecto = verificarCodigoPostal(data.codigoPostal);
        //       if (codigoPostalCorrecto === false) {
        //         toast.error("El codigo postal introducido es incorrecto");
        //         errores = true;
        //         setActivo(false)
        //       }
        //       break;
        //     case "telefonoContacto":

        //       let telefonoCorrecto = verificarTelefono(data.telefonoContacto);
        //       if (telefonoCorrecto === false) {
        //         toast.error("El teléfono  es incorrecto");
        //         errores = true;
        //         setActivo(false)
        //       }
        //       break;
        //     case "ultimaReclamacion":

        //       let fechaReclamacionCorrecta = verificarFecha(data.ultimaReclamacion);
        //       if (fechaReclamacionCorrecta === false) {
        //         toast.error("La fecha de ultima reclamación no puede ser superior a la actual");
        //         errores = true;
        //         setActivo(false)
        //       }
        //       break;
           
        //     default:
        //       break;
        //   }
        // });
       
        if (!errores && res.status >= 200 && res.status < 300) {
          toast.update(toastInfo, {
            render: "Datos guardados con éxito",
            type: toast.TYPE.SUCCESS,
            autoClose: true,
          });
          props.handleClose();
          props.hijoAPadre();
          setActivo(false)
        } else{
          toast.update(toastInfo, {
            render: "Error en al guardar los datos",
            type: toast.TYPE.ERROR,
            autoClose: true,
          });
        }
        
      })
      .catch((err) => {
        console.log(err)
        toast.update(toastInfo, {
          render: "ha ocurrido un error",
          type: toast.TYPE.ERROR,
          autoClose: true,
        });
        setActivo(false)
      });
    
  };
  
  const seleccionarMunicipios = (e) => {
    const nombreProvincia = e.target.value;
    const provinciaSeleccionada = provinces.find(p => p.name === nombreProvincia);
    const codProvincia = provinciaSeleccionada ? provinciaSeleccionada.code : "";

    const municipiosFiltrados = municipalities
    .filter(m => m.provCode === codProvincia)
    .map(m => ({
      ...m,
      name: cambiarFormatoNombre(m.name),
      value: m.name
    }));

    setEditarDatos(prevState => ({
      ...prevState,
      [e.target.name]: nombreProvincia
    }));

    setListadoMunicipios(municipiosFiltrados);
    // let codProvincia = ""
    // let municipios = []
    // console.log("asdasdasd")
    // provinces.map(p => {
    //   if (p.name === e.target.value) {
    //     codProvincia = p.code
    //   }
    // })
    // setEditarDatos({
    //   ...editarDatos,
    //   [e.target.name]: e.target.value
    // });
    // municipalities.map(m => {
    //   if (m.provCode === codProvincia) {
    //     municipios.push({ ...m, name: cambiarFormatoNombre(m.name), value: m.name })
    //   }
    // })
    // setListadoMunicipios(municipios)
  }

  return (
    <div>
      <Modal
        sx={modal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={modal}
        open={props.opened}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box sx={style}>
          <div
            style={{
              backgroundColor: "#fff",
              border: "2px solid #000",
              boxShadow:
                "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
              padding: "16px 32px 24px",
            }}
          >
            <h2 id="transition-modal-title">Editar datos</h2>
            <div id="transition-modal-description">
              <form noValidate autoComplete="off">
                <div
                  style={{
                    margin: "8px",
                    width: "100ch",
                    position: "relative",
                    display: "grid",
                    gridTemplateColumns: " repeat(2, 1fr)",
                    gridGap: "15px",
                    fontSize: "12px",
                  }}
                >
                  <TextField
                    id="nif"
                    name="nif"
                    label="NIF"
                    value={editarDatos.nif}
                    onChange={handleChange}
                  />
                  <TextField
                    id="razonSocial"
                    name="razonSocial"
                    label="Nombre proveedor"
                    value={editarDatos.razonSocial}
                    onChange={handleChange}
                  />
                  <TextField
                    id="direccion"
                    name="direccion"
                    label="Dirección"
                    value={editarDatos.direccion}
                    onChange={handleChange}
                  />
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="provincia">Provincia</InputLabel>
                    <Select 
                      label="provincia"
                      name="provincia" 
                      id="provincia" 
                      style={{ textTransform: 'uppercase' }} 
                      onChange={(e) => {
                        seleccionarMunicipios(e);
                      }} 
                      value={editarDatos?.provincia || ""}
                    >
                      <MenuItem value={props.proveedor.provincia || ""}>{props.proveedor.provincia || "provincia"}</MenuItem>
                      {provinces.sort(ordenarProvinciasYMunicipios)?.map((e, i) => {
                        return (
                          <MenuItem key={i} value={e.name}>
                            {e.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="municipio">Municipio</InputLabel>
                    <Select 
                      label="municipio"
                      name="municipio" 
                      id="municipio" 
                      style={{ textTransform: 'uppercase' }} 
                      onChange={handleChange} 
                      defaultValue={editarDatos?.municipio}
                    >
                      <MenuItem value={props.proveedor.municipio || ""}>{props.proveedor.municipio || "municipio"}</MenuItem>
                      {listadoMunicipios.sort(ordenarProvinciasYMunicipios)?.map((e, i) => {
                        return (
                          <MenuItem key={i} value={e.value}>
                            {e.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <TextField
                    id="nombreContacto"
                    name="nombreContacto"
                    label="Persona de contacto"
                    value={editarDatos.nombreContacto}
                    onChange={handleChange}
                  />
                  <TextField
                    id="emailContacto"
                    name="emailContacto"
                    label="Email de contacto"
                    value={editarDatos.emailContacto}
                    onChange={handleChange}
                  />
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-edit">Email login</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-edit"
                      type={'text'}
                      defaultValue={editarDatos.emailLogin ?? ''}
                      name="emailLogin"
                      disabled={isDisabled}
                      onChange={handleChange}
                      error={isDisabled}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleEditClick}
                            // onMouseDown={handleMouseDownPassword}
                            // onMouseUp={handleMouseUpPassword}
                            edge="end"
                          >
                            {<EditIcon />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Email login"
                    />
                    {!isDisabled && (
                      <FormHelperText error>⚠️Si editas este campo, cambiarás el email de acceso</FormHelperText>
                    )}
                  </FormControl>
                  <TextField
                    id="codigoPostal"
                    type="number"
                    name="codigoPostal"
                    label="C.P."
                    value={editarDatos.codigoPostal}
                    onChange={handleChange}
                  />
                  <TextField
                    id="telefonoContacto"
                    type="tel"
                    name="telefonoContacto"
                    label="Teléfono"
                    value={editarDatos.telefonoContacto}
                    onChange={handleChange}
                  />
                  <TextField
                    id="ultimaReclamacion"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    name="ultimaReclamacion"
                    label="Última Reclamación"
                    defaultValue={editarDatos.ultimaReclamacion}
                    onChange={handleChange}
                  />
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="trabajadores">Trabajadores</InputLabel>
                    <Select 
                      label="Trabajadores"
                      native 
                      name="trabajadores" 
                      id="trabajadores" 
                      style={{ textTransform: 'uppercase' }} 
                      onChange={handleChange} defaultValue={editarDatos?.trabajadores}
                    >
                      <option value={false}>Sí</option>
                      <option value={true}>No</option>
                    </Select>
                  </FormControl>
                  <div></div>
                </div>
                
                <div
                  style={{
                    margin: "8px",
                    width: "100ch",
                    position: "relative",
                    display: "grid",
                    gridTemplateColumns: " repeat(2, 1fr)",
                    gridGap: "15px",
                    fontSize: "12px",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      flex: 1,
                      backgroundColor: "whitesmoke",
                      color: "#92004D",
                      ":hover": { backgroundColor: "#92004D", color: "white" },
                      margin: 0,
                      p: 1,
                    }}
                    disableElevation
                    onClick={props.handleClose}
                  >
                    Cancelar
                  </Button>
                  <LaeButton sx={{ flex: 1 }} onClick={() => editar(editarDatos)}>
                    Guardar
                  </LaeButton>
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
