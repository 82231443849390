
import React from 'react'
/* import * as FileSaver from "file-saver"; */
import * as XLSX from "xlsx";
import Excel from "../icons/excel.svg"
export const ExportToExcel = ({ apiData, fileName, onComplete }) => {
  /* const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"; */
  
  const fileExtension = ".xlsx";

  const getColumnWidths = (data) => {
    const columnWidths = [];
    
    data.forEach(row => {
      Object.keys(row).forEach((key, index) => {
        const value = row[key] ? row[key].toString() : '';  // Convertir el valor a string
        const valueLength = value.length;

        // Si el ancho de la columna actual es menor que el valor de esta celda, actualizar el ancho
        if (!columnWidths[index] || columnWidths[index] < valueLength) {
          columnWidths[index] = valueLength;
        }
      });
    });

    // Agregar un margen extra al ancho de cada columna
    return columnWidths.map(width => ({ 
      wpx: Math.min(Math.max(width * 7, 80), 300) 
    }));
  };
  
  const exportar = (columnas, fileName) => {
    const newData = columnas.map(row=>{
      delete row.tableData
      delete row.__position
      delete row.path
      delete row._highlightResult
      delete row.objectID
      return row
    })
    const workSheet = XLSX.utils.json_to_sheet(newData);

    // Obtener los anchos de las columnas basados en el contenido
    const columnWidths = getColumnWidths(newData);
    
    // Asignar los anchos calculados a las columnas
    workSheet['!cols'] = columnWidths;

    //poner titulos en negrita
    if (workSheet['!ref']) {
      const range = XLSX.utils.decode_range(workSheet['!ref']);
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const headerCell = XLSX.utils.encode_cell({ r: range.s.r, c: C });
        if (workSheet[headerCell]) {
          workSheet[headerCell].s = { font: { bold: true } };
        }
      }
    }

    const workBook = XLSX.utils.book_new()

    
    XLSX.utils.book_append_sheet(workBook, workSheet, "Listado");
    XLSX.write(workBook, {bookType:"xlsx", type:"buffer"})
    XLSX.write(workBook, {bookType:"xlsx", type:"binary"})
    XLSX.writeFile(workBook, fileName + fileExtension);

    if (typeof onComplete === "function") {
      onComplete();
    }
  };

  return (
    <button className='bigBtn' onClick={(e) => exportar(apiData, fileName)}><img src={Excel} alt="exportar listado" width="30px"/> Exportar listado</button>
  );
};