import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import { Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import { Select } from "@mui/material";
import { cambiarFinca, getEstadosFincas, UpdateSale } from "../../../services";
import provinces from "@doncicuto/es-provinces";
import municipalities from "@doncicuto/es-municipalities";
import { ordenarProvinciasYMunicipios } from "../../../functions/ordenarListas";
import {
  municipiosIniciales,
  cambiarFormatoNombre,
} from "../../../functions/funciones";
import LaeButton from "../../../components/Buttons/LaeButton";
import {
  verificarCorreo,
  verificarTelefono,
  verificarNif,
  verificarCodigoPostal,
} from "../../../functions/validator/formValidator";
import { CustomSwitch } from "../../../components/Buttons/Switch";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85ch",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
};

export default function TransitionsModal(props) {
  const navigate = useNavigate();
  const [editarDatos, setEditarDatos] = useState({});
  const [listadoMunicipios, setListadoMunicipios] = useState([]);
  const [activo, setActivo] = useState(false);
  const [estados, setEstados] = useState([]);

  useEffect(() => {
    fetchData();
    const sale_cae =
      props.finca?.sales?.find((s) => [1, 3, 6, 7].includes(s.servp_id)) ||
      null;
    const sale_lopd =
      props.finca?.sales?.find((s) => [2, 4, 5].includes(s.servp_id)) || null;
    const fincaData = {
      nif: props.finca.nif === null ? "" : props.finca.nif,
      razonSocial: props.finca.razonSocial === null ? "" : props.finca.razonSocial,
      email: props.finca.emailContacto === null ? "" : props.finca.emailContacto,
      nombre: props.finca.nombreContacto === null ? "" : props.finca.nombreContacto,
      telefono: props.finca.telefonoContacto === null ? "" : props.finca.telefonoContacto,
      municipio: props.finca.municipio === null ? "" : props.finca.municipio,
      direccion: props.finca.direccion === null ? "" : props.finca.direccion,
      codigoPostal: props.finca.codigoPostal === null ? "" : props.finca.codigoPostal,
      provincia: props.finca.provincia === null ? "" : props.finca.provincia,
      nifPresidente: props.finca.nifPresidente === null ? "" : props.finca.nifPresidente,
      nombrePresidente: props.finca.nombrePresidente === null ? "" : props.finca.nombrePresidente,
      activo: props.finca.activo === null ? true : props.finca.activo,
      proveedores: props.finca.proveedores === null ? true : props.finca.proveedores,
      estadoCae: sale_cae
        ? sale_cae.estado_id === null
          ? 1
          : sale_cae.estado_id
        : "-",
      estadoLopd: sale_lopd
        ? sale_lopd.estado_id === null
          ? 1
          : sale_lopd.estado_id
        : "-",
      saleCae_id: sale_cae?.id || null,
      saleLopd_id: sale_lopd?.id || null,
    };
    setEditarDatos(fincaData);
    setListadoMunicipios(
      municipiosIniciales(provinces, municipalities, props.finca.provincia)
    );
  }, [props]);

  const fetchData = async () => {
    const responseEstados = await getEstadosFincas();
    setEstados(responseEstados);
  };
  function handleChange(evt) {
    const { name, type, checked, value } = evt.target;

    if (name === "activo" || name === "proveedores") {
      setEditarDatos({
        ...editarDatos,
        [name]: checked,
      });
    } else {
      setEditarDatos({
        ...editarDatos,
        [name]: value,
      });
    }
  }

  const handleChangeEstado = (evt, id, serv) => {
    const valor = parseInt(evt.target.value);
    const sale_id =
      serv === "cae" ? editarDatos?.saleCae_id : editarDatos?.saleLopd_id;
    UpdateSale(id, {
      estado_id: valor,
    });
    const nuevosDatos =
      serv === "cae" ? { estadoCae: valor } : { estadoLopd: valor };
    setEditarDatos({ ...editarDatos, estado_id: nuevosDatos });
  };

  const editar = (finca) => {
    setActivo(true);
    const toastInfo = toast.info("Guardando datos", { autoClose: false });

    const validarCampos = () => {
      let errores = false;
      const data = {
        emailContacto: finca.email,
        telefonoContacto: finca.telefono,
        codigoPostal: finca.codigoPostal,
        nifPresidente: finca.nifPresidente,
        direccion: finca.direccion,
        razonSocial: finca.razonSocial,
        nif: finca.nif,
        estado: finca.estado,
        activo: finca.activo,
        proveedores: finca.proveedores,
      };

      const requiredFields = ["telefonoContacto", "razonSocial"];

      requiredFields.forEach((field) => {
        if (!data[field]) {
          toast.dismiss(toastInfo);
          toast.error(`El campo ${field} es obligatorio`);
          errores = true;
        }
      });

      Object.keys(data).forEach((key) => {
        switch (key) {
          case "emailContacto":
            let correoCorrecto = verificarCorreo(data.emailContacto);
            if (correoCorrecto === false) {
              toast.dismiss(toastInfo);
              toast.error("El email es incorrecto");
              errores = true;
            }
            break;
          // case "telefonoContacto":
          //   let telefonoCorrecto = verificarTelefono(data.telefonoContacto);
          //   if (telefonoCorrecto === false) {
          //     toast.dismiss(toastInfo);
          //     toast.error("El teléfono es incorrecto");
          //     errores = true;
          //   }
          //   break;
          case "codigoPostal":
            let codigoPostalCorrecto = verificarCodigoPostal(data.codigoPostal);
            if (codigoPostalCorrecto === false) {
              toast.dismiss(toastInfo);
              toast.error("El código postal es incorrecto");
              errores = true;
            }
            break;
          case "nifPresidente":
            let nifPresidenteCorrecto = verificarNif(data.nifPresidente);
            if (nifPresidenteCorrecto === false && data.nifPresidente != "") {
              toast.dismiss(toastInfo);
              toast.error("El NIF del presidente es incorrecto");
              errores = true;
            }
            break;
          default:
            break;
        }
      });

      return errores;
    };

    const enviarSolicitudCambio = () => {
      cambiarFinca(props.finca.nif, {
        nif: finca.nif,
        razonSocial: finca.razonSocial,
        emailContacto: finca.email,
        nombreContacto: finca.nombre,
        telefonoContacto: finca.telefono,
        municipio: finca.municipio,
        direccion: finca.direccion,
        codigoPostal: finca.codigoPostal,
        provincia: finca.provincia,
        nifPresidente: finca.nifPresidente,
        nombrePresidente: finca.nombrePresidente,
        activo: finca.activo,
        proveedores: finca.proveedores,
      })
        .then((res) => {
          if (res.message === "PROPERTIE_UPDATED") {
            toast.update(toastInfo, {
              render: "Datos editados con éxito",
              type: toast.TYPE.SUCCESS,
              autoClose: true,
            });
            // Obtener la URL actual
            const urlActual = window.location.pathname + window.location.search;

            // Expresión regular para reemplazar solo el NIF en la parte correcta de la URL
            const nuevaUrl = urlActual.replace(
              new RegExp(`comunidades/${props.finca.nif}`), // Busca "comunidades/V95592511"
              `comunidades/${finca.nif}` // Reemplaza por "comunidades/V95592500"
            );

            // Cambiar la URL sin recargar la página
            navigate(nuevaUrl, { replace: true });
            props.handleClose();
          } else if (res.status === 403) {
            toast.update(toastInfo, {
              render: res.data.error[0],
              type: toast.TYPE.ERROR,
              autoClose: true,
            });
          } else {
            toast.update(toastInfo, {
              render: "Error al editar los datos",
              type: toast.TYPE.ERROR,
              autoClose: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          toast.update(toastInfo, {
            render: "Error al editar los datos",
            type: toast.TYPE.ERROR,
            autoClose: true,
          });
        })
        .finally(() => {
          setActivo(false);
        });
    };

    const errores = validarCampos();

    if (!errores) {
      enviarSolicitudCambio();
    } else {
      setActivo(false);
    }
  };

  const seleccionarMunicipios = (e) => {
    let codProvincia = "";
    let municipios = [];
    provinces.map((p) => {
      if (p.name === e.target.value) {
        codProvincia = p.code;
      }
    });
    setEditarDatos({
      ...editarDatos,
      [e.target.name]: e.target.value,
    });
    municipalities.map((m) => {
      if (m.provCode === codProvincia) {
        municipios.push({
          ...m,
          name: cambiarFormatoNombre(m.name),
          value: m.name,
        });
      }
    });
    setListadoMunicipios(municipios);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        //className={modal}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={props.opened}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box sx={style}>
          <div
            /* className={paper} */ style={{
              backgroundColor: "#fff",
              border: "2px solid #000",
              boxShadow:
                "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
              padding: "16px 32px 24px",
            }}
          >
            <h2 id="transition-modal-title">Editar datos</h2>
            <div id="transition-modal-description">
              <form noValidate autoComplete="off">
                <div
                  /* className={form} */ style={{
                    margin: "8px",
                    width: "100ch",
                    position: "relative",
                    display: "grid",
                    gridTemplateColumns: " repeat(2, 1fr)",
                    gridGap: "15px",
                    fontSize: "12px",
                  }}
                >
                  <TextField
                    id="nif"
                    name="nif"
                    label="NIF"
                    value={editarDatos.nif}
                    onChange={handleChange}
                  />
                  <TextField
                    id="razonSocial"
                    name="razonSocial"
                    label="Razón Social"
                    value={editarDatos.razonSocial}
                    onChange={handleChange}
                  />
                  <TextField
                    id="email"
                    name="email"
                    label="Email"
                    value={editarDatos.email}
                    onChange={handleChange}
                  />
                  <TextField
                    id="nombre"
                    name="nombre"
                    label="Nombre"
                    value={editarDatos.nombre}
                    onChange={handleChange}
                  />
                  <TextField
                    id="telefono"
                    name="telefono"
                    label="Teléfono"
                    value={editarDatos.telefono}
                    onChange={handleChange}
                  />
                  <TextField
                    id="direccion"
                    name="direccion"
                    label="Dirección"
                    value={editarDatos.direccion}
                    onChange={handleChange}
                  />
                  <TextField
                    id="codigoPostal"
                    name="codigoPostal"
                    label="Codigo Postal"
                    value={editarDatos.codigoPostal}
                    onChange={handleChange}
                  />
                  <Select
                    native
                    name="provincia"
                    id="provincia"
                    style={{ textTransform: "uppercase" }}
                    onChange={seleccionarMunicipios}
                    defaultValue={editarDatos?.provincia}
                  >
                    <option value="">Provincia</option>
                    {provinces
                      .sort(ordenarProvinciasYMunicipios)
                      ?.map((e, i) => {
                        return (
                          <option key={i} value={e.name}>
                            {e.name}
                          </option>
                        );
                      })}
                  </Select>
                  <Select
                    native
                    name="municipio"
                    id="municipio"
                    style={{ textTransform: "uppercase" }}
                    onChange={handleChange}
                    defaultValue={editarDatos?.municipio}
                  >
                    <option value="">Municipio</option>
                    {listadoMunicipios
                      .sort(ordenarProvinciasYMunicipios)
                      ?.map((e, i) => {
                        return (
                          <option key={i} value={e.value}>
                            {e.name}
                          </option>
                        );
                      })}
                  </Select>
                  <TextField
                    id="nifPresidente"
                    name="nifPresidente"
                    label="NIF presidente"
                    value={editarDatos.nifPresidente}
                    onChange={handleChange}
                  />
                  <TextField
                    id="nombrePresidente"
                    name="nombrePresidente"
                    label="Nombre presidente"
                    value={editarDatos.nombrePresidente}
                    onChange={handleChange}
                  />
                  {editarDatos?.saleCae_id === null ? (
                    <TextField label="Estado CAE" value={"-"} />
                  ) : (
                    <Select
                      label="EstadoCae"
                      native
                      name="estadoCae"
                      id="estadoCae"
                      style={{
                        textTransform: "uppercase",
                        color:
                          editarDatos?.estadoCae === 1 || null
                            ? "#3eae3e"
                            : editarDatos?.estadoCae === 2 ||
                              editarDatos?.estadoCae === 3
                            ? "#FF8300"
                            : editarDatos?.estadoCae === 4
                            ? "#EE4534"
                            : "#000",
                      }}
                      onChange={(event) =>
                        handleChangeEstado(
                          event,
                          editarDatos?.saleCae_id,
                          "cae"
                        )
                      }
                      defaultValue={editarDatos?.estadoCae}
                    >
                      {estados?.map((e, i) => {
                        return (
                          <option
                            key={i}
                            value={e.id}
                            style={{
                              color:
                                e.id === 1 || e.id == null
                                  ? "#3eae3e"
                                  : e.id === 2 || e.id === 3
                                  ? "#FF8300"
                                  : e.id === 4
                                  ? "#EE4534"
                                  : "#000",
                            }}
                          >
                            {e.nombre}
                          </option>
                        );
                      })}
                    </Select>
                  )}
                  {editarDatos?.saleLopd_id === null ? (
                    <TextField label="Estado LOPD" value={"-"} />
                  ) : (
                    <Select
                      label="EstadoLopd"
                      native
                      name="estadoLopd"
                      id="estadoLopd"
                      // disabled = {editarDatos?.saleLopd_id === null ? 'disabled' : 'enabled'}
                      style={{
                        textTransform: "uppercase",
                        color:
                          editarDatos?.estadoLopd === 1 || null
                            ? "#3eae3e"
                            : editarDatos?.estadoLopd === 2 ||
                              editarDatos?.estadoLopd === 3
                            ? "#FF8300"
                            : editarDatos?.estadoLopd === 4
                            ? "#EE4534"
                            : "#000",
                      }}
                      onChange={(event) =>
                        handleChangeEstado(
                          event,
                          editarDatos?.saleLopd_id,
                          "lopd"
                        )
                      }
                      defaultValue={editarDatos?.estadoLopd}
                    >
                      {estados?.map((e, i) => {
                        return (
                          <option
                            key={i}
                            value={e.id}
                            style={{
                              color:
                                e.id === 1 || e.id == null
                                  ? "#3eae3e"
                                  : e.id === 2 || e.id === 3
                                  ? "#FF8300"
                                  : e.id === 4
                                  ? "#EE4534"
                                  : "#000",
                            }}
                          >
                            {e.nombre}
                          </option>
                        );
                      })}
                    </Select>
                  )}
                  <div style={{ gridColumn: "span 2" }}>
                    {/* <h7>Activo</h7>
                    <CustomSwitch
                      name="activo"
                      checked={editarDatos.activo}
                      onChange={handleChange}
                    /> */}
                    <h7>Proveedores</h7>
                    <CustomSwitch
                      name="proveedores"
                      checked={editarDatos.proveedores}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div
                  /* className={form} */ style={{
                    margin: "8px",
                    width: "100ch",
                    position: "relative",
                    display: "grid",
                    gridTemplateColumns: " repeat(2, 1fr)",
                    gridGap: "15px",
                    fontSize: "12px",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      flex: 1,
                      backgroundColor: "whitesmoke",
                      color: "#92004D",
                      ":hover": { backgroundColor: "#92004D", color: "white" },
                      margin: 0,
                      p: 1,
                    }}
                    disableElevation
                    onClick={props.handleClose}
                  >
                    Cancelar
                  </Button>
                  <LaeButton
                    sx={{ flex: 1 }}
                    onClick={() => editar(editarDatos)}
                  >
                    Guardar
                  </LaeButton>
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
