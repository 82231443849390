import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, TextField, Box } from "@mui/material";

// 1️⃣ Validaciones con Yup
const schema = yup.object({
  nombre: yup.string().required("El nombre es obligatorio"),
  email: yup.string().email("Email inválido").required("El email es obligatorio"),
  telefono: yup
    .string()
    .matches(/^\d{9}$/, "Debe ser un teléfono válido de 9 dígitos")
    .required("El teléfono es obligatorio"),
}).required();

export default function FormProveedor({ modo = "crear", datosEdicion = null }) {
  // 2️⃣ useForm con validaciones
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, touchedFields },
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      nombre: "",
      email: "",
      telefono: "",
    },
    mode: "onChange",
  });

  // 3️⃣ Simula petición para editar (rellena campos)
  useEffect(() => {
    if (modo === "editar" && datosEdicion) {
      reset(datosEdicion); // Carga datos en campos
    }
  }, [datosEdicion, modo, reset]);

  // 4️⃣ onChange custom ejemplo
  const handleChangeCustom = (e) => {
    console.log("Cambio manual:", e.target.name, e.target.value);
    setValue(e.target.name, e.target.value, { shouldValidate: true, shouldTouch: true });
  };

  // 5️⃣ Envío del formulario
  const onSubmit = (data) => {
    console.log("Datos enviados:", data);
  };

  // 6️⃣ Reset manual
  const limpiarCampos = () => {
    reset();
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ display: "grid", gap: 2, width: 400 }}>
      <TextField
        label="Nombre del Proveedor"
        {...register("razonSocial")}
        onChange={handleChangeCustom}
        onBlur={(e) => console.log("Blur campo:", e.target.name)}
        error={Boolean(errors.nombre && touchedFields.nombre)}
        helperText={errors.nombre?.message}
      />

      <TextField
        label="Email"
        {...register("email")}
        onChange={handleChangeCustom}
        onBlur={(e) => console.log("Blur campo:", e.target.name)}
        error={Boolean(errors.email && touchedFields.email)}
        helperText={errors.email?.message}
      />

      <TextField
        label="Teléfono"
        {...register("telefono")}
        onChange={handleChangeCustom}
        onBlur={(e) => console.log("Blur campo:", e.target.name)}
        error={Boolean(errors.telefono && touchedFields.telefono)}
        helperText={errors.telefono?.message}
      />

      <Button type="submit" variant="contained" color="primary">
        {modo === "crear" ? "Crear" : "Actualizar"}
      </Button>
      <Button onClick={limpiarCampos} variant="outlined" color="secondary">
        Limpiar
      </Button>
    </Box>
  );
}
