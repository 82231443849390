import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { withRouter } from "../../../providers/withRouter";
import { UsuarioContext } from "../../../providers/userContext";
import { DataTable } from "../../../components";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import { getAAFFs, getConsultor } from "../../../services";
import Warning from "../../../icons/warning.svg";
import FiltrosAAFF from "../../../components/filtros/filtrosAAFF";
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecogerURLParamsFiltros, construirURLParamsFiltros } from './../../../functions/parametrosURL/funcionesParamsURL';


const AAFFRows = [
  {
    id: "razonSocial",
    numeric: false,
    disablePadding: false,
    label: "ADMINISTRADOR",
    overrideFunc: (data, row) => (
      <Link to={"/aaff/" + row.nif + "/comunidades"}>
        {data}
      </Link>
    ),
  },
  {
    id: "nif",
    numeric: false,
    disablePadding: false,
    label: "NIF",
  },
  {
    id: "municipio",
    numeric: false,
    disablePadding: false,
    label: "MUNICIPIO",
  },
  {
    id: "provincia",
    numeric: false,
    disablePadding: false,
    label: "PROVINCIA",
  },
  {
    id: "numeroComunidades",
    numeric: false,
    disablePadding: false,
    label: "Nº COMUNIDADES",
    noObjeto: true,
    overrideFunc: (data) => (

      <Typography style={{ textAlign: 'left' }}>
        {data}
      </Typography>
    ),
  },
  {
    id: "proveedores",
    numeric: false,
    disablePadding: false,
    label: "PROVEEDORES",
    noObjeto: true,
    overrideFunc: (data, row) => (
      <Link to={"/consultores/" + row.nifConsul + "/aaff/" + row.nif + "/proveedores"}>
        {data}
      </Link>
    ),
  },
  {
    id: "docsPendientes",
    numeric: false,
    disablePadding: false,
    label: "",
    noObjeto: true,
    overrideFunc: (row) => (
      <>
        {row > 0 ?
          <img src={Warning} width={15} alt="pendientes" />
          : null}
      </>
    ),
  },
];

const AAFFConsultor = (props) => {

  const user = useContext(UsuarioContext);
  const [recargar, setRecargar] = useState(0);
  //   const { loading, data, setPagination, pagination, fetchData } = useAAFFs();
  const [loading, setLoading] = useState(true);
  const { filtrosIniciales, filtroOrder } = useRecogerURLParamsFiltros();
  const [busqueda, setBusqueda] = useState(filtrosIniciales);
  const [consultor, setConsultor] = useState({});
  const [busquedaActiva, setBusquedaActiva] = useState(false);
  const [clearFilterActivo, setClearFilterActivo] = useState(false);

  const [data, setData] = useState({
    meta: {},
    rows: [],
  });
  useEffect(() => {
    // fetchData();
    setRecargar(false);
  }, [recargar]);

  const navigate = useNavigate();
  const location = useLocation();

  const clearFilters = () => {
    console.log(filtrosIniciales)
    setBusqueda({});
    setClearFilterActivo(true);
  }

  useEffect(() => {
    loadConsultor()
  }, [])

  const loadConsultor = async () => {
    const responseConsultor = await getConsultor(props.params.nif);
    setConsultor(responseConsultor);
    setBusqueda({ ...busqueda });
  }

  const fetchData = async ({ offset, limit, filtros = busqueda, order = 'ASC', orderBy = 'id' } = {}) => {
    setLoading(true);
    if (consultor.id) {
      
      order = order || 'ASC';
      orderBy = orderBy || 'id';
      construirURLParamsFiltros(navigate, offset || 1, limit || 50, filtros, order, orderBy);
      const payload = {
        filtros: { ...filtros, consul_id: consultor.id },
        params: { page: offset, limit, order, orderBy },
      };

      try {
        const response = await getAAFFs(payload);

        const { meta, data } = response;
        setData({
          meta,
          rows: formatData(data),
        });
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const formatData = (data) => {
    return data.map((row) => {
      const numComunidades = row.pendientesCount + row.visitadasCount + row.renovadasCount + row.renovadasVisitadasCount
      return {
        razonSocial: row.razonSocial || row.propertie?.aaff?.nombreContacto,
        nif: row.nif || row.propertie?.nif,
        nifConsul: props.params.nif,
        id: row.id,
        municipio: row.municipio || row.propertie?.municipio,
        provincia: row.provincia,
        numeroComunidades: numComunidades,
        proveedores: row.suppliersCount,
        docsPendientes: row.hasPendingDocs,
      }
    });
  };

  const filtrarDatos = (filtros) => {
    setBusqueda(filtros)
    setBusquedaActiva(true);
    // fetchData({offset: 1, limit: limite, filtros: { ...filtros, consul_id: consultor.id } });
  }

  return (
    <>
      <BreadCrumb rutas={[{ path: '/consultores', label: `Gest. Doc.` }, { path: '/consultores', label: `${consultor.nombre + " " + consultor.apellidos}` }]} style={{ margin: '0 10px' }} />
      <div className="pageContent tableContainer">
        <FiltrosAAFF onFilter={filtrarDatos} consultorOculto={true} clearFilter={clearFilters} filtrosIniciales={busqueda} />
        <div className="tableWrapper">
          <Box className="pageContent">
            <Box sx={{ mb: 10 }}>
              <DataTable
                data={data.rows}
                isLoading={loading}
                headers={AAFFRows}
                fetcher={fetchData}
                currentPage={data.current_page}
                meta={data.meta}
                totalItems={data.total}
                totalPages={data.totalPages}
                nextPage={data.next_page}
                filtros={busqueda}
                busquedaActiva={busquedaActiva}
                setBusquedaActiva={setBusquedaActiva}
                clearFilterActivo={clearFilterActivo}
                setClearFilterActivo={setClearFilterActivo}
                mensaje={"No hay administradores de fincas"}
              />
            </Box>
          </Box>
        </div>
      </div>

    </>
  );
};

export default withRouter(AAFFConsultor);
