import React from "react";
import { withRouter } from "../../../providers/withRouter";
import { getAAFF, getAllTecnicos, asignarTecnico, getOnlyAAFF } from "../../../services";
import { toast } from "react-toastify";
import HeaderBackground from "../../../images/banner_administradores.jpg";
import { Grid, CircularProgress } from "@mui/material";
import Add from "../../../icons/add.svg";
import { filterItems, filterItemsCustom } from "../../../functions/funciones";
import BreadCrumb from "../../../components/navbar/Breadcrumb";

class AsignarTecnico extends React.Component {
  constructor() {
    super();
    this.state = {
      nombre: "",
      aaff: undefined,
      resultados: undefined,
      tecnicos: [],
      loading: true // Bandera para saber si se están cargando los técnicos o el AAFF
    };
  }

  componentDidMount() {
    // Cargar datos del AAFF
    getOnlyAAFF(this.props.params.nif).then((res) => {
      this.setState({ aaff: res });
    });
    // Cargar técnicos
    getAllTecnicos().then((res) => {
      const tecnicos = res.map((c) => ({
        id: c.id,
        nif: c.nif,
        nombre: c.nombre,
        apellidos: c.apellidos,
        busqueda: c.nombre + " " + c.apellidos,
      }));
      this.setState({ tecnicos, loading: false });
    });
  }

  buscar = (e) => {
    e.preventDefault();
    // Realiza la búsqueda solo si ya se han cargado los técnicos
    if (this.state.tecnicos.length > 0) {
      this.setState({
        resultados: filterItemsCustom(this.state.nombre, this.state.tecnicos),
      });
    } else {
      toast.error("Los datos de técnicos aún se están cargando, inténtalo más tarde.");
    }
  };

  render() {
    // Consideramos loading mientras el AAFF no se haya cargado o loading sea true
    const isLoading = this.state.aaff === undefined || this.state.loading;
    
    return (
      <>
        <BreadCrumb
          rutas={[
            { path: "/aaff", label: "Administradores de fincas" },
            { path: `/aaff/${this.state.aaff ? this.state.aaff.nif : ""}`, label: this.state.aaff ? this.state.aaff.razonSocial : "" }
          ]}
          style={{ margin: "0 10px" }}
        />
        {/* Contenedor con posición relativa para el overlay */}
        <div className="pageContent" style={{ position: "relative" }}>
          <div className="listPage">
            <div className="gridCard">
              <div className="gridHead">ASIGNAR TÉCNICO</div>
            </div>
          </div>
          <form className="nuevoAAFFForm" onSubmit={this.buscar}>
            <Grid container spacing={5} style={{ marginTop: 20, marginBottom: 20 }}>
              <Grid item xs={12} sm={4}>
                <input
                  type="text"
                  placeholder="Nombre"
                  required
                  value={this.state.nombre}
                  onChange={(e) => this.setState({ nombre: e.target.value })}
                  disabled={isLoading} // Deshabilita el input mientras carga
                />
              </Grid>
            </Grid>
            <input type="submit" value="Buscar" disabled={isLoading} />
          </form>
          <Resultados resultados={this.state.resultados} aaff={this.state.aaff} />
          {/* Overlay del spinner */}
          {isLoading && (
            <div style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(255,255,255,0.7)",
              zIndex: 10
            }}>
              <CircularProgress />
            </div>
          )}
        </div>
      </>
    );
  }
}

class Resultados extends React.Component {
  enviarEmail = (e) => {
    console.log("enviar email");
  };

  asignar = (resultado) => {
    asignarTecnico(this.props.aaff.nif, resultado.id)
      .then((res) => {
        toast.success("Técnico añadido con éxito");
        window.location.replace("/aaff");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error al añadir el técnico");
      });
  };

  render() {
    if (this.props.resultados === undefined) return null;
    return (
      <div className="tableContainer" style={{ marginTop: 30 }}>
        <table>
          <thead>
            <tr>
              <th>Técnico</th>
              <th>NIF</th>
              <th style={{ textAlign: "center" }}>Asignar</th>
            </tr>
          </thead>
          <tbody>
            {this.props.resultados.length === 0 ? (
              <tr>
                <td colSpan={3} style={{ textAlign: "center" }}>
                  No coincide ningún resultado
                </td>
              </tr>
            ) : (
              this.props.resultados.map((e, i) => (
                <tr key={i}>
                  <td>{e.nombre + " " + e.apellidos}</td>
                  <td>{e.nif}</td>
                  <td style={{ textAlign: "center" }}>
                    <div
                      className="asignarButton"
                      style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        cursor: "pointer",
                      }}
                      onClick={() => this.asignar(e)}
                    >
                      <img src={Add} alt="Más" />
                      Asignar
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default withRouter(AsignarTecnico);