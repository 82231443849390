import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import { Box, InputLabel, FormControl} from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { toast } from "react-toastify";
import { Select } from "@mui/material";
import { editarAAFF } from '../../../services';
import {  municipiosIniciales, cambiarFormatoNombre } from './../../../functions/funciones'
import provinces from '@doncicuto/es-provinces';
import municipalities from '@doncicuto/es-municipalities';
import { ordenarProvinciasYMunicipios } from '../../../functions/ordenarListas';

import { verificarCorreo, verificarTelefono } from "../../../functions/validator/formValidator";

/* const useStyles = styled ((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  form: {
    margin: theme.spacing(1),
    width: '100ch',
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: ' repeat(2, 1fr)',
    gridGap: '15px',
    fontSize: '12px',
  },
})); */
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '85ch',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
  };

 const modal = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
  const paper= {
    backgroundColor: '#fff',
    border: '2px solid #000',
    boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
    padding: '16px 32px 24px'
  };
  const form= {
      
    margin: '8px',
    width: '100ch',
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: ' repeat(2, 1fr)',
    gridGap: '15px',
    fontSize: '12px',
  };
export default function TransitionsModal(props) {

  const [editarDatos, setEditarDatos] = useState({});
  const [listadoMunicipios, setListadoMunicipios] = useState([]);
  
  useEffect(()=>{
    const aaffData = {
      razonSocial: props.aaff.razonSocial,
      email: props.aaff?.user?.email,
      nif: props.aaff.nif,
      nombreContacto: props.aaff.nombreContacto,
      telefono: props.aaff.telefono,
      municipio: props.aaff.municipio,
      provincia: props.aaff.provincia,
    }
    setEditarDatos(aaffData)
    setListadoMunicipios(municipiosIniciales(provinces, municipalities, props.aaff.provincia))
  },[props])

  function handleChange(evt) {
    const value = evt.target.value;
    setEditarDatos({
      ...editarDatos,
      [evt.target.name]: value
    });
  }

  const editar = (aaff) => {
    let cont = "";
    if(aaff.nombreContacto === undefined || aaff.nombreContacto === null || aaff.nombreContacto === ''){cont= "-"}else{ cont= aaff.nombreContacto}
    let tel = "";
    if(aaff.telefono === undefined || aaff.telefono === null || aaff.telefono === ''){tel= "-"}else{ tel= aaff.telefono}

    let data = {
        razonSocial: aaff.razonSocial,
        nif: aaff.nif,
        email: aaff.email,
        nombreContacto: cont,
        telefono: tel,
        municipio: aaff.municipio,
        provincia: aaff.provincia,
        // comentarios: c
    };

    editarAAFF(data).then(res => {
        let keys = Object.keys(data);
        let errores = false;

        keys.forEach(key => {
            switch (key) {
                case "email":
                  if (data.email !== null && data.email !=="" ) {
                    let correoCorrecto = verificarCorreo(data.email);
                    if (correoCorrecto === false) {
                        toast.error("El email es incorrecto");
                        errores = true;
                    }
                  }
                    break;
                // case "telefono":
                //   if (data.telefono !== null && data.telefono !=="") {
                //     let telefonoCorrecto = verificarTelefono(data.telefono);
                //     if (telefonoCorrecto === false) {
                //        toast.error("El teléfono  es incorrecto");
                //         errores = true;
                //     }
                //   }
                //     break;
                default:
                    break;
            }
        });

        if (!errores && res.status>=200 && res.status<300) {
            toast.success("Datos editados con éxito");
            props.handleClose();
        }
    }).catch(err => {
        console.log(err);
        toast.error("Error al editar los datos");
    });
};



  const seleccionarMunicipios = (e) => {
    let codProvincia = ""
    let municipios = []
    provinces.map( p => {
      if(p.name === e.target.value){
        codProvincia = p.code
      }
    })
    setEditarDatos({
      ...editarDatos,
      [e.target.name]: e.target.value
    });   
    municipalities.map( m => {
      if(m.provCode === codProvincia){
        municipios.push({...m, name: cambiarFormatoNombre(m.name), value: m.name})
      }
    })
    setListadoMunicipios(municipios)
  }

  return (
    <div>
       

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={modal}
        open={props.opened}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box sx={style}>
          <div style={{backgroundColor: '#fff',
            border: '2px solid #000',
            boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
            padding: '16px 32px 24px'}}>
            <h2 id="transition-modal-title">Editar datos</h2>
            <div id="transition-modal-description">
              <form noValidate autoComplete="off">
                <div style={{margin: '8px',
                  width: '100ch',
                  position: 'relative',
                  display: 'grid',
                  gridTemplateColumns: ' repeat(2, 1fr)',
                  gridGap: '15px',
                  fontSize: '12px',}}>
                  <TextField variant="standard" id="razonSocial" name="razonSocial" label="Razón Social" value={editarDatos.razonSocial} onChange={handleChange} />
                  <TextField variant="standard" id="email" name="email" label="Email" value={editarDatos.email} type="email" onChange={handleChange} />
                  <TextField variant="standard" id="nombreContacto" name="nombreContacto" label="Contacto" minLength="3" value={editarDatos.nombreContacto} onChange={handleChange} />
                  <TextField variant="standard" id="telefono" name="telefono" label="Telefono" type="tel" value={editarDatos.telefono} onChange={handleChange} />
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="provincia">Provincia</InputLabel>
                    <Select 
                      label="provincia"
                      variant="standard" 
                      native 
                      name="provincia" 
                      id="provincia" 
                      style={{ textTransform: 'uppercase'}} 
                      onChange={seleccionarMunicipios} 
                      defaultValue={editarDatos?.provincia}
                    >
                      <option value="">Provincia</option>
                      {provinces.sort(ordenarProvinciasYMunicipios)?.map((e, i) => {
                        return (
                          <option key={i} value={e.name}>
                            {e.name}
                          </option>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="municipio">Municipio</InputLabel>
                    <Select 
                      label="municipio"
                      variant="standard" 
                      native name="municipio" 
                      id="municipio" 
                      style={{ textTransform: 'uppercase'}} 
                      onChange={handleChange} 
                      defaultValue={editarDatos?.municipio}
                    >
                      <option value="">Municipio</option>
                      {listadoMunicipios.sort(ordenarProvinciasYMunicipios)?.map((e, i) => {
                        return (
                          <option key={i} value={e.value}>
                            {e.name}
                          </option>
                        );
                      })}
                    </Select>
                  </FormControl>
                 </div>
                <div style={{margin: '8px', width: '100ch', position: 'relative', display: 'grid', gridTemplateColumns: ' repeat(2, 1fr)', gridGap: '15px', fontSize: '12px',}}>
                  <Button className="btn-Default" variant="contained" onClick={props.handleClose}>Cancelar</Button>
                  <Button className="btn-Secondary" variant="contained" color="secondary" onClick={() => editar(editarDatos)}>Editar</Button>
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}