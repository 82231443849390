import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from "../../providers/withRouter";
import { BsSearch } from "react-icons/bs";
import { getAAFFs } from '../../services';
import { UsuarioContext } from '../../providers/userContext';
import { ImCross } from "react-icons/im";
import {
  Box,
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel
} from "@mui/material";
import { useLocation} from 'react-router-dom';
import { useRecogerURLParamsFiltros } from './../../functions/parametrosURL/funcionesParamsURL';


const FiltrosGestion = ({ onFilter, clearFilter,filtrosIniciales }) => {


  const [busqueda, setBusqueda] = useState(filtrosIniciales);
  const [typeInicio, setTypeInicio] = useState('text');
  const [typeFin, setTypeFin] = useState('text');
  const [aaff, setAAFF] = useState([]);
  const [aaffFiltrado, setAAFFfiltrado] = useState([]);
  const [estado, setEstado] = useState(0);
  const user = useContext(UsuarioContext);

  const location = useLocation();

  useEffect(() => {
    getAAFFs({ filtros: { tech_id: user.id }, params: {} }).then(res => {
      setAAFF(res.data);
      setAAFFfiltrado(res.data);
    });
  }, [user.id]);

  useEffect(() => {
    if (localStorage.getItem("hasReloaded") === "true") {

      clearAllFilters();
      localStorage.setItem("hasReloaded", "false"); 
    }
  }, [location]);

  const clearAllFilters = () => {
    setBusqueda({});
    setEstado('')
    clearFilter();
   }

   const handleFocusInicio = () => setTypeInicio('date');
   const handleBlurInicio = () => {
     if (!busqueda.fechaAltaInicio) setTypeInicio('text');
   };
   const handleFocusFin = () => setTypeFin('date');
   const handleBlurFin = () => {
     if (!busqueda.fechaAltaFin) setTypeFin('text');
   };

  function handleChange(evt) {
    const { name, value } = evt.target;
    // console.log(value)
    if (name === 'estado') {
      setEstado(value);
      switch (value) {
        case 1:
          setBusqueda({
            ...busqueda,
            // estado: value,
            pendiente: true,
            visitada: null,
            renovada: null,
            renovadaVisitada: null
          });
          break;
        case 2:
          setBusqueda({
            ...busqueda,
            // estado: value,
            pendiente: null,
            visitada: true,
            renovada: null,
            renovadaVisitada: null
          });
          break;
        case 3:
          setBusqueda({
            ...busqueda,
            // estado: value,
            pendiente: null,
            visitada: null,
            renovada: true,
            renovadaVisitada: null
          });
          break;
        case 4:
          setBusqueda({
            ...busqueda,
            // estado: value,
            pendiente: null,
            visitada: null,
            renovada: null,
            renovadaVisitada: true
          });
          break;
        default:
          setBusqueda({
            ...busqueda,
            // estado: value,
            pendiente: null,
            visitada: null,
            renovada: null,
            renovadaVisitada: null
          });
          break;
      }
    } else {
      setBusqueda({
        ...busqueda,
        [name]: value,
      });
    }
  }

  const getBusqueda = () => {
    const filteredBusqueda = {};
    for (let key in busqueda) {
      if (
        busqueda[key] !== "" &&
        busqueda[key] !== null &&
        busqueda[key] !== undefined
      ) {
        filteredBusqueda[key] = busqueda[key];
      }
    }
    onFilter(filteredBusqueda);
  };

  return (
    <>
      <div className="buscadorFiltros">
        <div style={{ width: "100%" }}>
          <Box
            mx={1}
            display={"block"}
            flexDirection={"row"}
            gap={2}
          >
            <TextField
              id="razonSocialAaff-input"
              label="Razón Social - AAFF"
              variant="outlined"
              name='razonSocialAaff'
              className="filtros altura"
              value={busqueda.razonSocialAaff || ""}
              onChange={handleChange}
              onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
              inputProps={{
                "aria-label": "controlled",
              }}
            />
            <TextField
              id="razonSocial-input"
              name='razonSocial'
              label="Comunidad"
              variant="outlined"
              inputProps={{
                "aria-label": "controlled",
              }}
              className="filtros altura"
              value={busqueda.razonSocial || ""}
              onChange={handleChange}
              onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
            />
            <TextField
              id="codigoPostal-input"
              label="Código postal"
              variant="outlined"
              name='codigoPostal'
              className="filtros altura"
              value={busqueda.codigoPostal || ""}
              onChange={handleChange}
              onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
              inputProps={{
                "aria-label": "controlled",
              }}
            />
            <TextField
              id="nif-input"
              label="Nif"
              variant="outlined"
              name='nif'
              className="filtros altura"
              value={busqueda.nif || ""}
              onChange={handleChange}
              onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
              inputProps={{
                "aria-label": "controlled",
              }}
            />
            <FormControl variant="outlined" className='filtros-select'>
              <InputLabel id="estado-label">Estado</InputLabel>
              <Select
                labelId="estado-label"
                id="estado-select"
                label="Estado"
                name="estado"
                className="filtros"
                value={estado || ""}
                onChange={handleChange}
                onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
              >
                <MenuItem value="" style={{ height: 40 }}></MenuItem>
                <MenuItem value={1} style={{ height: 40 }}>Pendiente</MenuItem>
                <MenuItem value={2} style={{ height: 40 }}>Visitada</MenuItem>
                <MenuItem value={3} style={{ height: 40 }}>Renovada pendiente</MenuItem>
                <MenuItem value={4} style={{ height: 40 }}>Renovada visitada</MenuItem>

              </Select>
            </FormControl>
            <TextField
              type={typeInicio}
              id="fechaVisitaInicio-input"
              label="Fecha ult. visita (Desde)"
              variant="outlined"
              name='fechaVisitaInicio'
              className="filtros altura"
              value={busqueda.fechaVisitaInicio || ""}
              onChange={handleChange}
              onFocus={handleFocusInicio}
              onBlur={handleBlurInicio}
              onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
              inputProps={{
                "aria-label": "controlled",
              }}
            />
            <TextField
              type={typeFin}
              id="fechaAltaFin-input"
              label="Fecha ult. visita (Hasta)"
              variant="outlined"
              name='fechaVisitaFin'
              className="filtros altura"
              value={busqueda.fechaVisitaFin || ""}
              onChange={handleChange}
              onFocus={handleFocusFin}
              onBlur={handleBlurFin}
              onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
              inputProps={{
                "aria-label": "controlled",
              }}
            />
          </Box>
        </div>
        <div>
          <Button className="btn-buscador" onClick={clearAllFilters}>
            <ImCross />
          </Button>
        </div>
        <div>
          <Button className="btn-buscador" onClick={getBusqueda}>
            <BsSearch />
          </Button>
        </div>
      </div>

      <style jsx="true">
        {`
          .btn-buscador {
            padding: 5px 10px;
            color: #ffffff;
            background-color: #92004d;
            border-radius: 4px;
            font-weight: bold;
            width: -moz-fit-content;
            width: fit-content;
            align-self: flex-end;
            border: none;
            min-width: 40px;
            height: 40px;
            margin: 5px 10px 5px;
          }
          .btn-buscador:hover {
            color: black;
            background-color: #dee2e6;
          }
        `}
      </style>
    </>
  );
}

export default withRouter(FiltrosGestion);
