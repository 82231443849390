import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { ExportToExcel } from "../../../providers/export-excel";
import { withRouter } from "../../../providers/withRouter";
import Excel from "../../../icons/excel.svg"
import { getSalesToExcel } from "../../../services";
import { UsuarioContext } from "../../../providers/userContext";

const ListadoFincas = ({ filtros }) => {
    //const listado = props?.fincas
    const user = useContext(UsuarioContext);
    const [listado, setListado] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const params = useParams();
    const url = params['*'].split('/')[0];
    const [disabled, setDisbled] = useState();
    const fetchData = async () => {

        setShow(true);
        setIsLoading(true);
        const response = await getSalesToExcel(url == 'aaff' ? { nif: params.nifFinca } : filtros);
        let f = [];
        response.forEach(d => {
            let finca;
            const {workcenter_id, workcenters} = d;
            if (user.rol === 'TECH') {
                let estado = "";
                if (d.detailsCae[0]?.pendiente || d.detailsCae[0]?.visitSheet_id == null ) {
                    estado = "Pendiente";
                } else if (d.detailsCae[0]?.visitada) {
                    estado = "Visitada";
                } else if (d.detailsCae[0]?.unrealized) {
                    estado = "No Realizada";
                }
                finca = {
                    aaff: d.aaff?.razonSocial,
                    nombreComunidad: d.properties?.razonSocial,
                    direccion: d.properties?.direccion,
                    codigoPostal: d.properties?.codigoPostal,
                    localidad: d.properties?.municipio,
                    provincia: d.properties?.provincia,
                    fechaAlta: new Date(d.properties.createdAt),
                    empleados: d.properties?.trabajadores ? "SI" : "NO",
                    tipoServicio: d.serviceprops?.nombre,
                    estado: estado,
                };
            } else {
                finca = {
                    aaff: d.aaff?.razonSocial,
                    nif: d.properties?.nif,
                    nombreComunidad: d.properties?.razonSocial,
                    direccion: workcenter_id ? workcenters.razonSocial : d.properties?.direccion,
                    codigoPostal: d.properties?.codigoPostal,
                    localidad: d.properties?.municipio,
                    provincia: d.properties?.provincia,
                    fechaAlta: new Date(d.properties.createdAt),
                    empleados: d.properties?.trabajadores ? "SI" : "NO",
                    tipoServicio: d.serviceprops?.nombre,
                    fechaRenovacion: d.properties?.fechaRenovacion === null ? "" : new Date(d.properties?.fechaRenovacion),
                    fechaNoVisita: d.detailsCae[0]?.unrealizedDate === null ? "" : new Date(d.detailsCae[0]?.unrealizedDate),
                    observacionesNoVisita: d.detailsCae[0]?.observaciones,
                    aaffNif: d.aaff?.nif,
                    tecnico: d.technicians?.nombre && d.technicians?.apellidos ? `${d.technicians?.nombre} ${d.technicians?.apellidos}`: " "
                };
            }
            f.push(finca);
            setIsLoading(false);
            setListado(f);
        });

    };
    const handleExportComplete = () => {
        setShow(false);
    };

    useEffect(() => {
        const filtrosVacios = Object.keys(filtros).length === 0;
        const ambosFalsos = !filtros.pendientes && !filtros.visitasRecientes;
        setDisbled(filtrosVacios && ambosFalsos);
        if (url === 'aaff' || user.rol === 'AAFF') {
            setDisbled(false);
        }
    }, [filtros, params.nifFinca]);
    return (
        <>
            {!show ?
                <button
                    className={`bigBtn ${disabled ? 'disabled' : ''}`}
                    onClick={() => fetchData()}
                >
                    <img src={Excel} alt="Generar listado" width="30px" />
                    Generar listado
                </button>
                :
                !isLoading ?
                    <ExportToExcel apiData={listado} fileName={"Gestión fincas"} onComplete={handleExportComplete} />
                    :
                    <span className='bigBtn' style={{ cursor: 'context-menu' }}><CircularProgress style={{ color: '#fff', width: '16px', height: '16px', marginRight: '10px' }} /> Generando listado</span>
            }
        </>
    );
}

export default withRouter(ListadoFincas);