import React, { useState, useEffect, useContext } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "./App.scss";
import "./routes/pages/Page.css";
import "./../src/providers/pagination.css";
import "./routes/pages/proveedor/provStyles.css";
import ProtectedRoute from "./providers/ProtectedRoute";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Navigate, useLocation } from "react-router-dom";
import { UsuarioContext } from "./providers/userContext";
import { logout } from "./services";
import useInactivity from "./utilities/useInactivity";

function App(props) {
  const user = useContext(UsuarioContext);
  const [mobile, setMobile] = useState(false);
  /* const [menuOpen, setMenuOpen] = useState(false); */
  const [sidebar, setSidebar] = useState(true);
  
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);
 
  const location = useLocation();

  function handleResize() {
    if (window.innerWidth <= 992) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }
  const showSidebar = () => {
    setSidebar(!sidebar);
  };
  
  useInactivity(logout, 3600 * 1000);  // 30 minutos 1800 segundos*milisegundos

  return user === null ? <Navigate replace to="/login" state={{from: location}} /> : (
    <div className="App">
      <LocalizationProvider dateAdapter={AdapterMoment}>
        {mobile ? (
          <div
            className="contenedor-global"
            style={{
              marginLeft: 0,
              /* display: 'grid',
          gridTemplateColumns: '170px auto',
          gridTemplateRows: '50px auto',
          height: '100vh',
          backgroundColor: '#eef3f8' */
            }}
          >
            <ProtectedRoute
              user={user}
              showSidebar={showSidebar}
              type={"menuMov"}
            />
          </div>
        ) : (
          <div
            className="contenedor-global"
            style={{ marginLeft: sidebar ? "300px" : "100px" }}
          >
            <ProtectedRoute
              user={user}
              showSidebar={showSidebar}
              type={"menu"}
            />
          </div>
        )}
      </LocalizationProvider>
    </div>
  );
}

export default App;
