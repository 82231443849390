export const filtersRolDefault = (rol, filtrosIniciales) => {
  let defaultFilterValues = { ...filtrosIniciales };

  switch (rol) {
    case "ADMIN":
      defaultFilterValues = {
        ...defaultFilterValues,
        visitasRecientes: true,
        pendientes: true,
      };
      break;

    case "TECH":
      defaultFilterValues = {
        ...defaultFilterValues,
        visitasRecientes: true,
        pendientes: true,
      };
      break;

    // case "AAFF":
    //   defaultFilterValues = {
    //     ...defaultFilterValues,
    //     ano: new Date().getFullYear(),
    //   };
    //   break;

    default:
      break;
  }
  return defaultFilterValues;
};
