import axios from "axios";

const formHeaders = {
  'Content-Type': 'application/json',
  // "Content-Type": "multipart/form-data",
  "Access-Control-Allow-Origin": "*",
};

const urlPath = "visitSheet";

export const getVisitSheet = async (id) => {
  try {
    const response = await axios.get(`${urlPath}/${id}`);

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getStatement = async () => {
  try {
    const response = await axios.get(`${urlPath}/statements`);

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const createVisitSheet = async (data, images, img, nifProp   ) => {
  
  //Necesaria conversión para que el back lo reconozca con array
  const respuestas = Object.values(data.questions).map(preguntaPadre => {
    return {
        statements_id: preguntaPadre.statements_id,
        respuesta: preguntaPadre.respuesta,
        subquestions: preguntaPadre.subquestions
    };
});

  const datos = {
    saleDate:data.fechaVisita,
    sale_id: data.sale_id,
    aaff_id:data.aaff_id,
    work_id:data.work_id,
    tech_id:data.IdTecnicoPRL,
    user_id:data.user_id,
    prop_id:data.IdComunidad,
    nPlantas:data.numeroPlantas,
    nVersion:1,
    
    questions: data.questions,
    comentario:data.comentarios,
    year:new Date().getFullYear()
  }
  
  const json = JSON.stringify(datos);
  const formData = new FormData();
  formData.append("sale_id", datos.sale_id);
  formData.append("aaff_id", datos.aaff_id);
  formData.append("prop_id", datos.prop_id);
  formData.append("work_id", datos.work_id);
  formData.append("tech_id", datos.tech_id);
  formData.append("nPlantas", datos.nPlantas);
  formData.append("comentario", datos.comentario);
  formData.append("nVersion", datos.nVersion);
  formData.append("saleDate", datos.saleDate);
  formData.append("year", datos.year);
  formData.append("questions", JSON.stringify(respuestas));
  
  formData.append("img", img);
  images.forEach((file, index) => {
    formData.append(`images`, file);
  });
  
  try {
    
    const response = await axios.post(`${urlPath}/${nifProp}`, formData, {
      ...formHeaders,
    });
    return response;
  } catch (err) {
    console.log(err.response);
    return err.response;
  }
};


export const editarVisitSheet = async (data, imagesToDelete, images, img, nifProp   ) => {
  const id = data.visitSheet_id;
  const respuestas = Object.values(data.questions).map(preguntaPadre => {
    return {
        id: preguntaPadre.id,
        statements_id: preguntaPadre.statements_id,
        respuesta: preguntaPadre.respuesta,
        subquestions: preguntaPadre.subquestions
    };
  
});
  const datos = {
    saleDate:data.fechaVisita,
    visitSheet_id: data.visitSheet_id,
    sale_id: data.sale_id,
    aaff_id:data.aaff_id,
    // work_id:1,
    user_id:data.user_id,
    // tech_id:data.IdTecnicoPRL,
    // prop_id:data.IdComunidad,
    nPlantas:data.numeroPlantas,
    nVersion:1,
    questions:respuestas,
    comentario:data.comentario,
    year:new Date().getFullYear()
  }
  
  const json = JSON.stringify(datos);

  const formData = new FormData();
  formData.append("data", json);
  
  
  formData.append("img", img);
  images.forEach((file, index) => {
    formData.append(`images-[${index}]`, file);
  });

  
  imagesToDelete.forEach((image, index) => {
    if (image && typeof image === 'object' && 'id' in image && 'path' in image) {
      formData.append(`images_to_delete[${index}][id]`, image.id);
      formData.append(`images_to_delete[${index}][path]`, image.path);
    }
  });

  try {
    
    const response = await axios.put(`${urlPath}/${nifProp}/${id}`, formData, {
      ...formHeaders,
    });
    return response;
  } catch (err) {
    console.log(err.response);
    return err.response;
  }
};

export const visitTec = async (data) => {
  try {
    const response = await axios.post(`${urlPath}/visitTec`, data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}