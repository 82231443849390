import { Box, Typography, Tooltip } from "@mui/material";
import React, { useState, useContext } from "react";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { DataTable, NoVisitado } from "../../../components";
import Add from "../../../icons/add.svg";
import { generarPDF, getSales, getFinca } from "../../../services";
import { toast } from "react-toastify";
import FiltrosVentas from "../../../components/filtros/filtrosVentas";
import { formatoFechasEs } from "../../../functions/funciones";
import { UsuarioContext } from "../../../providers/userContext";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import { FaPencilAlt, FaPlus } from "react-icons/fa";
import { TbCertificate } from "react-icons/tb";
import { IoVideocam } from "react-icons/io5";
import { TiDocumentText } from "react-icons/ti";
import { MdHealthAndSafety } from "react-icons/md";
import { IoIosWarning } from "react-icons/io";
import { HiClipboardDocumentList } from "react-icons/hi2";
import Enlace from "../../../components/Enlace";
import { useRecogerURLParamsFiltros, construirURLParamsFiltros } from './../../../functions/parametrosURL/funcionesParamsURL';
import NoVisitadoAAFF from "../../../components/noVisitadoAAFF";
import ExportarListaServicios from "../admin/ExportarListaServicios";
import { filtersRolDefault } from "../../../utilities/filtersRolDefault";

export default function ComunidadDocs(props) {

  const { nifFinca, grupoServicio, prop_id  } = props
  const user = useContext(UsuarioContext);
  
  const [listaVentas, setListaVentas] = useState([]);
  const [pdfUrl, setPdfUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const { filtrosIniciales, filtroOrder } = useRecogerURLParamsFiltros();
  const filterCheckBox = filtersRolDefault(user.rol,filtrosIniciales)  
  // const [busqueda, setBusqueda] = useState(prop_id ? { ...filterCheckBox, prop_id } : filterCheckBox);
  const [busqueda, setBusqueda] = useState(() => {
    let filtros = prop_id ? { ...filterCheckBox, prop_id } : filterCheckBox;
  
    if (user.rol == "AAFF") {
      filtros = { ...filtros, aaff_id: user.id };
    }
  
    return filtros;
  });
  const [busquedaActiva, setBusquedaActiva] = useState(false);
  const [recargar, setRecargar] = useState(false);
  const [clearFilterActivo, setClearFilterActivo] = useState(false);
  const [tieneCentro, setTieneCentro] = useState(false);
  const [data, setData] = useState({
    meta: {},
    rows: [],
  });
  const navigate = useNavigate();
  const location = useLocation();
  const anoActual = new Date().getFullYear();
  const clearFilters = () => {
    setBusqueda(user.rol == "TECH" || user.rol == "ADMIN" ? { visitasRecientes: true, pendientes: true } : {});
    setClearFilterActivo(true);
  }

  console.log(user)
  const comunidadesRows = [
    {
      id: "color",
      numeric: false,
      disablePadding: true,
      label: "",
      overrideFunc: (row) => (
        <Typography
          className="servicios"
          style={{ width: '3px', height: '6vh', padding: 0, background: row }}
        ></Typography>
      ),
    },
    props.nifFinca && user.rol !== 'AAFF' && tieneCentro ?
      {
        id: "comunidad",
        numeric: false,
        disablePadding: false,
        label: "COMUNIDAD",
        noObjeto: true,
        overrideFunc: (data, row) => (
          row.isWorkcenter ? (
            <Typography style={{ textAlign: 'left' }}>
              {data}
            </Typography>
          ) : null
        ),
      }
    : null,
    location.pathname === "/servicios" ?
      user.rol === "AAFF"
        ? tieneCentro || location.pathname === "/servicios"
          ? {
            id: "comunidad",
            numeric: false,
            disablePadding: false,
            label: "COMUNIDAD",
            noObjeto: true,
            overrideFunc: (data, row) => (
              location.pathname === "/servicios" ? (
                <Link to={`/comunidades/${row.finca.nif}`}>
                  {data}
                </Link>
              ) : row.isWorkcenter ? (
                <Typography style={{ textAlign: 'left' }}>
                  {data}
                </Typography>
              ) : null
            ),
          }
          : null
        : {
          id: "razonSocial",
          numeric: false,
          disablePadding: false,
          label: "ADMINISTRADOR",
          noObjeto: true,
        }
      : '',
    {
      id: "ano",
      numeric: false,
      disablePadding: false,
      label: "AÑO",
    },
    {
      id: "servicio",
      numeric: false,
      disablePadding: false,
      label: "SERVICIO",
      noObjeto: true,
    },
    location.pathname === "/servicios" ?
      {
        id: "nif",
        numeric: false,
        disablePadding: false,
        label: "NIF",
        noObjeto: true,
        overrideFunc: (data, row) => (
          <Typography
            style={{ width: '100px', textAlign: 'center' }}>
            {data}
          </Typography>
        ),
      } : '',
    location.pathname === "/servicios" ?
      user.rol == "AAFF"
        ? null : {
          id: "comunidad",
          numeric: false,
          disablePadding: false,
          label: "COMUNIDAD",
          noObjeto: true,
          overrideFunc: (data, row) => (
            location.pathname === "/servicios" ?
              <Link to={user.rol === "AAFF" ? "/comunidades/" + row.finca.nif : "/aaff/" + row.nif_aaff + "/comunidades/" + row.finca.nif} >{data}</Link>
              : row.isWorkcenter ?
                <Typography style={{ textAlign: 'left' }}>
                  {data}
                </Typography>
                : null
          )
        }
      : '',
    ...(location.pathname === "/servicios"
      ? [
        {
          id: "direccion",
          numeric: false,
          disablePadding: false,
          label: "DIRECCIÓN",
        },
        {
          id: "codigoPostal",
          numeric: false,
          disablePadding: false,
          label: "CÓDIGO POSTAL",
        }
      ]
      : []),
    user.rol !== 'AAFF' ?
      {
        id: "saleDate",
        numeric: false,
        disablePadding: false,
        label: "FECHA VENTA",
        overrideFunc: (data) => formatoFechasEs(data),
      } : '',
    {
      id: "documentos",
      numeric: false,
      disablePadding: false,
      label: "DOCUMENTOS",
      colSpan: 4,
      noObjeto: true,
      overrideFunc: (row) => (
        <Typography style={{ display: 'flex', gap: 5, color: '#fff' }}>
          {(row.serviceprops.id === 1 || row.serviceprops.id === 3 || row.serviceprops.id === 6 || row.serviceprops.id === 7) && (
            <>
              {renderDocs("Hoja visita", <HiClipboardDocumentList />, "hoja-visita", convertirAPdf, row, "cae")}
              {renderDocs("Identificación de riesgos", <MdHealthAndSafety />, "identificacion-riesgos", convertirAPdf, row, "cae")}
              {renderDocs("Plan emergencia", <IoIosWarning />, "plan-emergencia", convertirAPdf, row, "cae")}
            </>
          )}
          {(row.serviceprops.id === 2 || row.serviceprops.id === 4) && (
            <>
              {renderDocs("Certificado", <TbCertificate />, row.serviceprops.id === 4 ? 'certificado-auditoria' : 'certificado-implantacion', convertirAPdf, row, "lopd")}

              {renderDocs("Implantación", <TiDocumentText />, 'documentacion-implantacion', convertirAPdf, row, "lopd")}

              {row.serviceprops.id === 4 ?
                renderDocs("Auditoría", <TiDocumentText />, 'documentacion-auditoria', convertirAPdf, row, "lopd")
                : null}
              {row.detailsLopd[0]?.surveillanceVideo ? renderDocs("Video vigilancia", <IoVideocam />, "video-vigilancia", convertirAPdf, row, "lopd") : ''}
            </>
          )}
          {(row.serviceprops.id === 5) && (
            <>
              {renderDocs("LOPD", <TbCertificate />, 'lopd', convertirAPdf, row, "lopd")}
            </>
          )}
        </Typography>
      ),
    },
    // user.rol !== 'AAFF' ?
    // {
    //   id: "noVisita",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "No visitada",
    //   overrideFunc: (row) => (
    //     <Typography>
    //         <NoVisitado finca={row} nif={row.properties.nif} tipo={"finca"} />
    //     </Typography>
    //   ),
    // }:'',
    user.rol !== 'AAFF' ?
      {
        id: "servicioRealizado",
        numeric: false,
        disablePadding: false,
        label: "SERVICIO REALIZADO",
        noObjeto: true,

        overrideFunc: (row) => (
          <Typography>
            {!row?.detailsCae?.[0]?.unRealized && row?.detailsCae?.[0]?.visitSheet?.createdAt !== null && row?.detailsCae?.[0]?.visitSheet?.createdAt !== undefined ?
              formatoFechasEs(row?.detailsCae?.[0]?.visitSheet?.createdAt) :
              row?.docsProps?.[0]?.visitSheet_id == null && row?.docsProps?.[0]?.ruta ?
                'Ver Informe'
                : null}
          </Typography>

        ),
      } : '',


  ].filter(Boolean);

  const realizarVisita = {
    id: "hacerVisita",
    numeric: false,
    disablePadding: false,
    label: "Realizar Visita",
    noObjeto: true,
    overrideFunc: (row, data) => (
      <Typography>
        {user.rol !== 'AAFF' && (data.ano < 2024 || data.primeraPendiente === data.ano) ?
          row.aaff.unRealized && row.detailsCae[0]?.visitSheet_id === null ?
            <NoVisitadoAAFF aaff={row.aaff} nif={row.aaff.nif} />
            :
            <NoVisitado finca={row} nif={row.properties.nif} tipo={"finca"} />
          : ''}
        {user.rol === "TECH" ?
          row.detailsCae[0]?.visitada ?
            row.detailsCae[0]?.visitSheet_id ?
              row.workcenters === null ?
                <Link style={{ padding: 5 }} className="btn" to={`/hojaVisita/${row.properties.nif}/edit/${row.id}`}  >
                  <FaPencilAlt />
                </Link>
                :
                <Link style={{ padding: 5 }} className="btn" to={`/hojaVisita/${row.properties.nif}/${row.workcenters.id}/edit/${row.id}`}  >
                  <FaPencilAlt />
                </Link>
              : ''
            :
            (data.ano < 2024 || data.primeraPendiente === data.ano) ?
              row.workcenters === null ?
                <Link style={{ padding: 5 }} className="btn" to={`/hojaVisita/${row.properties.nif}/create/${row.id}`}  >
                  <FaPlus />
                </Link>
              :
                <Link style={{ padding: 5 }} className="btn" to={`/hojaVisita/${row?.properties?.nif}/${row?.workcenters?.id}/create/${row?.id}`}  >
                  <FaPlus />
                </Link>
            : ''
          : ''}
      </Typography>
    ),
  };

  if (user.rol !== 'COMM' && user.rol !== 'AAFF') {
    comunidadesRows.push(realizarVisita)
  };

  const enlaceToDocType = {
    'identificacion-riesgos': 'evaluacionRiesgos',
    'hoja-visita': 'hojaVisita',
    'plan-emergencia': 'planEmergencia',
    'lopd': 'LOPD'
  };

  const renderDocs = (nombre, icono, enlace, convertirAPdf, row, tipo) => {

    let documento;
    let url;
    if (tipo === "lopd" && enlace !== 'lopd') {
      documento = row.detailsLopd?.find(obj => obj.sale_id === row.id);
    }
    if (tipo === "lopd" && enlace === 'lopd') {
      documento = true
    }
    if (tipo === 'cae') {
      documento = row.detailsCae?.find(obj => obj.sale_id === row.id);
    }

    if (!documento) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: 16 }}>
          <Tooltip title={nombre} style={{ fontSize: '16px' }}>
            <small
              className="docsStatus"
              style={{ background: '#FF5252', height: 'auto' }}
            >
              {icono}
            </small>
          </Tooltip>
        </div>
      );
    }

    const { pendiente, visitada } = documento;

    if (tipo === "lopd" && enlace !== 'lopd' || (tipo === 'cae' && (pendiente || visitada))) {
      if (row.workcenters === null) {
        url = `/doc-render/${row.properties?.nif}/${row.id}/${enlace}`
      } else {
        url = `/doc-render/${row.properties?.nif}/${row.workcenters.id}/${row.id}/${enlace}`
      }
    }
    if (tipo === "lopd" && enlace === 'lopd') {
      url = process.env.REACT_APP_STORAGE_URL + row.docsProps?.find(doc => doc.type === enlaceToDocType[enlace])?.ruta
    }
    if (tipo === 'cae' && !documento?.visitSheet_id) {
      url = process.env.REACT_APP_STORAGE_URL + row.docsProps?.find(doc => doc.type === enlaceToDocType[enlace])?.ruta
    }
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {!pendiente && tipo !== 'lopd' && !visitada ?
          <Tooltip title={nombre} style={{ fontSize: '16px' }}>
            <small
              className="docsStatus"
              style={
                { background: '#FF5252' }
              }
            >
              {icono}
            </small>
          </Tooltip>
          :
          <Tooltip title={nombre} style={{ fontSize: '16px' }}>
            <span >
              <Enlace to={url} target="_blank">

                <small
                  className="docsStatus"
                  style={
                    tipo === 'lopd' || visitada ?
                      { background: '#7bc977' }
                      : pendiente ?
                        { background: '#FCBA03' }
                        : { background: '#FF5252' }
                  }
                >
                  {icono}
                </small>

              </Enlace>
            </span>
          </Tooltip>
        }

      </div>
    );
  };

  const convertirAPdf = async ({ nombre, nif, workcenter_id, sale_id }) => {
    try {
      const toastInfo = toast.info("Abriendo archivo");
      //   const response = await generarPDF(`http://localhost:3001/doc-render/${nif}/${nombre}`);
      const datos = {
        url: workcenter_id !== null ? `http://localhost:3001/doc-render/${nif}/${workcenter_id}/${sale_id}/${nombre}` : `http://localhost:3001/doc-render/${nif}/${sale_id}/${nombre}`,
        nombre: nombre,
        nif: nif
      }
      await generarPDF(datos).then(res => {
        const url = URL.createObjectURL(res.data);
        // Abre el PDF en una nueva ventana o pestaña
        const newWindow = window.open('', '_blank');
        const pdfWindow = newWindow;
        pdfWindow.document.write('Cargando PDF...');

        // Promesa para cargar la URL
        const loadURL = new Promise((resolve, reject) => {
          const iframe = document.createElement('iframe');
          iframe.src = url;
          iframe.style.display = 'none';

          iframe.onload = () => {
            resolve();
          };

          iframe.onerror = (error) => {
            reject(error);
          };

          document.body.appendChild(iframe);
        });

        loadURL
          .then(() => {
            // Espera a que el evento "load" se dispare en el iframe antes de abrir la ventana emergente
            pdfWindow.location.href = 'about:blank'; // Limpiar la página en blanco
            pdfWindow.document.write('Cargando PDF...');
            setTimeout(() => {
              pdfWindow.location.href = 'about:blank'; // Limpiar la página en blanco nuevamente
              pdfWindow.location.href = url; // Abrir la URL del PDF
            }, 1000); // Espera 1 segundo (ajusta según sea necesario)
          })
          .catch((error) => {
            console.error('Error al cargar la URL:', error);
            pdfWindow.document.write('Error al cargar la URL');
          });
      })
      //   const blob = new Blob([response.data], { type: 'application/pdf' });

    } catch (error) {
      console.error("Error al generar el PDF:", error);
    }
  };

  const fetchData = async ({ offset, limit, filtros = busqueda, order, orderBy } = {}) => {
    let usuario = {}
    let finca = {}
    let ordenado = ""
    let buscarPor = ""
    if (user.rol === 'TECH') {
      ordenado = "ASC"
      buscarPor = "saleDate"
    }
    if (user.rol === 'AAFF') {
      ordenado = "DESC"
      buscarPor = "saleDate"
    }
    else {
      ordenado = "DESC"
      buscarPor = "id"
    }

    orderBy = orderBy || filtroOrder.ordenadoPor || buscarPor;
    order = order || filtroOrder.orden || ordenado;

    construirURLParamsFiltros(navigate, offset || 1, limit || 50, filtros, order, orderBy, grupoServicio);

    if (user.rol === "COMM") {
      usuario = { commer_id: user.id }
    }
    if (user.rol === "AAFF") {
      usuario = { aaff_id: user.id }
    }
    const payload = {
      // aaff_id: 7,
      filtros: { ...filtros, ...usuario },
      params: { page: offset, limit, order, orderBy },
    };


    try {
      setLoading(true);
      if (nifFinca === undefined) {
        const response = await getSales(payload)
        const { meta, data } = response;
        setListaVentas(data);
        setData({
          meta,
          rows: formatData(data),
        });
      } else {
        const finca = await getFinca(nifFinca)
        let grupoServ_id = null
        if (location.pathname !== '/servicios') {
          grupoServ_id = grupoServicio
        }
        const payload = {
          filtros: { prop_id: finca.id, grupoServ_id },
          params: { page: offset, limit, order, orderBy },
        };

        const response = await getSales(payload)
        const { meta, data } = response;
        const tieneWorkcenter = data.some(item => item.workcenters !== null);

        setTieneCentro(tieneWorkcenter);
        setListaVentas(data)
        setData({
          meta,
          rows: formatData(data),
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const color = ['#0767A2', '#B96800', '#409FD9', '#EE8D11', "", "#77AF6E", "#77D860"]
  const formatData = (data) => {
    console.log(data)
    return data.map((row) => ({
      color: user.rol === "AAFF" && row.serviceprops.id === 6 ? '#0767A2' : user.rol === "AAFF" && row.serviceprops.id === 7 ? '#B96800' : color[row.serviceprops.id - 1],
      primeraPendiente: new Date(row.properties.sales.filter(sale => sale.detailsCae.some(detail => detail.visitada === false)).map(sale => (sale.saleDate))[0]).getFullYear(),

      razonSocial: row.aaff.razonSocial,
      ano: new Date(row.saleDate).getFullYear(),
      servicio: user.rol === "AAFF" && row.serviceprops.nombre === "SPA Implantación" ? "CAE Implantación" : user.rol === "AAFF" && row.serviceprops.nombre === "SPA Renovación" ? "CAE Renovación" : row.serviceprops.nombre,
      nif: row.properties.nif,
      nif_aaff: row.aaff.nif,
      finca: row.properties,
      comunidad: row.workcenters === null ? row.properties.razonSocial : row.workcenters.razonSocial,
      direccion: row.properties.direccion,
      codigoPostal: row.properties.codigoPostal,
      saleDate: row.saleDate,
      documentos: row,
      noVisita: row,
      servicioRealizado: Object.keys(row.detailsLopd).length > 0 ? row.saleDate : row,
      hacerVisita: user.rol !== 'COMM' && user.rol !== 'AAFF' ? row : null,
      isWorkcenter: row.workcenters ? true : false
    }));
  };
  // function handleChange(evt) {
  //   const value = evt.target.value;
  //   setBusqueda({
  //     ...busqueda,
  //     [evt.target.name]: value,
  //   });
  // }

  // const getBusqueda = () => {
  //   for (let key in busqueda) {
  //     if (
  //       busqueda[key] === "" ||
  //       busqueda[key] === null ||
  //       busqueda[key] === undefined
  //     ) {
  //       delete busqueda[key];
  //     }
  //   }
  //   fetchData({ offset: 1, limit: limite, filtros: busqueda });
  // };

  const filtrarDatos = (filtros) => {
    setBusqueda(filtros)
    setBusquedaActiva(true);
    // fetchData({ offset: 1, limit: limite, filtros });
  }

  return (
    <>
      {nifFinca === undefined && (
        <BreadCrumb rutas={[{ path: `/servicios`, label: `Servicios` }]} style={{ margin: '0 10px' }} />
      )}
      <div className="pageContent tableContainer" style={{ margin: 0 }}>

        {nifFinca === undefined && (
          <>
            {user.rol !== "ADMIN" && user.rol !== "CONSUL" && user.rol !== "VENT" ? null :
              <div className="contentBar" style={{ padding: '0 10px' }}>
                <div className="contentBtns"></div>
                <Link to="/servicios/create" target={"_blank"}>
                  <div className="contentButton">
                    <img src={Add} alt="Más" />
                  </div>
                </Link>
              </div>
            }

            <FiltrosVentas onFilter={filtrarDatos} clearFilter={clearFilters} filtrosIniciales={busqueda} />
          </>
        )}
        <div style={{ margin: '20 0' }}>
          <ExportarListaServicios filtros={busqueda} />
        </div>
        <div className="tableWrapper">
          <Box className="pageContent" style={{ margin: 0 }}>
            <Box sx={{ mb: 10 }}>
              <DataTable
                data={data.rows}
                isLoading={loading}
                headers={comunidadesRows}
                fetcher={fetchData}
                currentPage={data.current_page}
                meta={data.meta}
                totalItems={data.total}
                totalPages={data.totalPages}
                nextPage={data.next_page}
                filtros={busqueda}
                busquedaActiva={busquedaActiva}
                setBusquedaActiva={setBusquedaActiva}
                clearFilterActivo={clearFilterActivo}
                setClearFilterActivo={setClearFilterActivo}
                mensaje={"No hay ventas"}
              />
            </Box>
          </Box>
        </div>
      </div>
      <style jsx="true">{`
        .tableContainer table tbody th.docs, .tableContainer table thead th {
          text-align: center;
        }
        .tableContainer table tbody td {
          padding: 0;
        }
      `}</style>
    </>
  );
}
