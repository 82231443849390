import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "../../../providers/withRouter";
import { DataTable } from "../../../components";
import {
  LinearProgress,
  Box,
  linearProgressClasses,
  styled,
} from "@mui/material";
import { getAaffListSuplier } from "../../../services";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import { UsuarioContext } from "../../../providers/userContext";
import FiltrosProveedores from "../../../components/filtros/filtrosProveedores";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecogerURLParamsFiltros, construirURLParamsFiltros } from './../../../functions/parametrosURL/funcionesParamsURL';

const BorderLinearProgress = styled(LinearProgress)((props) => ({
  height: 15,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    //backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 700],
    backgroundColor: "#eee",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: props.value,
    backgroundColor:
      props.value < 25 ? "#FF5252" : props.value < 75 ? "#fcba03" : "#7bc977",
  },
}));

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={2}>
        <BorderLinearProgress {...props} variant="determinate" />
      </Box>
      {/* <Box minWidth={35}>{props.label}</Box> */}
      <Box minWidth={35}>
        {`${Math.round(
          props.value * 100
        ) / 100}%`}
      </Box>
    </Box>
    
  );
}

const proveedoresRows = [
  {
    id: "razonSocial",
    numeric: false,
    disablePadding: false,
    label: "PROVEEDOR",
    overrideFunc: (data, row) => (
      <Link to={"/proveedores/" + row.nif}>
        {data}
      </Link>
    )
  },
  {
    id: "municipio",
    numeric: false,
    disablePadding: false,
    label: "MUNICIPIO",
  },
  {
    id: "provincia",
    numeric: false,
    disablePadding: false,
    label: "PROVINCIA",
  },
  {
    id: "cumplimiento",
    numeric: false,
    disablePadding: false,
    label: "CUMPLIMIENTO",
    noObjeto:true,
    overrideFunc: (data, row) => (
      <LinearProgressWithLabel value={data} variant="determinate" />
    ),
  },
]
const ProveedoresLista = () => {
  
  const {filtrosIniciales, filtroOrder} = useRecogerURLParamsFiltros();
  const [busqueda, setBusqueda] = useState(filtrosIniciales);
  const [loading, setLoading] = useState(true);
  const [recargar, setRecargar] = useState(false);
  const [limite, setLimite] = useState(0);
  const [busquedaActiva, setBusquedaActiva] = useState(false);
  const [clearFilterActivo, setClearFilterActivo] = useState(false);
  const [data, setData] = useState({
    meta: {},
    rows: [],
  });

  const navigate = useNavigate();
  const location = useLocation();

  const clearFilters = () => {
    setBusqueda({});
    setClearFilterActivo(true);
  }

  const fetchData = async ({ offset, limit, filtros = busqueda, order,orderBy } = {}) => {
    orderBy = orderBy || filtroOrder.ordenadoPor || 'id';
    order =   order || filtroOrder.orden || 'ASC';
    construirURLParamsFiltros(navigate, offset || 1, limit || 50, filtros, order,orderBy);

    const payload = {
      filtros,
      params: { page: offset, limit, order,orderBy },
    };

    try {
      setLoading(true);
      const response = await getAaffListSuplier(payload);
      const { meta, data } = response;

      setData({
        meta,
        rows: formatData(data),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const formatData = (data) => {
    return data.map((row) => ({
      razonSocial: row.razonSocial,
      nif: row.nif,
      municipio: row.municipio,
      provincia: row.provincia,
      cumplimiento: row.porcentajeDocs === null ? 0 : row.porcentajeDocs
    }));
  };

  const filtrarDatos = (filtros) => {
    setBusqueda(filtros);
    setBusquedaActiva(true);
  }

  return (
    <>
      <BreadCrumb rutas={[{ path: `/proveedores`, label: 'Proveedores' }]} />
      <div className="listPage">
        <div className="gridCard">
          <div className="gridHead">LISTADO PROVEEDORES</div>
          <div className="tableContainer">
            <div>
              <FiltrosProveedores onFilter={filtrarDatos} clearFilter={clearFilters} filtrosIniciales={busqueda} />
              <div className="exportar">
                {/* <ExportarProveedoresLista busqueda={Resbusqueda} />  */}
              </div>
            </div>
            <div className="tableWrapper">
              <Box className="pageContent">
                <Box sx={{ mb: 10 }}>
                  <DataTable
                    data={data.rows}
                    isLoading={loading}
                    headers={proveedoresRows}
                    fetcher={fetchData}
                    currentPage={data.current_page}
                    meta={data.meta}
                    totalItems={data.total}
                    totalPages={data.totalPages}
                    nextPage={data.next_page}
                    filtros={busqueda}
                    busquedaActiva={busquedaActiva}
                    setBusquedaActiva={setBusquedaActiva}
                    clearFilterActivo={clearFilterActivo}
                    setClearFilterActivo={setClearFilterActivo}
                    mensaje={"No hay proveedores"}
                  />
                </Box>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(ProveedoresLista);
