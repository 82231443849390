import React, { useState, useEffect, useContext } from "react";
import { Box, Select, Typography } from "@mui/material";
import { withRouter } from "../../../providers/withRouter";
import { UsuarioContext } from "../../../providers/userContext";
import { DataTable } from "../../../components";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import Cross from "../../../icons/cross.svg";
import Tick from "../../../icons/tick.svg";
import FiltrosComunidades from '../../../components/filtros/filtrosComunidades';
import {
  getTecnico,
  getOneAAFF,
  getTodasFincasTech,
  cambiarFinca,
} from "../../../services";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from 'react-router-dom';
import { getListFincas } from "../../../services";
import { useRecogerURLParamsFiltros, construirURLParamsFiltros } from './../../../functions/parametrosURL/funcionesParamsURL';




const HojasVisita = (props) => {
  const user = useContext(UsuarioContext);
  const [recargar, setRecargar] = useState(false);
  //   const { loading, data, setPagination, pagination, fetchData } = useAAFFs();
  const [loading, setLoading] = useState(true);
  const [fincas, setFincas] = useState([]);
  const [tecnico, setTecnico] = useState({});
  const [mensaje, setMensaje] = useState("");
  const [datosCargados, setDatosCargados] = useState(false);


  const { filtrosIniciales, filtroOrder } = useRecogerURLParamsFiltros();

  const [busqueda, setBusqueda] = useState(filtrosIniciales);
  const [busquedaActiva, setBusquedaActiva] = useState(false);
  const [clearFilterActivo, setClearFilterActivo] = useState(false);

  const [guardadoFiltros, setGuardadoFiltros] = useState({});
  const [primeraCarga, setPrimeraCarga] = useState(true);
  const [aaff, setAaff] = useState({});
  
  const [data, setData] = useState({
    meta: {},
    rows: [],
  });



  const navigate = useNavigate();

  const clearFilters = () => {
    if (user.rol === "CONSUL") {
      setBusqueda({
        consul_id: user.id
      });
    }
    else {
      setBusqueda({});
    }

    setClearFilterActivo(true);
  }

  useEffect(() => {
    loadData();
    // setRecargar(false);
  }, [recargar]);

  useEffect(() => {
    fetchData();
  }, [aaff])

  const loadData = async () => {
    const [tecnico, responseAAFF] = await Promise.all([
      getTecnico(props.params.nifTecnico),
      getOneAAFF(props.params.nifAAFF),
    ]);
    // Una vez obtenidos los resultados, actualizamos los estados
    setTecnico(tecnico);
    setAaff(responseAAFF);
  }

  const handleChangeActivo = (evt, nif) => {
    const actualizarEstado = data.rows.map((row) => {
      if (row.nif === nif) {
        cambiarFinca(nif, {
          activo: evt.target.value
        })
        return { ...row, activo: evt.target.value === 'true' };

      }
      return row;
    });
    setData({ ...data, rows: actualizarEstado });
  };

  const handleChangeEmpleados = (evt, nif) => {
    const actualizarEstado = data.rows.map((row) => {
      if (row.nif === nif) {
        cambiarFinca(nif, {
          trabajadores: evt.target.value
        })
        return { ...row, trabajadores: evt.target.value === 'true' };

      }
      return row;
    });
    setData({ ...data, rows: actualizarEstado });
  };

  
   const fetchData = async ({ offset, limit, filtros, order, orderBy } = {}) => {

    orderBy = orderBy || filtroOrder.ordenadoPor || 'id';
    order = order || filtroOrder.orden || 'ASC';

    const filtrosCombinados = {
      ...filtros,
      ...(busqueda == {} ? "" : busqueda),
      aaff_id: aaff.id || 0,
    };

    
    

    const id = filtrosCombinados.aaff_id;

    if (!id) {
      console.warn('No se encontró un id válido para la solicitud.');
      return;
    }

    //console.log("FiltrosCom", filtrosCombinados);

    construirURLParamsFiltros(navigate, offset || 1, limit || 50, filtrosCombinados ,order, orderBy);


    const payload = {
      filtros: filtrosCombinados,
      params: {
        page: offset,
        limit: limit,
        order: order,
        orderBy: orderBy,
      },
    }

    try {
      setLoading(true);
      const response = await getTodasFincasTech(payload);
      const { meta, data } = response;
      setData({
        meta,
        rows: formatData(data, meta),
      });
      setRecargar(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      
    }
  };



  const ComunidadesRows = [
    {
      id: "razonSocial",
      numeric: false,
      disablePadding: false,
      label: "COMUNIDAD",
    },
    {
      id: "nif",
      numeric: false,
      disablePadding: false,
      label: "NIF",
    },
    {
      id: "direccion",
      numeric: false,
      disablePadding: false,
      label: "DIRECCIÓN",
    },
    {
      id: "codigoPostal",
      numeric: false,
      disablePadding: false,
      label: "C.P.",
    },
    {
      id: "municipio",
      numeric: false,
      disablePadding: false,
      label: "MUNICIPIO",
    },
    {
      id: "activo",
      numeric: false,
      disablePadding: false,
      label: "ACTIVO",
      noObjeto: true,
      overrideFunc: (data, row) => (
        data !== "" ?
          <div className="activoSelect">
            <Select
              label="Activo"
              native
              name="activo"
              id="activo"
              style={{ textTransform: 'uppercase', padding: 0, fontSize: '11px', color: data ? "green" : 'red' }}
              onChange={(event) => handleChangeActivo(event, row.nif)} value={data}
            >
              <option value={true}>Sí</option>
              <option value={false}>No</option>
            </Select>
          </div>
          : ""
      ),
    },
    {
      id: "trabajadores",
      numeric: false,
      disablePadding: false,
      label: "EMPLEADOS",
      noObjeto: true,
      overrideFunc: (data, row) => (
        data !== "" ?
          <div className="empleadosSelect">
            <Select
              label="Empleados"
              native
              name="trabajadores"
              id="trabajadores"
              style={{ textTransform: 'uppercase', padding: 0, fontSize: '11px', color: data ? "green" : 'red' }}
              onChange={(event) => handleChangeEmpleados(event, row.nif)} value={data}
            >
              <option value={true}>Sí</option>
              <option value={false}>No</option>
            </Select>
          </div>
          : ""
      ),
    },
    {
      id: "identificacionRiesgos",
      numeric: false,
      disablePadding: false,
      noObjeto: true,
      label: "IDENTIFICACIÓN DE RIESGOS Y MEDIDAS PREVENTIVAS",
      overrideFunc: (data, row) => (
        <Typography style={{ textAlign: 'left' }}>
          {data ? <Link to={`/doc-render/${row.nif}/${row.sale_id}/identificacion-riesgos`} target="_blank"><img src={Tick} alt="" style={{ width: 20 }} /></Link> : <img src={Cross} alt="" style={{ width: 20 }} />}
        </Typography>
      )
    },
    {
      id: "planEmergencia",
      numeric: false,
      disablePadding: false,
      label: "PLAN DE EMERGENCIA",
      noObjeto: true,
      overrideFunc: (data, row) => (
        <Typography style={{ textAlign: 'left' }}>
          {data ? <Link to={`/doc-render/${row.nif}/${row.sale_id}/plan-emergencia`} target="_blank"><img src={Tick} alt="" style={{ width: 20 }} /></Link> : <img src={Cross} alt="" style={{ width: 20 }} />}
        </Typography>
      )
    },
    {
      id: "planPrevencion",
      numeric: false,
      disablePadding: false,
      label: "PLAN DE PREVENCIÓN",
      noObjeto: true,
      overrideFunc: (data, row) => (
        <Typography style={{ textAlign: 'left' }}>
          {data ? <Link to={`/doc-render/${row.nif}/${row.sale_id}/plan-prevencion`} target="_blank"><img src={Tick} alt="" style={{ width: 20 }} /></Link> : <img src={Cross} alt="" style={{ width: 20 }} />}
        </Typography>
      )
    },
    {
      id: "hojaVisita",
      numeric: false,
      disablePadding: false,
      label: "HOJA DE VISITA",
      noObjeto: true,
      overrideFunc: (data, row) => (
        <Typography style={{ textAlign: 'left' }}>
          {data ? <Link to={`/doc-render/${row.nif}/${row.sale_id}/hoja-visita`} target="_blank"><img src={Tick} alt="" style={{ width: 20 }} /></Link> : <img src={Cross} alt="" style={{ width: 20 }} />}
        </Typography>
      )
    },
  ];

  const formatData = (data) => {
    // data.map((row) => (
    //   console.log(row)))

    return data.map((row) => ({
      id: row.id,
      razonSocial: row.razonSocial,
      nif: row.nif,
      direccion: row.direccion,
      codigoPostal: row.codigoPostal,
      municipio: row.municipio,
      activo: row.activo === null ? true : row.activo,
      empleados: row.trabajadores === null ? false : row.trabajadores,
      identificacionRiesgos: row.sales[0]?.detailsCae[0]?.visitSheet_id !== null ? true : false,
      planEmergencia: row.sales[0]?.detailsCae[0]?.visitSheet_id !== null ? true : false,
      planPrevencion: row.sales[0]?.detailsCae[0]?.visitSheet_id !== null ? true : false,
      hojaVisita: row.sales[0]?.detailsCae[0]?.visitSheet_id !== null ? true : false,
      sale_id: row.sales[0].id,

    }));
  };

  const filtrarDatos = (filtros) => {
    setBusqueda(filtros);
    setBusquedaActiva(true);
  };


  return (
    <>
      <BreadCrumb rutas={[{ path: '/tecnicos', label: `Tecnicos` }, { path: `/tecnicos/${tecnico.nif}/aaff`, label: `${tecnico.nombre + " " + tecnico.apellidos}` }, { path: `/tecnicos`, label: `${aaff.razonSocial}` }]} style={{ margin: '0 10px' }} />
      <div className="pageContent tableContainer">
        <FiltrosComunidades onFilter={filtrarDatos} clearFilter={clearFilters} filtrosIniciales={busqueda} ocultarAaff/>
        <div className="tableWrapper">
          <Box className="pageContent">
            <Box sx={{ mb: 10 }}>
            <DataTable
                data={data.rows}
                isLoading={loading}
                headers={ComunidadesRows}
                fetcher={fetchData}
                currentPage={data.current_page}
                meta={data.meta}
                totalItems={data.total}
                totalPages={data.totalPages}
                nextPage={data.next_page}
                filtros={busqueda}
                busquedaActiva={busquedaActiva}
                setBusquedaActiva={setBusquedaActiva}
                clearFilterActivo={clearFilterActivo}
                setClearFilterActivo={setClearFilterActivo}
                mensaje={"No hay comunidades"}
                propCaducada={true}
              />
            </Box>
          </Box>
        </div>
      </div>
      <style jsx="true">{`
              #empleados, #activo{
                padding:0 25px 0 0;
                border: none;
              }
              .empleadosSelect fieldset, .empleadosSelect select:hover, .activoSelect fieldset, .activoSelect select:hover{
                border-color: rgba(0, 0, 0, 0) !important;
              }               
          `}</style>
    </>
  );
};

export default withRouter(HojasVisita);
