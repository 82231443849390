import axios from "axios";

const urlPath = "aaff";

export const getAaffSuppServ = async ( nifSupp) => {
  try {
    const response = await axios.get(`${urlPath}/getAaffSuppServ/${nifSupp}`);

    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const getAAFFId = async (nif) => {
  try {
    const response = await axios.get(`${urlPath}/getAAFFId/${nif}`);

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

//Esta petición no se usa
// export const getList = async () => {
//   try {
//     const response = await axios.get(`${urlPath}/getlist`);

//     return response;
//   } catch (error) {
//     console.log(error);
//     return error;
//   }
// };

export const getWelcomeAlert = async (data) => {
  try {
    const response = await axios.post(`${urlPath}/getWelcomeAlertList`, data.filtros, {
      params: data.params,
    });

    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const validateWelcomeAlert = async (id) => {
  try {
    const response = await axios.post(`${urlPath}/WelcomeAlertValidate/${id}`);

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const getInformeAlert = async (data) => {
  try {
    const response = await axios.post(`${urlPath}/getInformeAlertList`, data.filtros, {
      params: data.params,
    });

    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const validateInformeAlert = async (id) => {
  try {
    const response = await axios.post(`${urlPath}/InformeAlertValidate/${id}`);

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const getReclamationAlert = async (data) => {
  try {
    const response = await axios.post(`${urlPath}/getReclamationAlertList`, data.filtros, {
      params: data.params,
    });

    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const validateReclamationAlert = async (id) => {
  try {
    const response = await axios.post(`${urlPath}/ReclamationAlertValidate/${id}`);

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};




export const getPropertiFromAAFFfilter = async () => {
  try {
    const response = await axios.get(urlPath);
    return response.data;
  } catch (err) {
    console.error(err)
    console.log(err.response);
  }
};

export const getListAAFFs = async () => {
  try {
    const response = await axios.get(`${urlPath}/aaffList`);

    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};

export const getAAFFs = async (data) => {

  try {
    const response = await axios.post(`${urlPath}/filter`, data.filtros, {
      params: data.params
    });
    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};

export const filterAAFFs = async (data) => {
  try {
    const response = await axios.post(`${urlPath}/filter`, data.filtros, {
      params: data.params
    });

    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};

export const listAAFFs = async (data) => {
  try {
    const response = await axios.post(`${urlPath}/listarBusqueda`, data.filtros, {
      params: data.params,
      
    });

    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};

export const getListadoAaffsSupp = async (data) => {
  try {
    const response = await axios.post(`${urlPath}/ListadoAaffsSupp`, data.filtros, {
      params: data.params,
    });

    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};
export const getAAFF = async (id) => {
  try {
    const response = await axios.get(`${urlPath}/${id}`);
    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};
export const getOnlyAAFF = async (id) => {
  try {
    const response = await axios.get(`${urlPath}/onlyAaff/${id}`);
    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};
export const getOneAAFF = async (id) => {
  try {
    const response = await axios.get(`${urlPath}/oneAAFF/${id}`);
    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};
export const getAAFFPropVisit = async (id) => {
  try {
    const response = await axios.get(`${urlPath}/getAaffPropVisit/${id}`);
    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};
export const getAaffPropInformeSupp = async (id) => {
  try {
    const response = await axios.get(`${urlPath}/getAaffPropInformeSupp/${id}`);
    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};
export const createAAFF = async (data) => {
  try {
    const response = await axios.post(urlPath, data);

    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};
export const editarAAFF = async (nif, data) => {
  try {
    const response = await axios.put(`${urlPath}/${nif}`, data);

    return response;
  } catch (err) {
    console.log(err.response);
    return err.response;
  }
};
export const deleteAAFF = async (nif) => {
  try {
    const response = await axios.delete(`${urlPath}/${nif}`);

    return response;
  } catch (err) {
    console.log(err.response);
  }
};

export const changeAAFF = async (nifFinca, nuevoaaff, contacto) => {
  let datos = {
    nifAAFF: nuevoaaff,
    emailContacto: contacto.emailContacto,
    telefonoContacto: contacto.telefonoContacto,
    nombreContacto: contacto.nombreContacto
  };
  try {
    const response = await axios.put(`propertie/${nifFinca}`, datos);

    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};
export const sendEmailBienvenida = async (id, nifAAFF, razonSocialAAFF, email) => {
  const datos = {
    user_id:id,
    email,
    razonSocial: razonSocialAAFF
  }
  try {
    const response = await axios.post(`${urlPath}/sendEmail/${nifAAFF}`, datos,{
      nifAAFF
    });

    return response;
  } catch (err) {
    console.log(err.response);
  }
};

export const loginAs = async (nifAAFF) => {
  try {
    const response = await axios.get(`${urlPath}/${nifAAFF}/login`);

    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};

export const noRealizadaAAFF = async (id, data) => {
  try {
    const response = await axios.put(`${urlPath}/notVisit/${id}`, data);

    return response.data;
  } catch (error) {
    console.log(error);
  }
};