import React, { useState, useEffect } from "react";
import {
  Grid,
  Select,
  FormControl,
  TextField,
  Collapse,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
//import Change from "../icons/change.svg"
import { getAAFFs, changeAAFF, getListAAFFs } from "../services";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

export function DialogoCambioAAFF(props) {
  const [open, setOpen] = useState(false);
  const [aaffs, setAAFFS] = useState([]);
  const [aaffSeleccionado, setAAFFSeleccionado] = useState({});
  const [aaffBuscado, setAAFFBuscado] = useState(null);
  const [nuevaLista, setNuevaLista] = useState([]);

  const nifAAFF = props.aaff;
  let finca = props.finca;
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.setOpen(false);
  };

  useEffect(() => {
    getListAAFFs().then((res) => {
      setAAFFS(res);
      if (res.length > 0) {
        // Establece el primer valor como seleccionado por defecto si no hay otro valor seleccionado.
        setAAFFSeleccionado(res[0].nif);
      }
    });
    if (finca.nif !== undefined) handleClickOpen();
  }, [finca]);

  const seleccionarAAFF = (evt) => {
    // console.log("evt.target.value", evt.target.value)
    setAAFFSeleccionado(evt.target.value);
  };
  const mostrarAAFF = (evt) => {
    const valorBuscado = evt.target.value.trim().toLowerCase(); // limpiar espacios y pasar a minúsculas
    setAAFFBuscado(valorBuscado);
    let nuevaLista = [];

    aaffs.forEach((j) => {
      if (
        j.nif.toString().toLowerCase().includes(valorBuscado) ||
        j.razonSocial.toLowerCase().includes(valorBuscado)
      ) {
        nuevaLista.push({ nif: j.nif, razonSocial: j.razonSocial });
      }
    });

    if (nuevaLista.length === 1) {
      nuevaLista.forEach((l) => {
        setAAFFSeleccionado(l.nif);
      });
    }

    setNuevaLista(nuevaLista);
  };

  const cambiarAAFF = () => {
    let nuevoaaff = null;
    let contacto = {
      telefonoContacto: finca.cambiar.telefonoContacto,
      emailContacto: finca.cambiar.emailContacto,
      nombreContacto: finca.cambiar.nombreContacto,
    };
    if (aaffSeleccionado === null) {
      nuevoaaff = nifAAFF;
    } else {
      nuevoaaff = aaffSeleccionado;
    }
    changeAAFF(finca.nif, nuevoaaff, contacto)
      .then((res) => {
        console.log(res);

        if (
          (res.status >= 200 && res.status < 300) ||
          res.message === "PROPERTIE_UPDATED"
        ) {
          toast.success("CP cambiado con éxito");
        }
        handleClose();
        props.hijoAPadre(1);
      })
      .catch((err) => {
        console.log(err);
        props.hijoAPadre(0);
        toast.error("error al cambiar de aaff");
      });
  };
  return (
    <div>
      {/* <img src={Change} alt="CAMBIAR AAFF" className="tableIcon" onClick={handleClickOpen}></img> */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          ¿Quieres cambiar el aaff de <b>{finca.comunidad_name}</b>?
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
        </DialogContent>
        <form noValidate autoComplete="off">
          <div style={{ margin: "8px 30px" }}>
            <TextField
              id="buscarAAFF"
              name="buscarAAFF"
              label="Buscar AAFF por nif"
              onChange={mostrarAAFF}
              autoFocus
              style={{ margin: "0 0 40px 0" }}
              fullWidth
              variant="standard"
            />
            <Select
              isSearchable
              value={aaffSeleccionado || ""}
              style={{ width: "100%" }}
              fullWidth
              variant="standard"
              native
              name="aaff"
              id="aaff"
              onChange={seleccionarAAFF}
            >
              {aaffBuscado === null
                ? aaffs
                    .sort((a, b) =>
                      a.razonSocial
                        .toLowerCase()
                        .localeCompare(b.razonSocial.toLowerCase())
                    )
                    ?.map((j, i) => {
                      return (
                        <option key={i} value={j.nif} data-name={j.razonSocial}>
                          {j.razonSocial}
                        </option>
                      );
                    })
                : nuevaLista
                    .sort((a, b) =>
                      a.razonSocial
                        .toLowerCase()
                        .localeCompare(b.razonSocial.toLowerCase())
                    )
                    .map((j, i) => {
                      return (
                        <option key={i} value={j.nif} data-name={j.razonSocial}>
                          {j.razonSocial}
                        </option>
                      );
                    })}
            </Select>
          </div>
          <DialogActions>
            <Button
              onClick={() => {
                setOpen(false);
                props.setOpen(false);
              }}
              color="inherit"
              style={{ color: "#FF5252" }}
            >
              Cancelar
            </Button>
            <Button
              onClick={cambiarAAFF}
              color="inherit"
              style={{ color: "#7bc977" }}
            >
              <b>Cambiar</b>
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export function DialogoSubirDocumentacion(props) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    handleClickOpen();
  }, []);

  return (
    <div>
      {/* <img src={Change} alt="CAMBIAR AAFF" className="tableIcon" onClick={handleClickOpen}></img> */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          ¿Qué quieres hacer con el documento?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Subir un nuevo documento implica sobreescribir el anterior
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {}}
            color="inherit"
            style={{ color: "#92004D" }}
          >
            Borrar documento
          </Button>
          <Button
            onClick={() => {}}
            color="inherit"
            style={{ color: "#92004D" }}
          >
            Subir nuevo
          </Button>
          <Button
            onClick={() => {}}
            color="inherit"
            style={{ color: "#92004D" }}
            autoFocus
          >
            <b>VER</b>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
