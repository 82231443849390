import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import { withRouter } from "../../../providers/withRouter";
import { UsuarioContext } from "../../../providers/userContext";
import { DataTable } from "../../../components";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import { IoSave, IoDocumentText } from "react-icons/io5";
import { getAAFFs } from "../../../services";
import FiltrosAAFFInformes from "../../../components/filtros/filtrosAAFFInformes";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useRecogerURLParamsFiltros,
  construirURLParamsFiltros,
} from "./../../../functions/parametrosURL/funcionesParamsURL";

const comunidadesRows = [
  {
    id: "aff_name",
    numeric: false,
    disablePadding: false,
    label: "ADMINISTRADOR",
    overrideFunc: (data, row) => (
      <Link to={"/aaff/" + row.nif + "/comunidades"}>{data}</Link>
    ),
  },
  {
    id: "nif",
    numeric: false,
    disablePadding: false,
    label: "NIF",
  },
  {
    id: "informeComunidades",
    numeric: false,
    disablePadding: false,
    label: "INFORME COMUNIDADES",
    overrideFunc: (row) => (
      <Link
        to={`/doc-render/${row}/situacion-comunidades`}
        target={"_blank"}
        style={{
          display: "flex", // Asegura que ocupe el tamaño completo
          alignItems: "center",
          justifyContent: "center",
          width: "120px", // Ajusta el ancho del link
          height: "40px", // Ajusta la altura del link
          textDecoration: "none", // Elimina el subrayado del texto
        }}
      >
        <div className="bigBtn">
          <IoDocumentText alt="Informe comunidades" />
          <span style={{ marginLeft: 15 }}>Generar</span>
        </div>
      </Link>
    ),
  },
  {
    id: "informeComunidadesSinProveedores",
    numeric: false,
    disablePadding: false,
    label: "INFORME CP SIN PROVEEDORES",
    noObjeto: true,
    overrideFunc: (row) => (
      <Link
        to={`/doc-render/${row}/comunidades-sin-proveedores`}
        target={"_blank"}
        style={{
          display: "flex", // Asegura que ocupe el tamaño completo
          alignItems: "center",
          justifyContent: "center",
          width: "120px", // Ajusta el ancho del link
          height: "40px", // Ajusta la altura del link
          textDecoration: "none", // Elimina el subrayado del texto
        }}
      >
        <div className="bigBtn">
          <IoDocumentText alt="Informe comunidades sin proveedores" />
          <span style={{ marginLeft: 15 }}>Generar</span>
        </div>
      </Link>
    ),
  },
  {
    id: "informeProveedores",
    numeric: false,
    disablePadding: false,
    label: "INFORME PROVEEDORES",
    overrideFunc: (row) => (
      <Link
        to={`/doc-render/${row}/situacion-proveedores`}
        target={"_blank"}
        style={{
          display: "flex", // Asegura que ocupe el tamaño completo
          alignItems: "center",
          justifyContent: "center",
          width: "120px", // Ajusta el ancho del link
          height: "40px", // Ajusta la altura del link
          textDecoration: "none", // Elimina el subrayado del texto
        }}
      >
        <div className="bigBtn">
          <IoDocumentText alt="Informe proveedores" />
          <span style={{ marginLeft: 15 }}>Generar</span>
        </div>
      </Link>
    ),
  },
  {
    id: "informeRiesgos",
    numeric: false,
    disablePadding: false,
    label: "INFORME RIESGOS",
    overrideFunc: (row) => (
      <Link
        to={`/doc-render/${row}/situacion-riesgos`}
        target={"_blank"}
        style={{
          display: "flex", // Asegura que ocupe el tamaño completo
          alignItems: "center",
          justifyContent: "center",
          width: "120px", // Ajusta el ancho del link
          height: "40px", // Ajusta la altura del link
          textDecoration: "none", // Elimina el subrayado del texto
        }}
      >
        <div className="bigBtn">
          <IoDocumentText alt="Informe riesgos" />
          <span style={{ marginLeft: 15 }}>Generar</span>
        </div>
      </Link>
    ),
  },
];

const AAFFInformes = () => {
  const user = useContext(UsuarioContext);
  const { filtrosIniciales, filtroOrder } = useRecogerURLParamsFiltros();
  const [busqueda, setBusqueda] = useState(filtrosIniciales);
  const [recargar, setRecargar] = useState(0);
  const [busquedaActiva, setBusquedaActiva] = useState(false);
  const [loading, setLoading] = useState(true);
  const [clearFilterActivo, setClearFilterActivo] = useState(false);
  const [data, setData] = useState({
    meta: {},
    rows: [],
  });

  useEffect(() => {
    setRecargar(false);
  }, [recargar]);

  const navigate = useNavigate();

  const clearFilters = () => {
    setBusqueda({});
    setClearFilterActivo(true);
  };

  const fetchData = async ({
    offset,
    limit,
    filtros = busqueda,
    order,
    orderBy,
  } = {}) => {
    let usuario = { commer_id: user.id, informe: true };

    orderBy = orderBy || filtroOrder.ordenadoPor || "id";
    order = order || filtroOrder.orden || "ASC";

    construirURLParamsFiltros(
      navigate,
      offset || 1,
      limit || 50,
      filtros,
      order,
      orderBy
    );

    const payload = {
      filtros: { ...filtros, ...usuario },
      params: { page: offset, limit, order, orderBy },
    };

    try {
      setLoading(true);
      const response = await getAAFFs(payload);

      const { meta, data } = response;
      setData({
        meta,
        rows: formatData(data),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const formatData = (data) => {
    return data.map((row) => ({
      aff_name: row.razonSocial,
      nif: row.nif,
      informeComunidades: row.nif,
      informeProveedores: row.nif,
      informeComunidadesSinProveedores: row.nif,
      informeRiesgos: row.nif,
    }));
  };

  const filtrarDatos = (filtros) => {
    setBusqueda(filtros);
    setBusquedaActiva(true);
    // fetchData({ offset: 1, limit: limite, filtros: filtros });
  };

  return (
    <>
      <BreadCrumb
        rutas={[{ path: "/aaff-informes", label: "Administradores de fincas" }]}
        style={{ margin: "0 10px" }}
      />
      <div className="pageContent tableContainer">
        <FiltrosAAFFInformes
          onFilter={filtrarDatos}
          clearFilter={clearFilters}
          filtrosIniciales={busqueda}
        />
        <div className="tableWrapper">
          <Box className="pageContent">
            <Box sx={{ mb: 10 }}>
              <DataTable
                data={data.rows}
                isLoading={loading}
                headers={comunidadesRows}
                fetcher={fetchData}
                currentPage={data.current_page}
                meta={data.meta}
                totalItems={data.total}
                totalPages={data.totalPages}
                nextPage={data.next_page}
                filtros={busqueda}
                busquedaActiva={busquedaActiva}
                setBusquedaActiva={setBusquedaActiva}
                clearFilterActivo={clearFilterActivo}
                setClearFilterActivo={setClearFilterActivo}
                mensaje={"No hay administradores de fincas"}
              />
            </Box>
          </Box>
        </div>
      </div>
    </>
  );
};

export default withRouter(AAFFInformes);
