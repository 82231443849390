import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { filterAAFFs } from "../../services";
import CircularProgress from "@mui/material/CircularProgress";

export default function ProfileBtnMail() {
  const [busqueda, setBusqueda] = useState({});
  const [tecniCaeLopd, setTecniCaeLopd] = useState(null);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await filterAAFFs({ page: 1, limit: 1000 });
    const { meta, data } = response;
    let tecnicos = [],
      cae = [],
      lopd = [];
    // console.log(data);

    data.forEach((e) => {
      // Agregar técnico a la lista
      if (e.technician) {
        tecnicos.push({
          name: `${e.technician.nombre} ${e.technician.apellidos}`,
          mail: e.technician.user?.email || null,
        });
      }

      // Buscar todos los consultores con group_id === 2 (cae)
      const caeConsultants =
        e.consultants?.filter((consultant) =>
          consultant.r_consul_servsgroups_aaffs.some(
            (group) => group.group_id === 1
          )
        ) || [];

      // Buscar todos los consultores con group_id === 1 (lopd)
      const lopdConsultants =
        e.consultants?.filter((consultant) =>
          consultant.r_consul_servsgroups_aaffs.some(
            (group) => group.group_id === 2
          )
        ) || [];

      // Agregar consultores a cae
      cae.push(
        ...caeConsultants.map((consultant) => ({
          name: `${consultant.nombre} ${consultant.apellidos}`,
          mail: consultant.user?.email || null,
        }))
      );

      // Agregar consultores a lopd
      lopd.push(
        ...lopdConsultants.map((consultant) => ({
          name: `${consultant.nombre} ${consultant.apellidos}`,
          mail: consultant.user?.email || null,
        }))
      );
    });
    // Función para eliminar duplicados en un array de objetos por name y mail
    const removeDuplicates = (arr) => {
      return arr.filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (obj) => obj.name === value.name && obj.mail === value.mail
          )
      );
    };

    // Eliminar duplicados en cada array
    tecnicos = removeDuplicates(tecnicos);
    cae = removeDuplicates(cae);
    lopd = removeDuplicates(lopd);

    setTecniCaeLopd({
      tecnicos,
      cae,
      lopd,
    });
  };

  const handleMenuItemClick = (email) => {
    if (email) {
      window.location = `mailto:${email}`;
    }
  };

  const handleChange = (e) => {
    const selectedValue = e.target.value;

    // Reinicia todos los valores en `busqueda` a "" excepto el actual
    setBusqueda((prevState) => ({
      ...Object.keys(prevState).reduce((acc, key) => {
        acc[key] = ""; // Resetea todos los valores a ""
        return acc;
      }, {}),
      [e.target.name]: selectedValue, // Solo el seleccionado mantiene su valor
    }));

    // Ejecuta mailto aunque el usuario seleccione el mismo valor
    if (selectedValue) {
      window.location = `mailto:${selectedValue}`;
    }
  };

  return tecniCaeLopd ? (
    <>
      <FormControl variant="outlined" className="filtros-select">
        <InputLabel
          sx={{
            color: "#fff", // Color por defecto
            "&.Mui-focused": {
              color: "#000", // Color negro cuando el Select está enfocado
            },
            "&.MuiInputLabel-shrink": {
              color: "#000", // Color negro cuando hay un valor seleccionado
            },
          }}
          id="municipio-label1"
        >
          @ Técnico
        </InputLabel>
        <Select
          labelId="municipio-label1"
          id="municipio-select1"
          label="@ Contacto Técnico"
          name="tecnicos"
          size="small"
          className="filtros"
          value={busqueda?.tecnicos || ""}
          onChange={handleChange}
          sx={{
            backgroundColor: "#92004D",
            color: "white",
            "& .MuiSelect-icon": {
              color: "#fff", // Asegurarse de que el ícono tenga el color deseado
            },
          }}
        >
          <MenuItem value="" style={{ height: 40 }}></MenuItem>
          {tecniCaeLopd.tecnicos &&
            tecniCaeLopd.tecnicos.map((e, i) => {
              return (
                <MenuItem
                  key={i}
                  value={e.mail}
                  onClick={() => handleMenuItemClick(e.mail)}
                >
                  {e.name}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>

      <FormControl variant="outlined" className="filtros-select">
        <InputLabel
          id="municipio-label2"
          sx={{
            color: "#fff", // Color por defecto
            "&.Mui-focused": {
              color: "#000", // Color negro cuando el Select está enfocado
            },
            "&.MuiInputLabel-shrink": {
              color: "#000", // Color negro cuando hay un valor seleccionado
            },
          }}
        >
          @ Consultor Cae
        </InputLabel>
        <Select
          labelId="municipio-label2"
          id="municipio-select2"
          label="@Contacto Consultor Cae"
          name="cae"
          size="small"
          className="filtros"
          value={busqueda?.cae || ""}
          onChange={handleChange}
          sx={{
            backgroundColor: "#92004D",
            color: "white",
            "& .MuiSelect-icon": {
              color: "#fff", // Asegurarse de que el ícono tenga el color deseado
            },
          }}
        >
          <MenuItem value="" style={{ height: 40 }}></MenuItem>
          {tecniCaeLopd.cae &&
            tecniCaeLopd.cae.map((e, i) => {
              return (
                <MenuItem
                  key={i}
                  value={e.mail}
                  onClick={() => handleMenuItemClick(e.mail)}
                >
                  {e.name}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>

      <FormControl variant="outlined" className="filtros-select">
        <InputLabel
          id="municipio-label3"
          sx={{
            color: "#fff", // Color por defecto
            "&.Mui-focused": {
              color: "#000", // Color negro cuando el Select está enfocado
            },
            "&.MuiInputLabel-shrink": {
              color: "#000", // Color negro cuando hay un valor seleccionado
            },
          }}
        >
          @ Consultor LOPD
        </InputLabel>
        <Select
          labelId="municipio-label3"
          id="municipio-select3"
          label="@ Contacto Consultor Lopd"
          name="lopd"
          size="small"
          className="filtros"
          value={busqueda?.lopd || ""}
          onChange={handleChange}
          sx={{
            backgroundColor: "#92004D",
            color: "white",
            "& .MuiSelect-icon": {
              color: "#fff", // Asegurarse de que el ícono tenga el color deseado
            },
          }}
        >
          <MenuItem value="" style={{ height: 40 }}></MenuItem>
          {tecniCaeLopd.lopd &&
            tecniCaeLopd.lopd.map((e, i) => {
              return (
                <MenuItem
                  key={i}
                  value={e.mail}
                  onClick={() => handleMenuItemClick(e.mail)}
                >
                  {e.name}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </>
  ) : (
    <CircularProgress />
  );
}
