import React, { useState, useEffect, useContext } from 'react'
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { DataTable } from "..";
import Tick from "../../icons/tick.svg";
import Cross from "../../icons/cross.svg";

import Pending from "../../icons/pending.svg";
import { getAllConsultores, getAllSuppDocs, suppsWithDocs, validateNoDocSuppAlert, validateWithDocSuppAlert } from "../../services";
import {
  daysDiff,
  formatoFechasEs,
} from "../../functions/funciones";
import {
  Box,
  Button,
  Tooltip,
  Typography,
} from "@mui/material";
import FiltrosAlertasProv from "../filtros/filtrosAlertasProv";
import { FaCheck } from "react-icons/fa";
//import ModalDocsSupp from "./modalDocsSupp";
import { UsuarioContext } from '../../providers/userContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { construirURLParamsFiltros, useRecogerURLParamsFiltros } from '../../functions/parametrosURL/funcionesParamsURL';

const WithDocumentsAlert = ({ valorTab }) => {


  const user = useContext(UsuarioContext)
  const [Consultores, setConsultores] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [proveedor, setProveedor] = useState({});
  const { filtrosIniciales, filtroOrder } = useRecogerURLParamsFiltros();
  const [busqueda, setBusqueda] = useState(filtrosIniciales);
  const [loading, setLoading] = useState(true);
  const [recargar, setRecargar] = useState(false);
  const [busquedaActiva, setBusquedaActiva] = useState(false);
  const [validating, setValidating] = useState(false);
  const [clearFilterActivo, setClearFilterActivo] = useState(false);
  
  
 
  // const [modalEditarOpened, setModalEditarOpened] = useState(false);
  const [data, setData] = useState({
    meta: {},
    rows: [],
  });


  useEffect(() => {
    getAllConsultores().then((res) => {
      setConsultores(res);
    });
  }, []);

  const [primeraCarga, setPrimeraCarga] = useState(true);

  const navigate = useNavigate();

  const clearFilters = () => {
    if (user.rol === "CONSUL" && localStorage.getItem("hasReloaded") === "true") {
      setBusqueda({
        ...busqueda,
        consul_id: user.id,
      });
    } else {
      setBusqueda({});
    }

    setClearFilterActivo(true);
}


  const fetchData = async ({ offset, limit, filtros = busqueda, order, orderBy } = {}) => {


    orderBy = orderBy || filtroOrder.ordenadoPor || 'ultimaReclamacion';
    order = order || filtroOrder.orden || 'ASC';

    if (primeraCarga) {
      if (filtros.consul_id === undefined && user.rol === 'CONSUL') {
        filtros.consul_id = user.id
      } else if (filtros.consul_id === 0) {
        delete filtros.consul_id
      }
      setPrimeraCarga(false);
    }

    construirURLParamsFiltros(navigate, offset || 1, limit || 50, filtros, order, orderBy, valorTab);

    const payload = {
      filtros,
      params: { page: offset, limit, order, orderBy },
    };

    try {
      setLoading(true);
      const response1 = await getAllSuppDocs(payload);
      const { meta, data } = response1;
      setProveedores(data);
      setData({
        meta,
        rows: formatData(data),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const formatData = (data) => {

    return data.map((row, i) => ({
      
      proveedor_id: row.id,
      razonSocial: row.razonSocial,
      esCreatedAt: !row.ultimaReclamacion,
      ultimaReclamacion: row.ultimaReclamacion || row.createdAt,
      // ultimaReclamacion: row.ultimaReclamacion || row.createdAt,
      nif: row.nif,
      planificacionPreventiva: row.docssupps?.find((d) => d.tipoDoc === 4)?.validado || '',
      planificacionPreventivaUR: row.docssupps?.find((d) => d.tipoDoc === 4)?.ultimaReclamacion || '',
      evaluacionRiesgos: row.docssupps?.find((d) => d.tipoDoc === 5)?.validado || '',
      evaluacionRiesgosUR: row.docssupps?.find((d) => d.tipoDoc === 5)?.ultimaReclamacion || '',
      vigilancia: row.docssupps?.find((d) => d.tipoDoc === 7)?.fechaCaducidad || '',
      vigilanciaUR: row.docssupps?.find((d) => d.tipoDoc === 7)?.ultimaReclamacion || '',
      certificado: row.docssupps?.find((d) => d.tipoDoc === 6)?.fechaCaducidad || '',
      certificadoUR: row.docssupps?.find((d) => d.tipoDoc === 6)?.ultimaReclamacion || '',
      justificante: row.docssupps?.find((d) => d.tipoDoc === 3)?.fechaCaducidad || '',
      justificanteUR: row.docssupps?.find((d) => d.tipoDoc === 3)?.ultimaReclamacion || '',
      seguro: row.docssupps?.find((d) => d.tipoDoc === 2)?.fechaCaducidad || '',
      seguroUR: row.docssupps?.find((d) => d.tipoDoc === 2)?.ultimaReclamacion || '',
      validar: row.id,
      noProcede: row.noProcede
    }));
  };

  const proveedoresRows = [
    
    {
      id: "razonSocial",
      numeric: false,
      disablePadding: false,
      label: "PROVEEDOR",
      overrideFunc: (data, row) => <Link to={"/proveedores/" + row.nif}>{data}</Link>
    },
    {
      id: "ultimaReclamacion",
      numeric: false,
      disablePadding: false,
      label: "ÚLTIMA RECLAMACIÓN",
      // overrideFunc: (data, row) => <span>{isNaN ? formatoFechasEs(data) : "-"}</span>
      
      overrideFunc: (data, row) => <span style={{color: daysDiff(new Date(data), new Date()) > 30 ? 'red' : 'black'}}>{formatoFechasEs(data)}</span>
    },
    {
      id: "nif",
      numeric: false,
      disablePadding: false,
      label: "NIF",
    },
    {
      id: "planificacionPreventiva",
      numeric: false,
      disablePadding: false,
      label: "PLANIFICACIÓN PREVENTIVA",
      noObjeto: true,
      overrideFunc: (data, row) => (
        row.noProcede ? "No procede" :
          <Tooltip title={row.planificacionPreventivaUR === '' ? 'última reclamación: -' : "última reclamación: " + formatoFechasEs(row.planificacionPreventivaUR)}>
            <img style={{ width: "20px" }} src={daysDiff(new Date(row.planificacionPreventivaUR), new Date()) < 60 ? Pending : !data ? Cross : Tick} alt="vacio" />
          </Tooltip>
      )

    },
    {
      id: "evaluacionRiesgos",
      numeric: false,
      disablePadding: false,
      label: "EVALUACIÓN RIESGOS",
      noObjeto: true,
      overrideFunc: (data, row) => (
        <Tooltip title={row.evaluacionRiesgosUR === '' ? 'última reclamación: -' : "última reclamación: " + formatoFechasEs(row.evaluacionRiesgosUR)}>
          <img style={{ width: "20px" }} src={daysDiff(new Date(row.evaluacionRiesgosUR), new Date()) < 60 ? Pending : !data ? Cross : Tick} alt="vacio" />
        </Tooltip>
      )
    },
    {
      id: "vigilancia",
      numeric: false,
      disablePadding: false,
      label: "Vigilancia salud",
      noObjeto: true,
      overrideFunc: (data, row) => (
        row.noProcede ? 'No procede' :
          data ?
            <Tooltip title={row?.vigilanciaUR === '' ? 'última reclamación: -' : "última reclamación: " + formatoFechasEs(row?.vigilanciaUR)}>
              <span style={{ color: daysDiff(new Date(row?.vigilanciaUR), new Date()) < 60 ? 'orange' : daysDiff(new Date(data), new Date()) >= 0 ? 'red' : 'black' }}>
                {formatoFechasEs(data)}
              </span>
            </Tooltip> : '-'
      )
    },
    {
      id: "certificado",
      numeric: false,
      disablePadding: false,
      label: "Certificado Seg. Social",
      noObjeto: true,
      overrideFunc: (data, row) => (
        data ?
          <Tooltip title={row.certificadoUR === '' ? 'última reclamación: -' : "última reclamación: " + formatoFechasEs(row.certificadoUR)}>
            <span style={{ color: daysDiff(new Date(row.certificadoUR), new Date()) < 60 ? 'orange' : daysDiff(new Date(data), new Date()) >= 0 ? 'red' : 'black' }}>
              {formatoFechasEs(data)}
            </span>
          </Tooltip> : '-'
      )
    },
    {
      id: "justificante",
      numeric: false,
      disablePadding: false,
      label: "Justificante pago SPA",
      noObjeto: true,
      overrideFunc: (data, row) => (
        row.noProcede ? 'No procede' :
          data ?
            <Tooltip title={row.justificanteUR === '' ? 'última reclamación: -' : "última reclamación: " + formatoFechasEs(row.justificanteUR)}>
              <span style={{ color: daysDiff(new Date(row.justificanteUR), new Date()) < 60 ? 'orange' : daysDiff(new Date(data), new Date()) >= 0 ? 'red' : 'black' }}>
                {formatoFechasEs(data)}
              </span>
            </Tooltip> : '-'
      )
    },
    {
      id: "seguro",
      numeric: false,
      disablePadding: false,
      label: "Seguro Resp. Civil",
      noObjeto: true,
      overrideFunc: (data, row) => (
        data ?
          <Tooltip title={row.seguroUR === '' ? 'última reclamación: -' : "última reclamación: " + formatoFechasEs(row.seguroUR)}>
            <span style={{ color: daysDiff(new Date(row.seguroUR), new Date()) < 60 ? 'orange' : daysDiff(new Date(data), new Date()) >= 0 ? 'red' : 'black' }}>
              {formatoFechasEs(data)}
            </span>
          </Tooltip> : '-'
      )
    },
    {
      id: "validar",
      numeric: false,
      disablePadding: false,
      label: "Realizada",
      noObjeto: true,
      overrideFunc: (data, row) => (
        // <>
        //   <Button style={{ background: validating ? '#7bc977' : 'green', color: 'white', minWidth: '5px' }} onClick={() => validarSupp(data)} disabled={validating}><FaCheck /></Button>
        // </>
        <>
          <Button style={{ background: validating ? '#7bc977' : 'green', color: 'white', minWidth: '5px' }} onClick={() => validarAllSupp(data)} disabled={validating}><FaCheck /></Button>
        </>
      )
    },
  ];

  const handleCallback = () => {
    setRecargar(true)
//     const params = new URLSearchParams(location.search);
//     const pagina = getQueryParam('pagina');
//     const limite = getQueryParam('limite');
//     // Cambiar el parámetro 'pagina' al nuevo valor
//     // params.set('pagina', pagina);
//     // params.set('limite', limite);
// console.log(params.toString())
//     // Navegar a la misma ruta con los nuevos parámetros
//     navigate(`/alertas-proveedores?${params.toString()}`);
  };

  const validarAllSupp = async (id) => {
    try {
      let proveedor = proveedores.find(p => p.id === id)
      const todasRutasVacias = proveedor.docssupps.every(doc => !doc.ruta);
      if(todasRutasVacias){
        validarAlerta(id).then((resultado) => {
          handleCallback(resultado)
        })
      }else{
        validarSupp(id).then((resultado) => {
          handleCallback(resultado)
        })
      }

    } catch (error) {
      console.log(error)
      toast.error("Ha ocurrido un error");
    }
  }

  const validarSupp = async (id) => {

    // Validar documentos en bloque
    return new Promise(async (resolve, reject) => {
      try {
        const toastInfo = toast.info("Validando...", { autoClose: false });
        let proveedor = proveedores.find(p => p.id === id)
        console.log(proveedor)
        const elementosSeleccionados = proveedor.docssupps.map((d) => d.id)
        console.log(elementosSeleccionados)
        validateWithDocSuppAlert(proveedor.id, elementosSeleccionados).then(() => {
          toast.update(toastInfo, {
            render: "Alerta validada",
            type: toast.TYPE.SUCCESS,
            autoClose: true,
          });
          resolve(true);
        }).catch((error) => {
          console.log(error)
          toast.update(toastInfo, {
            render: "No se ha podido validar la alerta",
            type: toast.TYPE.ERROR,
            autoClose: true,
          });
          reject(error)
        })
        setProveedor(proveedor)
      } catch (error) {
        console.log(error)
        toast.error("Ha ocurrido un error");
        reject(error)
      }
    })

    //Modal para validar documentos individualmente
    
    // let proveedor = proveedores.find(p => p.id === id)
    // setModalEditarOpened(true)  
    //setProveedor(proveedor)
  }
  const validarAlerta = async (id) => {
    setValidating(true);
    try {
      const toastInfo = toast.info("Validando...", { autoClose: false });
      const response = await validateNoDocSuppAlert(id)
      if (response.status === 201) {
        toast.update(toastInfo, {
          render: "Alerta validada",
          type: toast.TYPE.SUCCESS,
          autoClose: true,
        });
        // setRecargar(true)
        setLoading(true);
        fetchData();
      }
      if (response.status === 403) {
        toast.update(toastInfo, {
          render: "No se ha podido validar la alerta",
          type: toast.TYPE.ERROR,
          autoClose: true,
        });
      }
    } catch (error) {
      console.error(error);
      toast.error("Error al validar la alerta");
    } finally {
      setValidating(false);
    }
  }

  const filtrarDatos = (filtros) => {
    setBusqueda(filtros);
    setBusquedaActiva(true);
  }

  return (
    <>
      {/* <ModalDocsSupp
        proveedor={proveedor}
        opened={modalEditarOpened}
        handleClose={() => {
          setModalEditarOpened(false);
          setRecargar(!recargar);
        }}
      /> */}
      <div className="listPage">
        <div className="gridCard">
          <div className="tableContainer">
            <FiltrosAlertasProv onFilter={filtrarDatos} clearFilter={clearFilters} filtrosIniciales={busqueda} />
            <div className="tableWrapper">
              <Box className="pageContent">
                <Box sx={{ mb: 10 }}>
                  <DataTable
                    data={data.rows}
                    isLoading={loading}
                    headers={proveedoresRows}
                    fetcher={fetchData}
                    meta={data.meta}
                    filtros={busqueda}
                    busquedaActiva={busquedaActiva}
                    setBusquedaActiva={setBusquedaActiva}
                    clearFilterActivo={clearFilterActivo}
                    mensaje={"No hay proveedores"}
                    segundaCol={true}
                    enumerate={true}
                    recargar={recargar}
                  />
                </Box>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WithDocumentsAlert;