import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import { withRouter } from "../../../providers/withRouter";
import { UsuarioContext } from "../../../providers/userContext";
import { DataTable } from "../../../components";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import { IoSave, IoDocumentText } from "react-icons/io5";
import { FaEye } from "react-icons/fa";
import { filterAAFFs, getAAFFs } from "../../../services";
import { BsDownload, BsUpload, BsSearch } from "react-icons/bs";
import FiltrosAAFF from "../../../components/filtros/filtrosAAFF";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useRecogerURLParamsFiltros,
  construirURLParamsFiltros,
} from "./../../../functions/parametrosURL/funcionesParamsURL";
import VerRiesgos from "../../../routes/pages/admin/VerRiesgos";

const comunidadesRows = [
  {
    id: "razonSocial",
    numeric: false,
    disablePadding: false,
    label: "ADMINISTRADOR",
    overrideFunc: (data, row) => (
      <Link to={"/aaff/" + row.nif + "/comunidades"}>{data}</Link>
    ),
  },
  {
    id: "nif",
    numeric: false,
    disablePadding: false,
    label: "NIF",
  },
  {
    id: "informeComunidades",
    numeric: false,
    disablePadding: false,
    label: "INFORME COMUNIDADES",
    noObjeto: true,
    overrideFunc: (row) => (
      <Link
        to={`/doc-render/${row}/situacion-comunidades`}
        target={"_blank"}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100px",
          height: "40px",
          textDecoration: "none",
        }}
      >
        <div className="bigBtn">
          <IoDocumentText alt="Informe comunidades" />
          <span style={{ marginLeft: 15 }}>Generar</span>
        </div>
      </Link>
    ),
  },
  {
    id: "informeComunidadesSinProveedores",
    numeric: false,
    disablePadding: false,
    label: "INFORME CP SIN PROVEEDORES",
    noObjeto: true,
    overrideFunc: (row) => (
      <Link
        to={`/doc-render/${row}/comunidades-sin-proveedores`}
        target={"_blank"}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100px",
          height: "40px",
          textDecoration: "none",
        }}
      >
        <div className="bigBtn" style={{ minWidth: 100 }}>
          <IoDocumentText alt="Informe comunidades sin proveedores" />
          <span style={{ marginLeft: 5 }}>Generar</span>
        </div>
      </Link>
    ),
  },
  {
    id: "informeProveedores",
    numeric: false,
    disablePadding: false,
    label: "INFORME PROVEEDORES",
    noObjeto: true,
    overrideFunc: (row) => (
      <Link
        to={`/doc-render/${row}/situacion-proveedores`}
        target={"_blank"}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100px",
          height: "40px",
          textDecoration: "none",
        }}
      >
        <div className="bigBtn" style={{ minWidth: 100 }}>
          <IoDocumentText alt="Informe proveedores" />
          <span style={{ marginLeft: 5 }}>Generar</span>
        </div>
      </Link>
    ),
  },
  {
    id: "informeRiesgos",
    numeric: false,
    disablePadding: false,
    label: "INFORME RIESGOS",
    noObjeto: true,
    overrideFunc: (row) => (
      <div style={{ display: "flex", justifyContent: "center", gap: 10 }}>
        <Link
          to={`/doc-render/${row}/situacion-riesgos`}
          target={"_blank"}
          style={{
            display: "flex", // Asegura que ocupe el tamaño completo
            alignItems: "center",
            justifyContent: "center",
            width: "40px", // Ajusta el ancho del link
            height: "40px", // Ajusta la altura del link
            textDecoration: "none", // Elimina el subrayado del texto
          }}
        >
          <div className="bigBtn" style={{ minWidth: 40 }}>
            <IoDocumentText alt="Informe riesgos" />
          </div>
        </Link>
        <Link
          to={`/informe-riesgos/${row}`}
          target={"_blank"}
          style={{
            display: "flex", // Asegura que ocupe el tamaño completo
            alignItems: "center",
            justifyContent: "center",
            width: "40px", // Ajusta el ancho del link
            height: "40px", // Ajusta la altura del link
            textDecoration: "none", // Elimina el subrayado del texto
          }}
        >
          <div
            className="bigBtn"
            style={{
              minWidth: 40,
              padding: "10px",
              textAlign: "center",
            }}
          >
            <span>Web</span>
          </div>
        </Link>
      </div>
    ),
  },
];

const AAFFInformes = () => {
  const user = useContext(UsuarioContext);
  const { filtrosIniciales, filtroOrder } = useRecogerURLParamsFiltros();
  const [busqueda, setBusqueda] = useState(filtrosIniciales);
  const [recargar, setRecargar] = useState(false);
  const [busquedaActiva, setBusquedaActiva] = useState(false);
  const [loading, setLoading] = useState(true);
  const [clearFilterActivo, setClearFilterActivo] = useState(false);
  const [data, setData] = useState({
    meta: {},
    rows: [],
  });
  const [primeraCarga, setPrimeraCarga] = useState(true);

  useEffect(() => {
    setRecargar(false);
  }, [recargar]);

  const navigate = useNavigate();
  const location = useLocation();

  const clearFilters = () => {
    if (
      user.rol === "CONSUL" &&
      localStorage.getItem("hasReloaded") === "true"
    ) {
      setBusqueda({
        ...busqueda,
        consul_id: user.id,
      });
    } else {
      setBusqueda({});
    }

    setClearFilterActivo(true);
  };

  const fetchData = async ({
    offset,
    limit,
    filtros = busqueda,
    order,
    orderBy,
  } = {}) => {
    orderBy = orderBy || filtroOrder.ordenadoPor || "id";
    order = order || filtroOrder.orden || "ASC";

    if (primeraCarga) {
      if (filtros.consul_id === undefined && user.rol === "CONSUL") {
        filtros.consul_id = user.id;
      } else if (filtros.consul_id === "0") {
        delete filtros.consul_id;
      }
      setPrimeraCarga(false);
    }

    construirURLParamsFiltros(
      navigate,
      offset || 1,
      limit || 50,
      filtros,
      order,
      orderBy
    );

    const payload = {
      filtros: { ...filtros },
      params: { page: offset, limit, order, orderBy },
    };

    try {
      setLoading(true);
      const response = await filterAAFFs(payload);

      const { meta, data } = response;
      setData({
        meta,
        rows: formatData(data),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const formatData = (data) => {
    return data.map((row) => ({
      razonSocial: row.razonSocial,
      nif: row.nif,
      informeComunidades: row.nif,
      informeComunidadesSinProveedores: row.nif,
      informeProveedores: row.nif,
      informeRiesgos: row.nif,
    }));
  };

  /* function handleChange(evt) {
    const value = evt.target.value;
    setBusqueda({
      ...busqueda,
      [evt.target.name]: value,
    });
  }

  const getBusqueda = () => {
    for (let key in busqueda) {
      if (
        busqueda[key] === "" ||
        busqueda[key] === null ||
        busqueda[key] === undefined
      ) {
        delete busqueda[key];
      }
    }
    fetchData({ offset: 1, limit: limite, filtros: busqueda });
  }; */

  const filtrarDatos = (filtros) => {
    setBusqueda(filtros);
    setBusquedaActiva(true);
  };

  return (
    <>
      <BreadCrumb
        rutas={[{ path: "/aaff-informes", label: "Informes" }]}
        style={{ margin: "0 10px" }}
      />
      <div className="listPage">
        <div className="gridCard">
          <div className="gridHead">INFORMES</div>
        </div>
      </div>
      <div className="pageContent tableContainer">
        <FiltrosAAFF
          onFilter={filtrarDatos}
          clearFilter={clearFilters}
          filtrosIniciales={busqueda}
        />

        <div className="tableWrapper">
          <Box className="pageContent">
            <Box sx={{ mb: 10 }}>
              <DataTable
                data={data.rows}
                isLoading={loading}
                headers={comunidadesRows}
                fetcher={fetchData}
                currentPage={data.current_page}
                meta={data.meta}
                totalItems={data.total}
                totalPages={data.totalPages}
                nextPage={data.next_page}
                filtros={busqueda}
                busquedaActiva={busquedaActiva}
                setBusquedaActiva={setBusquedaActiva}
                clearFilterActivo={clearFilterActivo}
                setClearFilterActivo={setClearFilterActivo}
                mensaje={"No hay administradores de fincas"}
              />
            </Box>
          </Box>
        </div>
      </div>
      <style jsx="true">{`
        .bigBtn {
          min-width: 95px;
        }
      `}</style>
    </>
  );
};

export default withRouter(AAFFInformes);
