import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "../../../providers/withRouter";
import { Link, useNavigate } from "react-router-dom";
import {
  deleteAAFF,
  getSalesPropsIds,
  sendEmailBienvenida,
  getFincasAAFF,
  getOneAAFF
} from "../../../services";
import Swal from 'sweetalert2';
import { toast } from "react-toastify";
import Delete from "../../../icons/delete.svg";
import Editar from "../../../icons/editar.svg";
import ModalEditar from "./editarDatosAAFF";
import { cambiarFormatoNombre, formatoFechasEs } from "./../../../functions/funciones";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import { TiArrowBack } from "react-icons/ti";
import ListadoComunidades from "./ListadoComunidades";
import { subirListado, eliminarFincas } from "../../../functions/documentacion";
import Chart from "../../../components/Charts/apexCharts";
import { UsuarioContext } from "../../../providers/userContext";
import ObservacionesTecnico from "../../../components/aaff/observacionesTecnico";
import Comentarios from "../../../components/aaff/comentarios";
import { CircularProgress } from '@mui/material';
import Excel from "../../../icons/excel.svg"
import ExportarListaFincasAAFF from "./ExportarListaFincasAAFF";

const Comunidades = (props) => {
  const navigate = useNavigate();
  const user = useContext(UsuarioContext)
  const [aaff, setAAFF] = useState({});
  const [comentarios, setComentarios] = useState();
  const [modalEditarOpened, setModalEditarOpened] = useState(false);
  const [recargar, setRecargar] = useState(0);
  const [isLoading, setIsLoading] = useState(true)
  const [show, setShow] = useState(false)
  const [propertiesCae, setPropertiesCae] = useState([])
  const [fincas,setFincas] =useState();



  useEffect(() => {
    fetchData()
    // setRecargar(false);
  }, [recargar]);

  const fetchData = async ({ offset, limit, filtros } = {}) => {
    //Asier
    const responseAaffs = await getOneAAFF(props.params.nif) || {}
    console.log(props.params.nif)
    
    setAAFF(responseAaffs);
    setComentarios(responseAaffs.comments);
    setIsLoading(false);
    const ids = responseAaffs ? await getSalesPropsIds(responseAaffs?.id) : []
    const payload = {
      filtros:{...filtros, aaff_id: responseAaffs.id},
      params:{page: offset, limit}
    }

    const response = await getFincasAAFF(payload);

    setFincas(response);
   
  
    const resultado = response?.data?.filter(item => ids.includes(item.id) );

    

    setPropertiesCae(resultado)

  }

 

  function eliminarAAFF() {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await Swal.fire({
          title: "¿Quieres eliminar el AAFF?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#888",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Eliminar"
        });

        if (result.isConfirmed) {
          const toastInfo = toast.info("Eliminando AAFF", { autoClose: false });
          await deleteAAFF(aaff.nif).then((res) => {
            if (res.data.message === "AAFF_DELETED") {
              toast.update(toastInfo, {
                render: "Eliminado correctamente",
                type: toast.TYPE.SUCCESS,
                autoClose: true
              });
              resolve(true);
              navigate("/aaff")
            } else {
              toast.update(toastInfo, {
                render: "Error al eliminar",
                type: toast.TYPE.ERROR,
                autoClose: true
              });
            }
          }).catch(error => {
            console.log(error);
            toast.update(toastInfo, {
              render: "Error al eliminar",
              type: toast.TYPE.ERROR,
              autoClose: true
            });
            reject(error);
          });
        } else {
          // Si el usuario cancela la eliminación
          resolve(false);
        }
      } catch (error) {
        console.error("Error al eliminar el aaff:", error);
        reject(error);
      }
    });

  }

  const handleCallback = () => {
    setRecargar(!recargar);
  };

  const deleteFincas = async () =>  {
    eliminarFincas(aaff).then((resultado) => {
      handleCallback(resultado)
    })
  }

  function subirFincas(evt) {
    subirListado(evt).then(() => {
      setRecargar(!recargar)
    })
  }

  const handleSendEmail = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await Swal.fire({
          title: "¿Quieres enviar el email de acceso a la plataforma?",
          text: "este email envía el acceso a crear nuevas claves",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#33dd75",
          cancelButtonColor: "#888",
          confirmButtonText: "Enviar",
          cancelButtonText: "Cancelar",
        });

        if (result.isConfirmed) {
          const toastInfo = toast.info("Enviando email de acceso a AAFF", { autoClose: false });
          await sendEmailBienvenida(user.id, props.params.nif, aaff.razonSocial, aaff.user.email).then((res) => {
            if (res.status === 201) {
              toast.update(toastInfo, {
                render: "Enviado correctamente",
                type: toast.TYPE.SUCCESS,
                autoClose: true
              });
              resolve(true);
            } else {
              toast.update(toastInfo, {
                render: "Error al enviar email",
                type: toast.TYPE.ERROR,
                autoClose: true
              });
            }
          }).catch(error => {
            console.log(error);
            toast.update(toastInfo, {
              render: "Error al enviar email",
              type: toast.TYPE.ERROR,
              autoClose: true
            });
            reject(error);
          });
        } else {
          // Si el usuario cancela la eliminación
          resolve(false);
        }
      } catch (error) {
        console.error("Error al enviar email:", error);
        reject(error);
      }
    })

  }

  return isLoading ? <CircularProgress style={{ color: '#92004D', position: 'absolute', top: '50%', left: '50%' }} /> :
    <>
    {console.log(aaff)}
      <ModalEditar
        aaff={aaff}
        opened={modalEditarOpened}
        handleClose={() => {
          setModalEditarOpened(false);
          setRecargar(!recargar);
        }}
      />
      <iframe title="pdf" id="iFramePdf" style={{ display: "none" }}></iframe>
      {/* <div
    className="pageHeader"
    style={{
      backgroundImage: `url(${HeaderBackground})`,
      textAlign: "left",
    }}
  >
    Administrador / <b>{aaff.razonSocial}</b>
  </div> */}
      <BreadCrumb rutas={[{ path: '/aaff', label: `Administradores de fincas` }, { path: '/aaff', label: `${aaff.razonSocial}` }]} style={{ margin: '0 10px' }} />
      <div className="listPage">
        <div className="gridCard">
          <div className="gridHead">
            INFORMACIÓN GENERAL
            <Link to="/aaff">
              <div className="gridHeadRight">

                <TiArrowBack style={{ fontSize: 23 }} />
              </div>
            </Link>
          </div>
          <div className="gridSubhead editarSubhead">
            <div>DATOS EMPRESA</div>
            <div className="btnsSubhead">
              <div className="editarBtn" onClick={() => setModalEditarOpened(true)} >
                <span style={{ marginRight: 10 }}>Editar</span>{" "}
                <img src={Editar} alt="Editar" />
              </div>
              <div className="editarBtn" onClick={eliminarAAFF}>
                <span style={{ marginRight: 10 }}>Eliminar</span>{" "}
                <img src={Delete} alt="Eliminar" />
              </div>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <div className="datosGrid" style={{ width: '75%' }}>
              <p>
                <b>Nombre AAFF:</b> {aaff.razonSocial}
              </p>
              <p>
                <b>NIF:</b> {aaff.nif}
              </p>
              <p>
                <b>Municipio:</b> {cambiarFormatoNombre(aaff.municipio)}
              </p>
              <p>
                <b>Provincia:</b> {aaff.provincia}
              </p>
              <p>
                <b>Código postal:</b>  {aaff.codigoPostal}
              </p>
              <p>
                <b>Persona de contacto:</b> {aaff.nombreContacto}
              </p>
              <p>
                <b>Teléfono:</b> {aaff.telefono}
              </p>
              <p>
                <b>Email:</b> {aaff.user?.email}
              </p>
              {aaff?.gruposServicios?.includes(1) &&
                <p>
                  <b>Consultor Cae:</b>{" "}
                  {aaff.consultants?.find(item => item.r_consul_servsgroups_aaffs.group_id === 1) ? `${aaff.consultants?.find(item => item.r_consul_servsgroups_aaffs.group_id === 1).nombre} ${aaff.consultants.find(item => item.r_consul_servsgroups_aaffs.group_id === 1).apellidos}` : ''}
                  {/* {aaff.r_consul_servsgroups_aaff?.consultant} */}
                  {/* {aaff.consultant?.nombre !== undefined
                ? aaff.consultant?.nombre + " " + aaff.consultant?.apellidos
                : "-"}{" "} */}
                </p>
              }
              {aaff?.gruposServicios?.includes(2) &&
                <p>
                  <b>Consultor Lopd:</b>{" "}
                  {aaff.consultants?.find(item => item.r_consul_servsgroups_aaffs.group_id === 2) ? `${aaff.consultants.find(item => item.r_consul_servsgroups_aaffs.group_id === 2).nombre} ${aaff.consultants.find(item => item.r_consul_servsgroups_aaffs.group_id === 2).apellidos}` : ''}
                  {/* {aaff.r_consul_servsgroups_aaff?.consultant} */}
                  {/* {aaff.consultant?.nombre !== undefined
                ? aaff.consultant?.nombre + " " + aaff.consultant?.apellidos
                : "-"}{" "} */}
                </p>
              }
              <p>
                <b>Técnico:</b>{" "}
                {aaff.technician?.nombre !== undefined
                  ? aaff.technician?.nombre + " " + aaff.technician?.apellidos
                  : "-"}{" "}
              </p>
              <p>
                <b>Comercial:</b>{" "}
                {aaff.commercial?.nombre !== undefined
                  ? aaff.commercial?.nombre + " " + aaff.commercial?.apellidos
                  : "-"}{" "}
              </p>
              <p>
                <b>Fecha de aviso:</b>{" "}
                {aaff.user?.notifiedAt === undefined || aaff.user?.notifiedAt === null
                  ? null
                  : formatoFechasEs(aaff.user?.notifiedAt)}{" "}
              </p>
              {/* <p>
                <b>Fecha de encargo:</b>{" "}
                {aaff.fechaEncargo === undefined || aaff.fechaEncargo === null
                  ? null
                  : formatoFechasEs(aaff.fechaEncargo)}{" "}
              </p> */}
              <p>
                <b>Fecha de bienvenida:</b>{" "}
                {aaff.fechaBienvenida === undefined ||
                  aaff.fechaBienvenida === null
                  ? null
                  : formatoFechasEs(aaff.fechaBienvenida)}{" "}
              </p>
              <p>
                <b>Fecha de Reclamación proveedores:</b>{" "}
                {aaff.fechaReclamacion === undefined ||
                  aaff.fechaReclamacion === null
                  ? null
                  : formatoFechasEs(aaff.fechaReclamacion)}{" "}
              </p>
              <p>
                <b>Fecha informe proveedores:</b>{" "}
                {aaff.fechaInforme === undefined || aaff.fechaInforme === null
                  ? null
                  : formatoFechasEs(aaff?.fechaInforme)}{" "}
              </p>
            </div>
            
            {fincas?.data != undefined && aaff?.gruposServicios?.includes(1) ? <Chart datos={propertiesCae} /> : null}
          </div>
          <div className="datosGrid">
            <div></div>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <button onClick={() => handleSendEmail()} style={{ margin: '10px 0', borderRadius: '5px 5px', backgroundColor: '#92004D', color: '#ffffff', padding: '5px 10px' }}>
                Enviar email acceso
              </button>
            </div>
          </div>
          <div className="datosGrid">
            <Comentarios aaff={aaff} />
          </div>
          {aaff?.gruposServicios?.includes(1) &&
            <>
              <hr />
              <div className="datosGrid">

                <ObservacionesTecnico aaff={aaff} />
              </div>
            </>
          }
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              alignItems: "center",
              paddingLeft: 10,
              paddingRight: 10,
              margin: 10,
            }}
          >
          </div>

          <div className="gridSubhead editarSubhead">
            <div>LISTADO COMUNIDADES</div>
            <div className="subheadBtn" onClick={deleteFincas}>
              <span style={{ marginRight: 10 }}>Eliminar fincas</span>{" "}
              <img src={Delete} alt="Eliminar" />
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', paddingLeft: 10, paddingRight: 10, marginTop: 20 }}>
            <div >
              {show ?
                <ExportarListaFincasAAFF aaff_id={aaff.id} fincas={propertiesCae} />
                :
                <button className='bigBtn' onClick={() => setShow(true)}><img src={Excel} alt="Generar listado" width="30px" /> Generar listado</button>
              }
            </div>
          </div>
          <div className="tableContainer">
            <ListadoComunidades  aaff={aaff}/>
          </div>
        </div>
      </div>

    </>
    ;
};

export default withRouter(Comunidades);
