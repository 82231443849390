import React, { useContext, useState } from "react";
import { Box, Typography } from "@mui/material";
import { UsuarioContext } from "../../../providers/userContext";
import { useEffect } from "react";
import { createDeclRes, getProveedor } from "../../../services";
import moment from "moment";
import { Iconify } from "../../../utilities";
import provinces from "@doncicuto/es-provinces";
import municipalities from "@doncicuto/es-municipalities";
import { daysDiff } from "../../../functions/funciones";
import {
  municipiosIniciales,
  cambiarFormatoNombre,
} from "../../../functions/funciones";
import Tick from "../../../icons/tick.svg";
import Cross from "../../../icons/cross.svg";
import Minus from "../../../icons/minus.svg";
import { withRouter } from "../../../providers/withRouter";
import { subirDocumentacionPerfilProveedor } from "../../../providers/documentos";
import { getDocsType } from "../../../services/docsType.service";
import DeclRes from "../../../components/proveedores/declRes";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import Editar from "../../../icons/editar.svg";
import EditarDatosProveedor from "./editarDatosProveedor";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation } from 'react-router-dom';
import FormProveedor from "./FormProveedor";

const Proveedor = () => {
  const user = useContext(UsuarioContext);

  const [info, setInfo] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [recargar, setRecargar] = useState(false);
  const [docsType, setDocsType] = useState([]);
  const [listadoMunicipios, setListadoMunicipios] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  

  const location = useLocation();


  useEffect(() => {
    if (localStorage.getItem("hasReloaded") === "true") {

      fetchData();
      localStorage.setItem("hasReloaded", "false"); 
    }
  }, [location]);

  const fetchData = async () => {
    try {
      const response = await getProveedor(user?.nif);

      const res = await getDocsType()
      
      setDocsType(res)

      setInfo(response);
      setListadoMunicipios(
        municipiosIniciales(provinces, municipalities, response?.provincia)
      );
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenDoc = (route) =>
    window.open(`${process.env.REACT_APP_STORAGE_URL}${route}`, "_blank");

  const handleSubirDocsCallback = () => {
    setRecargar(!recargar);
  };

  const subirArchivo = async (ruta, id, idDoc, rol, nif) => {
   subirDocumentacionPerfilProveedor(ruta, id, idDoc, rol, nif).then((resultado) => {
      handleSubirDocsCallback(resultado)
    })
  };


  useEffect(() => {
    fetchData();
  }, [recargar]);
  
  return isLoading ? <CircularProgress style={{ color: '#92004D', position: 'absolute', top: '50%', left: '50%' }} /> :
    <>

      <BreadCrumb rutas={[{ path: '/proveedor', label: 'Panel principal' }]} />
      <EditarDatosProveedor
        data={info}
        showModal={showModal}
        handleClose={() => {
          setShowModal(false);
          setRecargar(!recargar);
        }}
      />
      {/* <FormProveedor></FormProveedor> */}


      <div className="listPage">
        <div className="gridCard">
          <div
            className="gridHead"
            style={{
              backgroundColor: "#92004D",
              display: "flex",
              flexDirection: " row",
            }}
          >
            <Typography fontWeight="bold">INFORMACION GENERAL</Typography>

          </div>
          <div className="gridSubhead editarSubhead">
            <div>DATOS DE PROVEEDOR:</div>
            <div
              className="editarBtn"
              onClick={() => setShowModal(true)}
            >
              <span style={{ marginRight: 10 }}>Editar</span>{" "}
              <img src={Editar} alt="Editar" />
            </div>
          </div>
        </div>
        <Box sx={{ mb: 2 }}>
          <Box
            className="lae-flex lae-flex-column"
            sx={{
              backgroundColor: "#fff",
              padding: 2,
              mb: 1,
              borderRadius: "0 0 10px 10px",
              boxShadow: "0.1px 2px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Box className="lae-flex lae-flex-row">
              <Box className="lae-flex lae-flex-column" sx={{ flex: 3.3 }}>
                <Typography>Nombre Proveedor: {info?.razonSocial}</Typography>
                <Typography>NIF: {info?.nif}</Typography>
                <Typography>
                  Persona de contacto: {info?.nombreContacto}
                </Typography>
                <Typography>
                  Email de contacto: {info?.emailContacto}
                </Typography>
                <Typography>
                  Telefono de contacto: {info?.telefonoContacto}
                </Typography>
              </Box>
              <Box className="lae-flex lae-flex-column" sx={{ flex: 3.3 }}>
                <Typography>Dirección: {info?.direccion}</Typography>
                <Typography>Municipio: {cambiarFormatoNombre(info?.municipio)}</Typography>
                <Typography>CP: {info?.codigoPostal}</Typography>
                <Typography>Provincia: {info?.provincia}</Typography>
                <Typography>
                  Fecha de alta: {moment(info?.createdAt).format("DD/MM/YYYY")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className="lae-flex lae-flex-row" sx={{ width: "100%" }}>
          <Box sx={{ flex: 1 }}>
            <div className="gridCard">
              <div className="gridHead" style={{ backgroundColor: "#92004D" }}>
                <Typography fontWeight="bold">DOCUMENTACIÓN CAE</Typography>
              </div>
            </div>

            <Box
              className="lae-flex lae-flex-column"
              sx={{
                backgroundColor: "#fff",
                padding: 2,
                mb: 1,
                borderRadius: "0 0 10px 10px",
                boxShadow: "0.1px 2px 10px rgba(0, 0, 0, 0.1)",
                justifyContent: "space-between",
              }}
            >
              <Box className="lae-flex lae-flex-row">
                <Box sx={{ flex: 8 }}>
                  <picture></picture>
                  <Typography></Typography>
                </Box>
                <Box
                  className="lae-flex lae-flex-row"
                  sx={{
                    flex: 2,
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ w: "24px" }}>
                    <Typography>Caducidad</Typography>
                  </Box>
                  <Box sx={{ w: "24px" }}>
                    <Typography>Ver</Typography>
                  </Box>
                  <Typography>Adjuntar</Typography>
                </Box>
              </Box>

              {Object.values(docsType).map((dt, i) => {
                if(dt.id !== 8 && dt.id !== 9){

                  const findDoc = info?.docssupps?.find(
                    (doc) => doc.tipoDoc === dt.id
                  );
  
                  const noProcede = info?.noProcede === true && (docsType[i].id === 3 || docsType[i].id === 4 || docsType[i].id === 7);
                  
                  return (
                    <>
                      {!noProcede && (
                        <Box key={i} className="lae-flex lae-flex-row" sx={{ pr: 0.4 }}>
                          <Box>
                            {findDoc?.validado ? <img src={Tick} width={20} /> : findDoc?.pendiente ? <img src={Minus} width={20} /> : <img src={Cross} width={20} />}
                          </Box>
                          <Box sx={{ flex: 8 }}>
                            <picture></picture>
                            <Typography variant="body2">
                              {docsType[i].nombre}
                            </Typography>
                          </Box>
                          <Box
                            className="lae-flex lae-flex-row"
                            sx={{
                              flex: 2,
                              justifyContent: "space-around",
                              alignItems: "center",
                            }}
                          >
                            <Typography>
                              {findDoc?.fechaCaducidad !==
                                null && findDoc?.status ? (
                                <span
                                  style={{
                                    color:
                                      daysDiff(
                                        new Date(
                                          findDoc?.fechaCaducidad
                                        ),
                                        new Date()
                                      ) > 0
                                        ? "red"
                                        : "black",
                                  }}
                                >
                                  {new Date(
                                    findDoc?.fechaCaducidad
                                  ).toLocaleDateString()}
                                </span>
                              ) : (
                                <span style={{ display: 'block', width: 60, textAlign: 'right' }}>----</span>
                              )}
                            </Typography>
                            <Iconify
                              icon={"solar:eye-line-duotone"}
                              height={24}
                              width={24}
                              style={{
                                textAlign: "center",
                                opacity: findDoc?.ruta === undefined || findDoc?.ruta === null ? 0.4 : 1,
                              }}
                              sx={{
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handleOpenDoc(
                                  findDoc?.ruta
                                )
                              }
                            />
                            <Iconify
                              icon={"material-symbols:upload"}
                              height={24}
                              width={24}
                              sx={{
                                cursor: "pointer",
                              }}
                              onClick={() => subirArchivo(
                                findDoc?.ruta,
                                findDoc?.id,
                                findDoc?.tipoDoc === undefined ? docsType[i].id : findDoc?.tipoDoc,
                                user.rol,
                                info.nif
                              )}
                            />
                          </Box>
                        </Box>
                      )}
                    </>
                  );
                }
              })}
              
            </Box>
          </Box>
          <Box sx={{ flex: 1 }}>
            <DeclRes nifProv={user.nif} proveedor={info} />
          </Box>
        </Box>
        <input type="file" id="fileElem" hidden></input>
      </div>
    </>

};

export default withRouter(Proveedor);
