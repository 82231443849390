import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "../../../providers/withRouter";
import { DataTable, ProgressBarWithColors } from "../../../components";
import { docsPendientesProv, formatoFechasEs } from "../../../functions/funciones";
import Add from "../../../icons/add.svg";
import {
  Box,
  Select,
  Typography,
} from "@mui/material";
import { cambiarProveedor, deleteProveedor, getProveedores } from "../../../services";
import { daysDiff } from "../../../functions/funciones";
import CircularProgress from "@mui/material/CircularProgress";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import FiltrosProveedores from "../../../components/filtros/filtrosProveedores";
import { UsuarioContext } from "../../../providers/userContext";
import Warning from "../../../icons/warning.svg";
import Delete from "../../../icons/delete.svg";
import { FormControl } from "react-bootstrap";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecogerURLParamsFiltros, construirURLParamsFiltros } from './../../../functions/parametrosURL/funcionesParamsURL';
import Swal from "sweetalert2";
import { set } from "date-fns";



const ProveedoresLista = () => {

  const user = useContext(UsuarioContext)
  const [proveedores, setProveedores] = useState([]);
  const {filtrosIniciales, filtroOrder} = useRecogerURLParamsFiltros();
  const [busqueda, setBusqueda] = useState(filtrosIniciales);
  const [loading, setLoading] = useState(true);
  const [recargar, setRecargar] = useState(false);
  const [clearFilterActivo, setClearFilterActivo] = useState(false);
  const [clean, setClean] = useState(false);

  const [busquedaActiva, setBusquedaActiva] = useState(false);
  const [data, setData] = useState({
    meta: {},
    rows: [],
  });

  const [primeraCarga, setPrimeraCarga] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();
  let params = new URLSearchParams(location.search);
  const pagina = parseInt(params.get("pagina")) || 1;
  const limiteParam = parseInt(params.get("limite")) || 50;

  const clearFilters = () => {
    if (user.rol === "CONSUL" && localStorage.getItem("hasReloaded") === "true") {
      setBusqueda({
        consul_id: user.id 
      });
    }
    else if(user.rol === "CONSUL"){
      setBusqueda({
        consul_id: user.id 
      });
    }
    else{
      setBusqueda({});
    }
    
    setClearFilterActivo(true);
  }

  // useEffect(() => {
  //   fetchData();
  // },[recargar])

  const fetchData = async ({ offset, limit, filtros = busqueda, order,orderBy } = {}) => {
    orderBy = orderBy || filtroOrder.ordenadoPor || 'id';
    order = order || filtroOrder.orden || 'ASC';
    if (primeraCarga) {
      if (filtros.consul_id === undefined && user.rol === 'CONSUL') {
        filtros.consul_id = user.id
      } else if (filtros.consul_id === "0") {
        delete filtros.consul_id
      }
      setPrimeraCarga(false);
    }
    // if ( user.rol ==='CONSUL') {

    // } else if (user.rol ==='ADMIN') {
    //     delete filtros.consul_id
    // }


    construirURLParamsFiltros(navigate, offset || 1, limit || 50, filtros, order, orderBy);

    const payload = {
      filtros,
      params: { page: offset, limit, order, orderBy },
    };

    try {
      setLoading(true);
      const response = await getProveedores(payload);
      const { meta, data } = response;
      setData({
        meta,
        rows: formatData(data),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const formatData = (data) => {
    return data.map((row) => ({

      razonSocial: row.razonSocial,
      ultimaReclamacion: row.ultimaReclamacion === null ? row.createdAt : row.ultimaReclamacion,
      nif: row.nif,
      telefonoContacto: row.telefonoContacto,
      emailContacto: row.emailContacto,
      direccion:row.direccion,
      municipio: row.municipio,
      provincia: row.provincia,
      trabajadores: row.noProcede === null ? false : row.noProcede,
      cumplimiento: row.porcentajeDocs,
      docsPendientes: row.hasPendingDocs,
      eliminarProv: <img src={Delete} onClick={() => handleEliminarProv(row.nif)} width={15} alt="eliminar" />,
    }));
  };

  const handleChangeTrabajadores = (evt, nif) => {
    const actualizarEstado = data.rows.map((row) => {
      if (row.nif === nif) {
        cambiarProveedor(nif, {
          noProcede: evt.target.value
        })
        return { ...row, trabajadores: evt.target.value === 'true' };

      }
      return row;
    });
    setData({ ...data, rows: actualizarEstado });
  };

  const proveedoresRows = [

    {
      id: "razonSocial",
      numeric: false,
      disablePadding: false,
      label: "PROVEEDOR",
      overrideFunc: (data, row) =>
      (
        <Link to={"/proveedores/" + row.nif}>
          {data}
        </Link>
      )
    },
    {
      id: "ultimaReclamacion",
      numeric: false,
      disablePadding: false,
      label: "ÚLTIMA RECLAMACIÓN",
      overrideFunc: (data) => data ? formatoFechasEs(data) : '',
    },
    {
      id: "nif",
      numeric: false,
      disablePadding: false,
      label: "NIF",
    },
    {
      id: "telefonoContacto",
      numeric: false,
      disablePadding: false,
      label: "TELÉFONO",
    },
    {
      id: "emailContacto",
      numeric: false,
      disablePadding: false,
      label: "EMAIL",
    },
    {
      id: "direccion",
      numeric: false,
      disablePadding: false,
      label: "DIRECCIÓN",	
    },
    {
      id: "municipio",
      numeric: false,
      disablePadding: false,
      label: "MUNICIPIO",
    },
    {
      id: "provincia",
      numeric: false,
      disablePadding: false,
      label: "PROVINCIA",
    },
    {
      id: "cumplimiento",
      numeric: false,
      disablePadding: false,
      label: "CUMPLIMIENTO",
      noObjeto:true,
      overrideFunc: (row) => (
        <ProgressBarWithColors
          value={row}
          label={
            <Typography>
              {row}
            </Typography>
          }
        />
      ),
    },
    {
      id: "trabajadores",
      numeric: false,
      disablePadding: false,
      label: "TRABAJADORES",
      noObjeto:true,
      overrideFunc: (data, row) => (
        <div className="trabajadoresSelect">
          <Select
            label="Trabajadores"
            native
            name="trabajadores"
            id="trabajadores"
            style={{ textTransform: 'uppercase', padding: 0, fontSize: '11px', color: data ? "red" : 'green' }}
            onChange={(event) => handleChangeTrabajadores(event, row.nif)} value={data}
          >
            <option value={false}>Sí</option>
            <option value={true}>No</option>
          </Select>
        </div>
      ),
    },
    {
      id: "docsPendientes",
      numeric: false,
      disablePadding: false,
      label: "",
      noObjeto:true,
      overrideFunc: (row) => (
        <>
          {row > 0 ?
            <img src={Warning} width={15} alt="pendientes" />
            : null}
        </>
      ),
    },
    {
      id: "eliminarProv",
      numeric: false,
      disablePadding: false,
      label: "",
      noObjeto:true,
    },
  ]

  const handleCallback = () => {
    setRecargar(!recargar);
  };

  const filtrarDatos = (filtros) => {
    setBusqueda(filtros)
    setBusquedaActiva(true);
  }

  const handleEliminarProv = async (nif) => {
    eliminarProv(nif).then((resultado) => {
      handleCallback(resultado)
    })
  }

  const eliminarProv = async (nif) => {
        return new Promise(async (resolve, reject) => {
      try {
        const result = await Swal.fire({
          title: "¿Quieres eliminar el Proveedor?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#888",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Eliminar"
        });
        
        if (result.isConfirmed) {    
          const toastInfo = toast.info("Eliminando Proveedor", { autoClose: false });
          await deleteProveedor(nif).then(res => {
            
            if (res.message === "SUPPLIER_DELETED") {
              toast.update(toastInfo, {
                render: "Eliminado correctamente",
                type: toast.TYPE.SUCCESS,
                autoClose: true
              });
              resolve(true);
              // navigate("/proveedores");
              // clearFilters();
              // setClean(!clean);
              fetchData({ offset: 1, limit: limiteParam, filtros:busqueda});
            }else {
              toast.update(toastInfo, {
                render: "Error al eliminar",
                type: toast.TYPE.ERROR,
                autoClose: true
              });
            }
          }).catch(error => {
            console.log(error);
            toast.update(toastInfo, {
              render: "Error al eliminar",
              type: toast.TYPE.ERROR,
              autoClose: true
            });
            reject(error);
          })
        }

      } catch (error) {
        console.error("Error al eliminar el proveedor:", error);
        reject(error);
      }
    })
  }

  return (
    <>
      <BreadCrumb rutas={[{ path: `/proveedores`, label: 'Proveedores' }]} />
      <div className="listPage">
        <div className="gridCard">
          <div className="gridHead">LISTADO PROVEEDORES</div>
          <div className="tableContainer">
            <div className="contentBar">
              <div className="contentBtns">
                {/* <div className="listadoBtn" style={{ marginLeft: 0}} >
                <BsUpload alt="subir listado" style={{ marginRight: 5 }} />
                listado
              </div>
              <div className="descargarBtn">
                <a href="files/plantillaAAFF.xlsx" download="plantillaAAFF.xlsx">
                  <BsDownload alt="Descargar plantilla" style={{ marginRight: 5 }} />
                  plantilla
                </a>
              </div> */}

              </div>
              <Link to="/proveedores/nuevo" target={"_blank"}>
                <div className="contentButton">
                  <img src={Add} alt="Más" />
                </div>
              </Link>
            </div>
            <div style={{ display: "flex" }}>
              <FiltrosProveedores onFilter={filtrarDatos} clearFilter={clearFilters} filtrosIniciales={busqueda} clean = {clean} />
              {/* <div className="exportar">
                <ExportarProveedoresLista busqueda={Resbusqueda} /> 
              </div> */}
            </div>
            <div className="tableWrapper">
              <Box className="pageContent">
                <Box sx={{ mb: 10 }}>
                  <DataTable
                    data={data.rows}
                    isLoading={loading}
                    headers={proveedoresRows}
                    fetcher={fetchData}
                    currentPage={data.current_page}
                    meta={data.meta}
                    totalItems={data.total}
                    totalPages={data.totalPages}
                    nextPage={data.next_page}
                    filtros={busqueda}
                    busquedaActiva={busquedaActiva}
                    setBusquedaActiva={setBusquedaActiva}
                    clearFilterActivo={clearFilterActivo}
                    setClearFilterActivo={setClearFilterActivo}
                    mensaje={"No hay proveedores"}
                  />
                </Box>
              </Box>
            </div>
          </div>
        </div>
      </div>
      <style jsx="true">{`
          #trabajadores{
            padding:0 25px 0 0;
            border: none;
          }
          .trabajadoresSelect fieldset, .trabajadoresSelect select:hover{
            border-color: rgba(0, 0, 0, 0) !important;
          }               
      `}</style>
    </>
  );
};

export default withRouter(ProveedoresLista);
