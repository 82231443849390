import React, { useState, useEffect } from "react";
import {
  PDFViewer,
  Document,
  View,
  Page,
  Text,
  Image,
  StyleSheet,
  Svg,
  Path,
} from "@react-pdf/renderer";
import Portada from "../../../images/PortadaProveedor.png";
import Logo from "../../../images/LogoLAE.png";
import { withRouter } from "../../../providers/withRouter";
import { getAaffPropInformeSupp } from "../../../services";
import CircularProgress from "@mui/material/CircularProgress";
import { daysDiff } from "../../../functions/funciones";
import PDFHandler from "../../../components/pdfs/PDFHandler";
import { Navigate, useLocation } from "react-router-dom";
// import Caducado from "../../../icons/caducado.png"

const InformeSituacionComunidades = (props) => {
  const styles = StyleSheet.create({
    docTitle: {
      position: 'absolute',
      left: 70,
      right: 70,
      bottom: '70px',
      textAlign: 'center',
      fontSize: '16px',
      color: '#B50655' || '#023867',
      fontFamily: 'Roboto',
      fontWeight: 700,
      textTransform: 'uppercase'
    },
    pagina: {
      padding: "0px 50px",
      fontSize: "8px",
    },
    pageBreak: {
      pageBreak: 'always',
      marginBottom: 150,
      borderBottomWidth: 1,
    },
    pdfHeader: {
      marginBottom: "50px",
    },
    pdfFooter: {
      marginTop: "70px",
    },
    table: {
      display: "table",
      width: "auto",
      marginBottom: 30,

    },
    tableRow: {
      margin: "auto",
      flexDirection: "row",
    },
    tableColHeader: {
      width: "auto",
      borderStyle: "solid",
      borderWidth: 0,
      backgroundColor: "#375491",
      color: "#ffffff",
    },
    tableCell: {
      margin: "auto",
      marginTop: 5,
      marginBottom: 5,
      fontSize: 8,

    },
    p: {
      lineHeight: "1.8",
      textAlign: "justify",
      paddingBottom: 20,
    },
    containerHeader: {
      flexDirection: "row",
      borderStyle: "solid",
      borderWidth: 1,
      borderBottomWidth: 0,
      alignItems: "center",
      height: 30,
      textAlign: "center",
      fontWeight: "ultrabold",
      flexGrow: 1,
      fontSize: "5px",
    },
    fincaHeader: {
      width: "40%",
      borderRightColor: "#000",
      borderRightWidth: 1,
      paddingVertical: 7.5,
      height: 25,
      paddingTop: 10,
      backgroundColor: '#c31e64',
      color: '#ffffff',
    },
    nifHeader: {
      width: "16%",
      borderRightColor: "#000",
      borderRightWidth: 1,
      paddingVertical: 7.5,
      height: 25,
      paddingTop: 10,
      backgroundColor: '#c31e64',
      color: '#ffffff',
    },
    ultimaReclamacionHeader: {
      width: "16%",
      borderRightColor: "#000",
      borderRightWidth: 1,
      paddingVertical: 7.5,
      height: 25,
      paddingTop: 5,
      backgroundColor: '#c31e64',
      color: '#ffffff',
    },
    lopdHeader: {
      width: "11%",
      borderRightColor: "#000",
      borderRightWidth: 1,
      paddingVertical: 7.5,
      height: 25,
      paddingTop: 5,
      backgroundColor: '#c31e64',
      color: '#ffffff',
    },
    hojavisitaHeader: {
      width: "11%",
      borderRightColor: "#000",
      borderRightWidth: 1,
      paddingVertical: 7.5,
      height: 25,
      paddingTop: 5,
      backgroundColor: '#c31e64',
      color: '#ffffff',
    },
    planPrevencionHeader: {
      width: "11%",
      borderRightColor: "#000",
      borderRightWidth: 1,
      paddingVertical: 0,
      
    },
    planEmergenciaHeader: {
      width: "11%",
      paddingVertical: 7.5,
      height: 25,
      paddingTop: 5,
      backgroundColor: '#c31e64',
      color: '#ffffff',
    },
    
    rowCell: {
      flexDirection: "row",
      borderWidth: 1,
      borderBottomWidth: 0,
      alignItems: "center",
      textAlign: "center",
      fontStyle: "bold",
      flexGrow: 1,
      fontSize: "6px",

    },
    fincaCell: {
      width: "40%",
      textAlign: "left",
      borderRightColor: "#000",
      borderRightWidth: 1,
      paddingVertical: 7.5,
      height: 30,
      paddingLeft: 2,
      paddingTop: 12,
    },
    nifCell: {
      width: "16%",
      textAlign: "center",
      borderRightColor: "#000",
      borderRightWidth: 1,
      paddingVertical: 7.5,
      height: 30,
      paddingTop: 12,
    },
    lopdCell: {
      width: "11%",
      textAlign: "center",
      borderRightColor: "#000",
      borderRightWidth: 1,
      paddingVertical: 7.5,
    },
    hojavisitaCell: {
      width: "11%",
      textAlign: "center",
      borderRightColor: "#000",
      borderRightWidth: 1,
      paddingVertical: 7.5,
    },
    planPrevencionCell: {
      width: "11%",
      textAlign: "center",
      borderRightColor: "#000",
      borderRightWidth: 1,
      paddingVertical: 7.5,
    },
    planEmergenciaCell: {
      width: "11%",
      textAlign: "center",
    },
    lecturaPlanPrevencionHeader: {
      width: "22%",
      borderRightColor: "#000",
      borderRightWidth: 1,
      paddingVertical: 3.5,
      backgroundColor: '#c31e64',
      color: '#ffffff',
    },
    lecturaPlanEmergenciaHeader: {
      width: "22%",
      backgroundColor: '#c31e64',
      color: '#ffffff',
    },
    lecturaPlanPrevencionCell: {
      width: "22%",
      textAlign: "center",
      borderRightColor: "#000",
      borderRightWidth: 1,
      paddingVertical: 7.5,
    },
    lecturaPlanEmergenciaCell: {
      width: "22%",
      textAlign: "center",
      borderRightColor: "#000",
      borderRightWidth: 0,
      paddingVertical: 7.5,
    },
    
    svg: {
      height: "15pt",
      width: "15pt",
      margin: "auto",
    },
  });
  const location = useLocation();
  const [aaff, setAAFF] = useState({});
  const [proveedores, setProveedores] = useState([]);
  const [isLoading, setIsloading] = useState(true);

  const Caducado = (
    <Svg fill="#FFBB33" viewBox="0 0 512 512" style={styles.svg}>
      <Path d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm94.273438 320.105469c8.339843 8.34375 8.339843 21.824219 0 30.167969-4.160157 4.160156-9.621094 6.25-15.085938 6.25-5.460938 0-10.921875-2.089844-15.082031-6.25l-64.105469-64.109376-64.105469 64.109376c-4.160156 4.160156-9.621093 6.25-15.082031 6.25-5.464844 0-10.925781-2.089844-15.085938-6.25-8.339843-8.34375-8.339843-21.824219 0-30.167969l64.109376-64.105469-64.109376-64.105469c-8.339843-8.34375-8.339843-21.824219 0-30.167969 8.34375-8.339843 21.824219-8.339843 30.167969 0l64.105469 64.109376 64.105469-64.109376c8.34375-8.339843 21.824219-8.339843 30.167969 0 8.339843 8.34375 8.339843 21.824219 0 30.167969l-64.109376 64.105469zm0 0" />
    </Svg>
  );
  const Cross = (
    <Svg fill="#FF5252" viewBox="0 0 512 512" style={styles.svg}>
      <Path d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm94.273438 320.105469c8.339843 8.34375 8.339843 21.824219 0 30.167969-4.160157 4.160156-9.621094 6.25-15.085938 6.25-5.460938 0-10.921875-2.089844-15.082031-6.25l-64.105469-64.109376-64.105469 64.109376c-4.160156 4.160156-9.621093 6.25-15.082031 6.25-5.464844 0-10.925781-2.089844-15.085938-6.25-8.339843-8.34375-8.339843-21.824219 0-30.167969l64.109376-64.105469-64.109376-64.105469c-8.339843-8.34375-8.339843-21.824219 0-30.167969 8.34375-8.339843 21.824219-8.339843 30.167969 0l64.105469 64.109376 64.105469-64.109376c8.34375-8.339843 21.824219-8.339843 30.167969 0 8.339843 8.34375 8.339843 21.824219 0 30.167969l-64.109376 64.105469zm0 0" />
    </Svg>
  );
  const Tick = (
    <Svg fill="#7bc977" viewBox="0 0 512 512" style={styles.svg}>
      <Path d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm129.75 201.75-138.667969 138.664062c-4.160156 4.160157-9.621093 6.253907-15.082031 6.253907s-10.921875-2.09375-15.082031-6.253907l-69.332031-69.332031c-8.34375-8.339843-8.34375-21.824219 0-30.164062 8.339843-8.34375 21.820312-8.34375 30.164062 0l54.25 54.25 123.585938-123.582031c8.339843-8.34375 21.820312-8.34375 30.164062 0 8.339844 8.339843 8.339844 21.820312 0 30.164062zm0 0" />
    </Svg>
  );

  useEffect(() => {
    let aaffID = 0;
    let fincasIDS = [];
    getAaffPropInformeSupp(props.params.nif).then((res) => {
      let provs = {};
      setAAFF(res)
      console.log(res)
      res?.forEach((sup) => {
        console.log(sup.nif, sup.declres?.find(d => d.validado === true))
        provs[sup.id] = sup;
      });
      setProveedores(Object.values(provs));
      // setProveedores(provs);

      setIsloading(false);
    });
  }, []);

  const token = localStorage.getItem('token');
  console.log(token)

  if(!token){
    return <Navigate replace to="/login" state={{from: location}} />
  }

  function gruposFilas(filas, filasPorPagina) {
    const filasAgrupadas = [];
    for (let i = 0; i < filas.length; i += filasPorPagina) {
      filasAgrupadas.push(filas.slice(i, i + filasPorPagina));
    }
    return filasAgrupadas;
  }

  const filasPorPagina1 = 20;
  const filasAgrupadas1 = gruposFilas(proveedores, filasPorPagina1);

  const filasPorPagina2 = 18;
  const filasAgrupadas2 = gruposFilas(proveedores, filasPorPagina2);



  return (
    <>
      {isLoading ? (
        <CircularProgress
          style={{
            color: "#92004D",
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
        />
      ) : (
        <PDFHandler>
        <Document size="A4" margin="2cm">
          <Page style={styles.body}>
            <View>
              <Image
                id="portada"
                className="portada"
                style={{ width: "100%", height: "100vh" }}
                src={Portada}
              />
              <Text style={styles.docTitle}>
                {aaff.razonSocial}
              </Text>
            </View>

              <View>
                <View style={{ marginTop: 0, paddingBottom: 20, marginHorizontal: '2cm' }} fixed>
                  <View >
                    <Image src={Logo} style={{ marginTop: 20, width: '20%', objectFit:'cover' }} />
                  </View>
                </View>
                <View style={styles.pagina}>
                  <Text style={styles.p}>Estimado administrador de fincas.</Text>
                  <Text style={styles.p}>
                    Tras los primeros meses de trabajo administrativo y
                    reclamación de la documentación a los proveedores os mostramos
                    a continuación el informe de situación documental:
                  </Text>
                  <View style={styles.table}>
                    {filasAgrupadas1.length === 0 ? (
                      <></>
                    ) : (
                      filasAgrupadas1.map((grupo, grupoIndex) => (
                        <View key={grupoIndex} style={styles.pageBreak}>
                          <View style={styles.containerHeader}>
                            <Text style={styles.fincaHeader}>PROVEEDOR</Text>
                            <Text style={styles.nifHeader}>NIF</Text>
                            <Text style={styles.ultimaReclamacionHeader}>{`ULTIMA \nRECLAMACIÓN`}</Text>
                            <Text style={styles.lopdHeader}>{`CERTIFICADO \nLOPD`}</Text>
                            <Text style={styles.hojavisitaHeader}>{`SEGURO DE \nRESP. CIVIL`}</Text>
                            <Text style={styles.lopdHeader}>{`JUSTIFICANTE DE \nPAGO SPA`}</Text>
                            <Text style={styles.lopdHeader}>{`PLANIFICACIÓN \nPREVENTIVA`}</Text>
                            <Text style={styles.lopdHeader}>{`EVALUACIÓN \nRIESGOS`}</Text>
                            <Text style={styles.lopdHeader}>{`CERTIFICADO \nSEG. SOC.`}</Text>
                            <Text style={styles.lopdHeader}>{`VIGILANCIA \nSALUD`}</Text>
                            <Text style={styles.planEmergenciaHeader}>{`DECLARACIÓN \nRESPONSABLE`}</Text>
                          </View>

                          {grupo.map((prov, i) => {
                            const ultimaReclamacion = prov.ultimaReclamacion &&
                              new Date(prov.ultimaReclamacion) > new Date(prov.createdAt)
                              ? prov.ultimaReclamacion
                              : prov.createdAt;

                            return (
                              <View key={i} style={styles.rowCell}>
                                <Text style={styles.fincaCell}>{prov.razonSocial}</Text>
                                <Text style={styles.nifCell}>{prov.nif}</Text>
                                <Text style={styles.nifCell}>
                                  {new Date(ultimaReclamacion).toLocaleDateString()}
                                </Text>

                                <View style={styles.lopdCell}>
                                  {prov?.docssupps?.find(d => d.tipoDoc === 1)?.validado || prov?.docssupps?.find(d => d.tipoDoc === 1)?.pendiente ? Tick : Cross}
                                </View>
                                <View style={styles.hojavisitaCell}>
                                  {new Date(prov?.docssupps?.find(d => d.tipoDoc === 2)?.fechaCaducidad).getFullYear() !== 1970 && daysDiff(new Date(prov?.docssupps?.find(d => d.tipoDoc === 2)?.fechaCaducidad), new Date()) > 30 ? Caducado : prov?.docssupps?.find(d => d.tipoDoc === 2)?.validado || prov?.docssupps?.find(d => d.tipoDoc === 2)?.pendiente ? Tick : Cross}
                                </View>
                                <View style={styles.planPrevencionCell}>
                                  {prov?.noProcede ? <Text>{`No \nprocede`}</Text> : new Date(prov?.docssupps?.find(d => d.tipoDoc === 3)?.fechaCaducidad).getFullYear() !== 1970 && daysDiff(new Date(prov?.docssupps?.find(d => d.tipoDoc === 3)?.fechaCaducidad), new Date()) > 30 ? Caducado : prov?.docssupps?.find(d => d.tipoDoc === 3)?.validado || prov?.docssupps?.find(d => d.tipoDoc === 3)?.pendiente ? Tick : Cross}
                                </View>
                                <View style={styles.planPrevencionCell}>
                                  {prov?.noProcede ? <Text>{`No \nprocede`}</Text> : prov?.docssupps?.find(d => d.tipoDoc === 4)?.validado || prov?.docssupps?.find(d => d.tipoDoc === 4)?.pendiente ? Tick : Cross}
                                </View>
                                <View style={styles.hojavisitaCell}>
                                  {prov?.docssupps?.find(d => d.tipoDoc === 5)?.validado || prov?.docssupps?.find(d => d.tipoDoc === 5)?.pendiente ? Tick : Cross}
                                </View>
                                <View style={styles.planPrevencionCell}>
                                  {new Date(prov?.docssupps?.find(d => d.tipoDoc === 6)?.fechaCaducidad).getFullYear() !== 1970 && daysDiff(new Date(prov?.docssupps?.find(d => d.tipoDoc === 6)?.fechaCaducidad), new Date()) > 30 ? Caducado : prov?.docssupps?.find(d => d.tipoDoc === 6)?.validado || prov?.docssupps?.find(d => d.tipoDoc === 6)?.pendiente ? Tick : Cross}
                                </View>
                                <View style={styles.planPrevencionCell}>
                                  {prov?.noProcede ? <Text>{`No \nprocede`}</Text> : new Date(prov?.docssupps?.find(d => d.tipoDoc === 7)?.fechaCaducidad).getFullYear() !== 1970 && daysDiff(new Date(prov?.docssupps?.find(d => d.tipoDoc === 7)?.fechaCaducidad), new Date()) > 30 ? Caducado : prov?.docssupps?.find(d => d.tipoDoc === 7)?.validado || prov?.docssupps?.find(d => d.tipoDoc === 7)?.pendiente ? Tick : Cross}
                                </View>
                                <View style={styles.planEmergenciaCell}>
                                  {prov?.declres?.find(d => d.validado === true) ? Tick : Cross}
                                </View>
                               
                              </View>
                            );
                          })}
                        </View>
                      ))
                    )}
                  </View>
                </View>
                <View style={styles.pdfFooter} fixed></View>
              </View>
            </Page>
            <Page style={styles.body}>

              <View>
                <View style={{ marginTop: 0, paddingBottom: 20, marginHorizontal: '2cm' }} fixed>
                  <View >
                    <Image src={Logo} style={{ marginTop: 20, width: '20%' }} />
                  </View>
                </View>
                <View style={styles.pagina}>
                  <Text style={styles.p}>
                    La obtención de esta documentación es fundamental para
                    asegurar que los proveedores que acceden a las comunidades de
                    propietarios cumplen debidamente con las obligaciones
                    normativas (seguros de responsabilidad civil, modalidad
                    preventiva en vigor, obligaciones en materia laboral, etc.).
                  </Text>
                  <Text style={styles.p}>
                    Al mismo tiempo os informamos de la relación de proveedores
                    que se han descargado la documentación relativa a la
                    evaluación de riesgos y/o plan de emergencia de las
                    comunidades que prestan servicio:
                  </Text>

                  <View style={styles.table}>
                  {filasAgrupadas1.length === 0 ? (
                      <></>
                    ) : (
                    filasAgrupadas2.map((grupo, grupoIndex) => (
                      <View key={grupoIndex} style={styles.pageBreak}>
                        <View style={styles.containerHeader}>
                          <Text style={styles.fincaHeader}>PROVEEDOR</Text>
                          <Text style={styles.nifHeader}>NIF</Text>
                          <Text
                            style={styles.lecturaPlanPrevencionHeader}
                          >{`LECTURA DE \n PLAN DE \nPREVENCIÓN`}</Text>
                          <Text
                            style={styles.lecturaPlanEmergenciaHeader}
                          >{`LECTURA DE \n PLAN DE \nEMERGENCIA`}</Text>
                        </View>
                        {grupo.map((prov, i) => {
                          return (
                            <View key={i} style={styles.rowCell}>
                              <Text style={styles.fincaCell}>
                                {prov.razonSocial}
                              </Text>
                              <Text style={styles.nifCell}>{prov.nif}</Text>
                              <View style={styles.lecturaPlanPrevencionCell}>
                                {prov?.docsRead?.find(d => d.nombre === 'plan de emergencia')?.validado || prov?.docssupps?.find(d => d.nombre === 'plan de emergencia')?.pendiente ? Tick : Cross}
                              </View>
                              <View style={styles.lecturaPlanEmergenciaCell}>
                                {prov?.docsRead?.find(d => d.nombre === 'identificacion de riesgos')?.validado || prov?.docssupps?.find(d => d.nombre === 'identificacion de riesgos')?.pendiente ? Tick : Cross}
                              </View>
                            </View>
                          );
                        })}
                      </View>
                    ))
                  )};
                  </View>

                  <Text style={styles.p}>
                    Nuestro departamento técnico seguirá trabajando mensualmente
                    en la reclamación activa de documentación.
                  </Text>
                  <Text style={styles.p}>
                    Quedamos a su entera disposición para aclarar cualquier duda
                    que puedan tener,
                  </Text>
                  <Text style={styles.p}>Atentamente,</Text>
                  <Image src={Logo} alt="logo" style={{ width: "100px" }} />
                </View>
                <View style={styles.pdfFooter} fixed></View>
              </View>
            </Page>
          </Document>
        </PDFHandler>
      )}

    </>
  );
};

export default withRouter(InformeSituacionComunidades);
